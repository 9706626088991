@import "../exports/variables.scss";

.AssetsView {
  position: relative;

  .scrollWrapper {
    height: calc(100vh - 36px);
    overflow-y: scroll;

    .layoutBuffer {
      height: 92px;
    }

    /* Gravity 3.1.0 */
    .mediaWrapper {
      height: 660px;
      width: 100%;
      min-width: 1080px;
      max-width: 1280px;
      position: relative;

      .mediaWrapperInner {
        height: 620px;
        width: calc(100% - 40px);
        background: linear-gradient(
          150deg, 
          rgb(4, 106, 201, 0.05) 0%, 
          rgb(255, 237, 252, 0.3) 35%, 
          rgb(159, 255, 247, 0.25) 100%
        );
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 24px;
        overflow: hidden;
        box-shadow:
          5px 7px 6px 0 rgb(0 0 0 / 7%),
          -9px -8px 7px 0 white;
        border: 1px solid rgba(255, 255, 255, 0.2);

        .wrapperHeader {
          height: 40px;
          width: 100%;
          background: rgba(140, 228, 210, 0.2);
          padding: 6px 24px;
          box-sizing: border-box;

          .controlWrapper {
            position: absolute;
            top: 42px;
            right: 12px;
            z-index: 2;

            .buttonWrapper {
              display: inline-block;
              vertical-align: middle;
              padding: 8px;

              .buttonControl {
                height: 40px;
                width: 40px;
                border: none;
                border-radius: 50%;

                &.delete {
                  background: $red;
                }
                &.settings {
                  background: $purpleLight;
                }
                .Loading {
                  transform: scale(0.5) translate(-14px, -14px);
                }

                .iconImage {
                  height: 16px;
                  width: 16px;
                  transform: translateY(2px);
                }
              }
            }
          }
        }
        .headerContent {
          height: calc(100% - 50px);
          width: 100%;

          .contentText {
            display: inline-block;
            vertical-align: top;
            height: 100%;
            width: calc(100% - 600px);
            padding: 40px 0 40px 40px;
            box-sizing: border-box;

            .contentTitle {
              font: {
                family: "Gabarito-Black";
                size: 2.2rem;
              }
              color: $purpleLight;
            }
            @media (max-width: 1380px) {
              .contentTitle {
                font-size: 2.0rem;
              }
            }
            .contentData {
              padding: 40px 0 24px;
              position: relative;

              .creator {
                display: inline-block;
                vertical-align: middle;
                position: relative;

                .creatorAvatar {
                  height: 50px;
                  width: 50px;
                  background-size: cover;
                  background-position-x: 50%;
                  background-position-y: 50%;
                  border-radius: 50%;
                }
              }
              .metadata {
                display: inline-block;
                vertical-align: middle;
                padding: 0 8px;

                .name {
                  font: {
                    family: "Gabarito-Bold";
                    size: 1.4rem;
                  }
                  color: #2c5041;
                }
                .handle {
                  font: {
                    family: "Gabarito-Medium";
                    size: 0.8rem;
                  }
                  color: #1c2d26;
                  display: none;
                }
                .creation {
                  font: {
                    family: "Gabarito-Medium";
                    size: 0.9rem;
                  }
                  color: #1c2d26;
                  padding: 48px 16px;
                  position: absolute;
                  top: -28px;
                  right: 0;
                }
                @media (max-width: 1380px) {
                  .creation {
                    top: -40px;
                    right: 0;
                    left: -15px;
                  }
                }
              }
            }
            @media (max-width: 1380px) {
              .contentData {
                padding: 40px 0 12px;
              }
            }
            .contentDescription {
              font: {
                family: "Gabarito-Regular";
                size: 1.2rem;
              }
              color: black;

              &.hasKeywords {
                height: 210px;
                overflow-y: scroll;
              }
              @media (max-width: 1380px) {
                &.hasKeywords {
                  height: 118px;
                  overflow-y: scroll;
                }
              }

              .descriptionLink {
                color: #003922;
              }
            }
            .respondWrapper {
              width: calc(100% - 700px);
              position: absolute;
              bottom: 40px;

              .respond {
                height: 50px;
                background: $gravityGreen;
                color: $purpleLight;
                border: none;
                font: {
                  family: "Gabarito-Bold";
                  size: 1.0rem;
                }
                padding: 0 80px;
                border-radius: 25px;
                left: 50%;
                position: relative;
                transform: translateX(-50%);

                &.magic {
                  background-image: repeating-linear-gradient(45deg, violet, $gravityGreen, $orange, $red, violet);
                  background-size: 800% 800%;
                  animation: rainbow 12s ease infinite;
                }

                .responseType {
                  display: inline-block;
                  vertical-align: middle;
                  padding-right: 8px;
                  transform: translateY(2px);

                  .typeIcon {
                    height: 24px;
                    width: 24px;
                  }
                }
                .typeText {
                  display: inline-block;
                  vertical-align: middle;
                }
              }
              .aiFollowUp {
                width: 100%;
                text-align: center;
                position: absolute;
                padding-top: 8px;

                .plus {
                  display: inline-block;
                  vertical-align: middle;
                  font: {
                    family: "Gabarito-Black";
                    size: 1.0rem;
                  }
                  color: $gravityGreen;
                  padding: 0 8px 0 0;
                }
                .keywordAI {
                  display: inline-block;
                  height: 17px;
                  width: 17px;
                  font: {
                    family: "Gabarito-Black";
                    size: 0.7rem;
                  }
                  color: #fff;
                  background: $gravityGreen;
                  padding: 2px 3px;
                  box-sizing: border-box;
                  border-radius: 2px;
                }
                .keywordWord {
                  display: inline-block;
                  font: {
                    family: "Gabarito-Bold";
                    size: 0.9rem;
                  }
                  color: $gravityGreen;
                  padding: 0 0 0 4px;
                  transform: translateY(1px);
                }
              }
            }
          }
          .contentMedia {
            display: inline-block;
            vertical-align: top;
            height: 100%;
            width: 600px;
            box-sizing: border-box;
            position: relative;

            .Video {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            .attachmentWrapper {
              display: inline-block;
              position: absolute;
              width: 520px;
              text-align: center;
              bottom: 30px;
              right: 40px;

              .actionWrapper {
                display: inline-block;
                vertical-align: middle;
                padding: 0 4px;

                .fileLink,
                .youtubeLink,
                .narchivesLink {
                  display: table-cell;
                  vertical-align: middle;
                  height: 50px;
                  background: #003922;
                  color: white;
                  font-family: "Gabarito-Bold";
                  font-size: 0.8rem;
                  padding: 0 30px;
                  border-radius: 25px;
                  border: none;

                  .fileType {
                    .image {
                      display: inline-block;
                      vertical-align: middle;
                      height: 12px;
                      width: 12px;
                      padding-right: 8px;
                    }
                    .type {
                      display: inline-block;
                      vertical-align: middle;
                    }
                  }
                }
                .youtubeLink {
                  background: $purpleDark;
                }
                .narchivesLink {
                  background: #16254e;

                  .fileType {
                    .image {
                      display: inline-block;
                      vertical-align: middle;
                      height: 24px;
                      width: 24px;
                      padding-right: 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.viewer {
        height: 575px;

        .mediaWrapperInner {
          height: 555px;
        }
      }
    }
    .largeViewer {
      width: 100%;
      min-width: 1080px;
      max-width: 1280px;
      padding: 0 16px;
      box-sizing: border-box;

      .largeViewerChoices {
        text-align: right;

        .serviceButtonWrapper {
          display: inline-block;
          padding: 0 4px;

          .serviceButton {
            height: 40px;
            width: 200px;
            border: none;
            border-radius: 20px;
            box-shadow:
              5px 7px 6px 0 rgba(0, 0, 0, 0.07),
              -6px -2px 7px 0 white;

            .iconWrapper {
              display: inline-block;
              vertical-align: middle;
              height: 20px;
              width: 20px;

              .icon {
                height: 100%;
                width: 100%;
              }
            }
            .text {
              display: inline-block;
              vertical-align: middle;
              font: {
                family: "Gabarito-Bold";
                size: 0.8rem;
              }
              color: #003922;
              padding: 0 0 0 8px;
            }
          }
        }
      }
    }
    .figmaWrapper {
      height: 720px;
      width: 100%;
      min-width: 1080px;
      max-width: 1280px;
      position: relative;
      padding-bottom: 32px;

      .figmaInnerWrapper {
        padding-top: 18px;

        .figmaEmbed {
          height: 720px;
          width: calc(100% - 40px);
          background: white;
          position: relative;
          left: 50%;
          transform: translate(-50%, 0);
          border-radius: 24px;
          border: 1px solid rgba(255, 255, 255, 0.2);
        }
      }
    }
    .counter {
      width: 100%;
      min-width: 1080px;
      max-width: 1240px;
      padding: 24px 24px;
      position: relative;
      box-sizing: border-box;
      z-index: 3;
      //overflow-x: clip;

      .participantCount {
        font: {
          family: "Gabarito-Black";
          size: 2.4rem;
        }
        color: #003922;
        transform: translateY(-10px);
      }
      .participantList {
        position: absolute;
        top: 16px;
        right: 0;
      }
    }

    @media (min-width: 1380px) {
      .mediaWrapper,
      .counter,
      .figmaWrapper {
        left: 50%;
        transform: translateX(-50%);
      }
      .largeViewer {
        min-width: 0;
        max-width: 1280px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .content {
      .type {
        text-align: center;
      }
    }
    .media {
      text-align: center;
      padding-top: 40px;

      .videoPlayer,
      .videoError {
        width: 90%;
      }
    }
    .responses {
      width: 100%;
      min-width: 1020px;
      text-align: center;

      &.viewer {
        padding-top: 24px;
      }

      .responseEmpty {
        font: {
          family: "Gabarito-Regular";
          size: 1.0rem;
        }
        color: #999;
        padding: 16px 0;
      }
      .responsesWrapper {
        display: inline;
        text-align: left;

        .responseContainer {
          display: inline-block;
          vertical-align: top;
          width: calc(50% - 16px);
          min-width: 484px;
          max-width: calc(500px - 16px);
          padding: 8px;
          box-sizing: border-box;

          .responseBorder {
            background: linear-gradient(
              150deg,
              rgba(4, 201, 96, 0.01) 0%,
              rgba(0, 216, 159, -0.95) 35%,
              rgba(13, 255, 236, 0.05) 100%
            );
            padding: 48px 8px 8px;
            border-radius: 22px;
            box-shadow:
              5px 7px 6px 0 rgb(0 0 0 / 7%),
              -9px -8px 7px 0 white;
            border: 1px solid rgba(255, 255, 255, 0.2);
            position: relative;
            overflow-y: hidden;

            &.viewer {
              padding: 24px 8px 8px;
            }

            .aiAnswer {
              position: absolute;
              top: 17px;
              left: 50%;
              transform: translateX(-50%);

              .answerAI {
                padding: 6px 16px;
                background: $purpleDark;
                border: none;
                border-radius: 6px;
                position: relative;

                .followBubble {
                  display: inline-block;
                  height: 16px;
                  width: 16px;
                  font: {
                    family: "Gabarito-Black";
                    size: 0.9rem;
                  }
                  color: white;
                  background: $lightPurple;
                  padding: 6px;
                  border-radius: 50%;
                  position: absolute;
                  z-index: 1;
                  top: -16px;
                  right: -6px;
                }

                .answerText {
                  display: inline-block;
                  font: {
                    family: "Gabarito-Black";
                    size: 0.7rem;
                  }
                  color: white;
                  padding-right: 8px;
                }
                .keywordAI {
                  display: inline-block;
                  height: 17px;
                  width: 17px;
                  font: {
                    family: "Gabarito-Black";
                    size: 0.7rem;
                  }
                  color: #fff;
                  background: $gravityGreen;
                  padding: 2px 3px;
                  box-sizing: border-box;
                  border-radius: 2px;
                }
                .keywordWord {
                  display: inline-block;
                  font: {
                    family: "Gabarito-Bold";
                    size: 0.7rem;
                  }
                  color: white;
                  padding: 0 2px;
                  transform: translateY(1px);

                  .beta {
                    font: {
                      family: "Gabarito-Regular";
                      size: 0.5rem;
                    }
                    color: $purpleLight;
                    padding: 0 2px;
                  }
                }
              }
            }

            .reactionWrapper {
              text-align: left;
              height: 40px;
              width: 100%;
              background: rgba(100, 200, 180, 0.1);
              padding: 8px 20px;
              box-sizing: border-box;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
            }

            .responseWrapper {
              border-radius: 6px;
              padding: 0 0 90px;
              overflow: hidden;

              &.viewer {
                padding: 0;
              }

              .response {
                position: relative;

                .videoPlayer,
                .videoError {
                  height: 253px;
                  width: 100%;
                }
                .videoError {
                  background: rgba(0,0,0,0.9);
                  border-radius: 6px;
                }
                .deleteResponse {
                  background: none;
                  border: none;
                  position: absolute;
                  top: 6px;
                  right: 6px;
                  background: rgba(240, 240, 240, 0.7);
                  border: none;
                  border-radius: 50%;
                  height: 40px;
                  width: 40px;

                  .deleteIcon {
                    height: 24px;
                    width: 20px;
                  }
                }
              }
              .data {
                box-sizing: border-box;
                padding: 4px 16px;
                position: relative;
                border-radius: 6px;

                .responderWrapper {
                  display: inline-block;
                  width: 100%;
                  padding: 8px 0;
                  position: relative;

                  .responder {
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;

                    .responderAvatar {
                      height: 50px;
                      width: 50px;
                      background-size: cover;
                      background-position-x: 50%;
                      background-position-y: 50%;
                      border-radius: 50%;
                    }
                  }
                  .creation {
                    font: {
                      family: "Gabarito-Medium";
                      size: 0.8rem;
                    }
                    color: #1c2d26;
                    position: absolute;
                    top: 25px;
                    right: 0;
                  }
                  .metadata {
                    display: inline-block;
                    vertical-align: middle;
                    padding: 0 8px;

                    .name {
                      font: {
                        family: "Gabarito-Bold";
                        size: 1.1rem;
                      }
                      color: #2c5041;
                    }
                    .handle {
                      font: {
                        family: "Gabarito-Medium";
                        size: 0.7rem;
                      }
                      color: #1c2d26;
                      display: none;
                    }
                  }
                }
                .descriptionWrapper {
                  .triangleUp {
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 12px solid #f1fffb;
                    transform: translateX(16px);
                  }

                  .description {
                    padding: 10px 16px;
                    text-align: left;
                    font: {
                      family: "Gabarito-Bold";
                      size: 1.0rem;
                    }
                    color: #003922;
                    background: #f1fffb;
                    border-radius: 6px;
                  }
                }
              }
              .respondingWrapper {
                width: calc(100% - 16px);
                position: absolute;
                bottom: 8px;
                background: $purpleLight;
                padding: 8px 16px;
                box-sizing: border-box;
                border-radius: 12px;

                .respondingToText {
                  font: {
                    family: "Gabarito-Bold";
                    size: 1.0rem;
                  }
                  color: white;
                  padding: 0 0 8px;
                }
                .viewResponsesWrapper {
                  min-height: 22px;
                  width: 100%;
                  left: -8px;

                  .respondButtonWrapper {
                    display: inline-block;
                    padding-right: 8px;

                    .respond {
                      height: 34px;
                      background: $gravityGreen;
                      color: $purpleLight;
                      border: none;
                      font: {
                        family: "Gabarito-Bold";
                        size: 0.9rem;
                      }
                      padding: 0 28px;
                      border-radius: 18px;

                      &.magic {
                        background-image: repeating-linear-gradient(
                          45deg,
                          violet,
                          $gravityGreen,
                          $orange,
                          $red,
                          violet
                        );
                        background-size: 800% 800%;
                        animation: rainbow 12s ease infinite;
                      }

                      .responseType {
                        display: inline-block;
                        vertical-align: middle;
                        padding-right: 6px;
                        transform: translateY(2px);

                        .typeIcon {
                          height: 18px;
                          width: 18px;
                        }
                      }
                      .typeText {
                        display: inline-block;
                        vertical-align: middle;
                      }
                    }
                  }

                  .viewResponses {
                    height: 34px;
                    background: $lightPurple;
                    border: none;
                    padding: 0 28px;
                    border-radius: 18px;
                    opacity: 0.3;
                    cursor: default;
                    float: right;

                    .responsesText {
                      display: inline-block;
                      font: {
                        family: "Gabarito-Bold";
                        size: 1.0rem;
                      }
                      color: white;
                      padding: 0 4px;
                    }
                    .responsesIcon {
                      display: inline-block;
                      height: 16px;
                      width: 16px;
                      transform: translateY(1px);
                    }
                    &.hasResponses {
                      opacity: 1;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }

          &.private {
            .responseBorder {
              background: $purpleLight;
              box-shadow:
                5px 7px 6px 0 rgb(0 0 0 / 7%),
                -9px -8px 7px 0 white;
              border: 1px solid rgba(255, 255, 255, 0.2);

              .reactionWrapper {
                background: $purpleDark;
                text-align: center;

                .privateConversation {
                  font: {
                    family: "Gabarito-Medium";
                    size: 1.0rem;
                  }
                  color: #fff;
                  padding: 4px 0 0 0;

                  .privateIcon {
                    height: 16px;
                    width: 16px;
                    padding: 0 4px 0 0;
                  }
                }
              }

              .responseWrapper {
                .data {
                  .responderWrapper {
                    .creation {
                      color: #fff;
                    }
                    .metadata {
                      .name {
                        color: #fff;
                      }
                      .handle {
                        color: #fff;
                      }
                    }
                  }
                  .descriptionWrapper {
                    .triangleUp {
                      border-bottom: 12px solid #51436d;
                    }

                    .description {
                      padding: 10px 16px;
                      text-align: left;
                      font: {
                        family: "Gabarito-Bold";
                        size: 1.0rem;
                      }
                      color: #fff;
                      background: #51436d;
                      border-radius: 6px;
                    }
                  }
                  .viewResponsesWrapper {
                    min-height: 22px;
                    width: calc(100% - 8px);
                    padding: 8px;
                    position: absolute;
                    left: -8px;
                    transform: translateY(8px);

                    .respondButtonWrapper {
                      display: inline-block;
                      transform: translate(16px, 8px);

                      .respond {
                        height: 34px;
                        background: $gravityGreen;
                        color: $purpleLight;
                        border: none;
                        font: {
                          family: "Gabarito-Bold";
                          size: 0.9rem;
                        }
                        padding: 0 28px;
                        border-radius: 18px;

                        &.magic {
                          background-image: repeating-linear-gradient(
                            45deg,
                            violet,
                            $gravityGreen,
                            $orange,
                            $red,
                            violet
                          );
                          background-size: 800% 800%;
                          animation: rainbow 12s ease infinite;
                        }
                      }
                    }

                    .viewResponses {
                      height: 34px;
                      background: #003922;
                      border: none;
                      padding: 0 28px;
                      border-radius: 18px;
                      position: absolute;
                      top: 16px;
                      right: 16px;
                      opacity: 0.2;
                      cursor: default;

                      .responsesText {
                        display: inline-block;
                        font: {
                          family: "Gabarito-Bold";
                          size: 1.0rem;
                        }
                        color: white;
                        padding: 0 4px;
                      }
                      .responsesIcon {
                        display: inline-block;
                        height: 16px;
                        width: 16px;
                        transform: translateY(1px);
                      }
                      &.hasResponses {
                        opacity: 1;
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .creatorCrown {
      height: 16px;
      width: 16px;
      position: absolute;
      top: -11px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.mobile {
  .AssetsView {
    top: 8px;

    .scrollWrapper {
      /* Gravity 3.1.0 */
      .layoutBuffer {
        height: 102px;
      }

      .mediaWrapper {
        height: 100%;
        min-width: 0px;

        .mediaWrapperInner {
          height: auto;
          width: calc(100% - 12px);
          top: auto;
          left: auto;
          transform: translateX(6px);

          .headerContent {
            .contentText {
              width: 100%;
              padding: 16px;

              .contentData {
                .creator {
                  left: 50%;
                  transform: translateX(-50%);
                }
                .metadata {
                  width: 100%;
                  text-align: center;
                  padding: 4px 8px 0;

                  .creation {
                    padding: 40px 0;
                    top: -34px;
                    left: 0;
                    right: auto;
                  }
                  .name {
                    font-size: 1.2rem !important;
                  }
                }
              }
              .contentDescription {
                &.hasKeywords {
                  height: 100%;
                  padding-bottom: 30px;
                }
              }
              .respondWrapper {
                width: 100%;
                position: relative;
                bottom: auto;

                .respond {
                  width: 100%;
                  padding: 0;
                  left: auto;
                  transform: none;
                }
              }
            }
            .attachmentWrapper {
              display: block;
              width: 100%;
              right: auto;
              bottom: unset;
              position: relative;
              padding: 8px 0;
            }
            .contentMedia {
              display: block;
              height: auto;
              width: auto;
              position: relative;

              .Video {
                width: 100%;
                position: relative;
                top: auto;
                left: auto;
                transform: none;

                &.noTranscript {
                  animation: none !important;
                }
                &.transcript {
                  animation: none !important;
                }
              }
            }
          }
        }
      }
      .counter {
        min-width: 0px;

        .participantCount {
          font-size: 2.0rem;
        }
      }
      .responses {
        min-width: 0px;

        .responsesWrapper {
          .responseContainer {
            width: 100%;
            min-width: 0;

            .responseBorder {
              .responseWrapper {
                .response {
                  .videoPlayer,
                  .videoError {
                    height: auto;
                  }
                }
                .data {
                  .responderWrapper {
                    .creation {
                      top: 2px;
                      right: -14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
