@import "../exports/variables.scss";

.Application {
  background: #f1f1f1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: hidden;

  &.embedModule,
  .embedModule {
    height: 500px;
    width: 500px;
    background: transparent;
  }

  &.recorderModule,
  .recorderModule {
    height: 460px;
    width: 680px;
    background: transparent;
  }

  &.carouselModule,
  .carouselModule {
    height: 350px;
    width: 680px;
    background: transparent;
  }

  .blurred {
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
  }
}
