@import "../exports/variables.scss";

.WhatIs {
  .whatIsWrapper {
    .whatIs {
      display: inline-block;
      position: relative;
      left: 50%;
      transform: translateX(calc(-50% - 20px));
      padding: 48px 0;

      .whatIsText {
        padding: 24px 0;

        .whatIsImage {
          display: inline-block;
          vertical-align: middle;

          .whatIsIcon {
            height: 40px;
            padding: 0 16px;
          }
        }
        .whatIsTitle {
          display: inline-block;
          vertical-align: middle;
          font: {
            family: "Gabarito-Bold";
            size: 2.2rem;
          }
          color: $purpleLight;
        }
      }
      .whatIsContent {
        max-width: 600px;
        font: {
          family: "Gabarito-Regular";
          size: 1.2rem;
        }
        padding: 0 0 0 88px;
      }
      .whatIsGraphic {
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .WhatIs {
    .whatIsWrapper {
      .whatIs {
        padding: 48px;
        width: 100%;
        box-sizing: border-box;
        transform: translate(-50%, 0px);

        .whatIsText {
          padding: 24px 0;
          text-align: center;

          .whatIsImage {
            .whatIsIcon {
              padding: 0 8px;
            }
          }
          .whatIsTitle {
            font-size: 1.6rem;
          }
        }

        .whatIsContent {
          padding: 0 16px;
        }
        .whatIsGraphic {
          text-align: center;
          width: 100%;
          padding: 48px 0;

          .graphic {
            width: 300px;
          }
        }
      }
    }
  }
}
