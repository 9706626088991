@import "../exports/variables.scss";

.DashboardViewer {
    //height: calc(100% - 240px);
    min-width: 1080px;
    //overflow-y: scroll;
    /*position: absolute;
    top: 70px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;*/

    .viewerWrapper {
        .dashboardModule {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            min-width: 532px;

            &.topModule {
                min-height: 500px;
            }

            &.fullWidth {
                width: 100%;
            }

            .moduleWrapper {
                padding: 8px;
                box-sizing: border-box;

                .moduleName {
                    font: {
                        family: "Gabarito-Bold";
                        size: 1.7rem;
                    }
                    color: $purpleLight;
                    padding: 0 12px;
                }
                .moduleContent {
                    padding: 16px;
                    box-sizing: border-box;

                    .moduleBox {
                        height: 200px;
                        border-radius: 22px;
                        padding: 16px;
                        box-sizing: border-box;
                        box-shadow: $morphShadow;

                        &.topModule {
                            height: 420px;
                            background: linear-gradient(
                                150deg,
                                rgb(4, 106, 201, 0.05) 0%,
                                rgb(255, 237, 252, 0.3) 35%,
                                rgb(159, 255, 247, 0.25) 100%
                            );
                        }
                    }
                }
            }
        }
    }
}

.mobile {
    .DashboardViewer {
        min-width: 100%;

        .viewerWrapper {
            .dashboardModule {
                min-width: 100%;

                .moduleWrapper {
                    padding: 0;

                    .moduleName {
                        font: {
                            size: 1.2rem;
                        }
                        padding: 8px 12px 0;
                    }
                    .moduleContent {
                        padding: 8px;

                        .moduleBox {
                            height: 140px;
                            padding: 0;

                            &.topModule {
                                height: 420px;
                                overflow-y: scroll;
                                padding-bottom: 12px;
                            }
                        }
                    }
                }
                &.topModule {
                    min-height: 400px;
                }
            }
        }
    }
}
