@import "../exports/variables.scss";

.Features {
    width: 100%;
    padding: 36px;
    box-sizing: border-box;

    .contentWrapper {
        width: 100%;
        border-radius: 24px;
        padding: 70px 0 60px;
        position: relative;

        .creatorBackground {
            height: 100%;
            width: 100%;
        }

        .contentContent {
            .contentBlock {
                padding: 8px 0 40px;
                text-align: center;

                .contentTitle {
                    font: {
                        family: "Gabarito-Bold";
                        size: 2.0rem;
                    }
                    text-align: center;
                    color: $gravityGreen;
                }
                .contentDescription {
                    font: {
                        family: "Gabarito-Regular";
                        size: 1.2rem;
                    }
                    color: $purpleDark;
                    padding: 8px 0;
                    text-align: center;

                    .bolded {
                        font: {
                            family: "Gabarito-Bold";
                            size: 1.2rem;
                        }
                    }
                    .italics {
                        font-style: italic;
                    }
                }
                .contentFeature {
                    height: 40px;
                    font: {
                        family: "Gabarito-Bold";
                        size: 1.0rem;
                    }
                    text-align: center;
                    color: #fff;
                    background: purple;
                    border: none;
                    padding: 8px 16px;
                    border-radius: 20px;
                    transform: translateY(6px);

                    &:hover {
                        background: rgb(128, 0, 138);
                    }
                }
                .pricingTable {
                    display: flex;
                    justify-content: center;
                    padding: 20px;

                    .plan {
                        border: 2px solid #ddd;
                        padding: 20px 50px;
                        margin: 10px;
                        border-radius: 16px;
                        width: 420px;
                        text-align: center;
                        display: flex;
                        flex-direction: column;

                        h2 {
                            font: {
                                family: "Gabarito-Bold";
                                size: 1.6rem;
                            }
                            color: purple;
                        }

                        p {
                            color: #666;
                        }

                        .planPrice {
                            display: inline-block;
                            height: 30px;
                            font: {
                                family: "Gabarito-Bold";
                                size: 1.5rem;
                            }
                            margin-bottom: 0;
                            color: #333;
                        }
                        
                        .planDiscount {
                            display: inline-block;
                            font: {
                                family: "Gabarito-Bold";
                                size: 1.0rem;
                            }
                            background: $gravityGreen;
                            color: #fff;
                            border-radius: 4px;
                            padding: 4px 0;
                        }

                        .planDesc {
                            font: {
                                family: "Gabarito-Medium";
                                size: 1.2rem;
                            }
                        }

                        .features {
                            list-style: none;
                            padding: 0;
                            text-align: left;
                            flex-grow: 1;

                            li {
                                font: {
                                    family: "Gabarito-Regular";
                                    size: 1.0rem;
                                }
                                padding: 10px;
                                list-style-type: none;

                                &::before {
                                    content: "•";
                                    color: purple;
                                    display: inline-block;
                                    width: 1.0rem;
                                    margin-left: -1rem;
                                }
                                .unlimited {
                                    font: {
                                        family: "Gabarito-Bold";
                                    }
                                }
                            }
                        }

                        .signupButton {
                            display: inline-block;
                            height: 40px;
                            background: purple;
                            font: {
                                family: "Gabarito-Medium";
                                size: 1.0rem;
                            }
                            color: white;
                            padding: 10px 20px;
                            text-align: center;
                            text-decoration: none;
                            border: none;
                            border-radius: 20px;
                            cursor: pointer;
                            margin-top: 10px;

                            &.magicAIButton {
                                background-image: repeating-linear-gradient(
                                    45deg,
                                    violet,
                                    $gravityGreen,
                                    $orange,
                                    $red,
                                    violet
                                );
                                background-size: 800% 800%;
                                animation: rainbow 12s ease infinite;
                                color: white;

                                &:hover {
                                    box-shadow: 0 0 10px 4px rgb(72, 171, 224, 0.4);
                                }
                            }
                        }

                        &:hover {
                            border: 2px solid purple;
                        }
                    }
                }
            }
        }
        .premiumWrapper {
            max-width: 1080px;
            background: linear-gradient(
                90deg,
                rgb(21, 11, 42, 0.9) 0%,
                rgba(39, 30, 58, 0.9) 54%,
                rgba(21, 11, 42, 0.9) 100%
            );
            border-radius: 24px;
            text-align: center;
            padding: 12px 24px 50px;
            box-sizing: border-box;
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            .premium {
                width: 360px;
                display: inline-block;
                text-align: center;
                padding: 40px 6px 6px;

                .premiumTitle {
                    font: {
                        family: "Gabarito-Bold";
                        size: 1.75rem;
                    }
                    padding: 6px;

                    .text {
                        background-image: repeating-linear-gradient(
                            45deg,
                            violet,
                            $gravityGreen,
                            $orange,
                            $red,
                            violet
                        );
                        background-size: 800% 800%;
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                        animation: rainbow 12s ease infinite;
                    }
                }
                .premiumDescription {
                    width: 260px;
                    font: {
                        family: "Gabarito-Regular";
                        size: 1.0rem;
                    }
                    color: #fff;
                    padding: 8px;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}

@media only screen and (max-width: 620px) {
    .Features {
        .contentWrapper {
            .contentContent {
                .contentBlock {
                    .pricingTable {
                        display: block;
                        justify-content: left;
                        padding: 0;

                        .plan {
                            width: auto;
                            border: 1px solid #ddd;
                            padding: 20px;
                            margin: 8px 0px;
                        }
                    }
                }
            }
            .premiumWrapper {
                max-width: 100%;

                .premium {
                    width: 100%;
                }
            }
        }
    }
}
