@import '../exports/variables.scss';

@keyframes rotateGear {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
  
@keyframes unrotateGear {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

.ProfileControl {
  position: absolute;
  top: 0px;
  right: 0px;
  
  .options {
    display: inline-block;
    vertical-align: top;
    padding: 0 8px;

    .logoutWrapper {
      display: inline-block;
      vertical-align: middle;
      padding: 0 4px 0 8px;
      box-sizing: border-box;

      .logout {
        font: {
          family: 'Gabarito-Regular';
          size: 1.0rem;
        }
        border: none;
        background: none;
      }
    }
    .setting {
      display: inline-block;
      vertical-align: middle;

      &.menuAvatar {
        display: inline-block;

        .settingsIcon {
          height: 64px;
          background: none;
          border: none;
          padding: 0;
          position: relative;

          .avatar {
            height: 50px;
            width: 50px;
            background-size: cover;
            background-position-x: 50%;
            background-position-y: 50%;
            border-radius: 50%;
            border: none;
          }
          .avatarOverlay {
            height: 50px;
            width: 50px;
            background: rgba(0,0,0,0.4);
            position: absolute;
            top: 7px;
            border-radius: 50%;
          }
          .settingsImage {
            height: 20px;
            width: 20px;
            background-size: cover;
            background-position-x: 50%;
            background-position-y: 50%;
            position: absolute;
            top: 22px;
            left: 16px;
            animation: 250ms 0s normal forwards rotateGear;
          }
          &.open {
            .settingsImage {
              animation: 250ms 0s normal forwards unrotateGear;
            }
          }
        }
      }
    }
    .contextMenu {
      width: 140px;
      background: black;
      padding: 4px 8px;
      position: absolute;
      z-index: 2;
      top: 68px;
      right: 8px;
      border-radius: 6px;

      .languages {
        padding: 8px 0 16px;

        .languageWrapper {
          display: inline-block;
          vertical-align: top;
          padding: 0 4px 0 0;

          .language {
            height: 22px;
            width: 42px;
            font: {
              family: 'Gabarito-Bold';
              size: 0.8rem;
            }
            color: $gravityGreen;
            background: $purpleDark;
            border: 1px solid $gravityGreen;
            border-radius: 2px;

            &.zh {
              font: {
                size: 0.7rem;
              }
            }

            &.active {
              background: $gravityGreen;
              color: $purpleDark;
            }
          }
        }
      }

      .control {
        min-width: 100px;
        text-align: left;
        background: none;
        padding: 8px 0;
        border: none;

        .menuIcon {
          display: inline-block;
          vertical-align: middle;
          padding: 0 8px 0 4px;

          .menuImage {
            height: 16px;
            width: 16px;
          }
        }
        .menuText {
          display: inline-block;
          vertical-align: middle;
          font: {
            family: 'Gabarito-Regular';
            size: 1.0rem;
          }
          color: white;

          &.zh {
            font: {
              family: 'Gabarito-Regular';
              size: 0.9rem;
            }
          }
          
        }

        &:hover {
          .menuText {
            color: $gravityGreen;
          }
        }
      }
    }
  }
}