@import "../exports/variables.scss";

.QuickMenu {
    .shortcutWrapper {
        display: inline-block;
        vertical-align: top;
        width: 25%;
        padding: 8px;
        box-sizing: border-box;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        .shortcutButtonWrapper {
            text-align: center;

            .shortcutButton {
                height: 100px;
                width: 100px;
                background: $offWhite;
                border: none;
                border-radius: 22px;
                transform: scale(0.94);

                &.tutorials {
                    background: $gravityGreen;
                    border: 4px solid #fff;

                    .shortcutText {
                        color: #fff;
                    }
                    .shortcutLine {
                        background: #fff;
                    }
                }

                .shortcutImage {
                    height: 40px;
                    width: 40px;
                    padding: 0 0 4px;
                }
                .shortcutText {
                    font: {
                        family: "Gabarito-Bold";
                        size: 0.9rem;
                    }
                    color: $purpleLight;
                }
                .shortcutLine {
                    height: 4px;
                    width: calc(100% - 20px);
                    background: $gravityGreen;
                    position: relative;
                    left: 50%;
                    transform: translate(-50%, 8px);
                    border-radius: 2px;
                }

                &:hover {
                    transform: scale(1);
                }
            }
        }
    }
}

.mobile {
    .QuickMenu {
        .shortcutWrapper {
            display: inline-block;
            vertical-align: top;
            width: 25%;
            padding: 8px;
            box-sizing: border-box;
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            .shortcutButtonWrapper {
                text-align: center;

                .shortcutButton {
                    width: 88px;
                    transform: scale(0.7) translateX(-24px);
                    background: transparent;

                    .shortcutImage {
                        height: 30px;
                        width: 30px;
                    }
                    .shortcutText {
                        font: {
                            size: 0.8rem;
                        }
                    }
                }
            }
        }
    }
}
