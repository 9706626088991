@import "../exports/variables.scss";

.RecentVideos {
    .Loading {
        transform: translateX(calc(50% - 16px));
    }
    .videoWrapper {
        display: inline-block;
        vertical-align: top;
        height: 180px;
        width: calc(33% - 16px);
        padding: 8px;

        .videoInner {
            height: 100%;
            width: 100%;
            position: relative;
            border-radius: 12px;
            overflow: hidden;
            display: inline-flex;
            background: $dashGrey;
            border: 2px solid rgba(255, 255, 255, 0.4);
            box-shadow: $morphShadow;
            animation: 250ms 0s normal forwards hopUp;
            padding: 8px;

            &:hover {
                animation: 500ms 0s normal forwards hopDown;
            }

            .previewVideo {
                height: 100%;
                width: 200px;
                border-radius: 6px;
                background-size: cover;
                background-position: center;
                background-color: black;
                position: relative;

                &.voice {
                    background: rgb(175, 215, 200);
                }

                .voiceIcon {
                    height: 80px;
                    width: 80px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .openImage {
                    height: 22px;
                    width: 22px;
                    background: rgba(0, 0, 0, 0.4);
                    padding: 12px;
                    border-radius: 50%;
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    .openIcon {
                        height: 22px;
                        width: 22px;
                    }
                }
            }
            .previewTitle {
                height: 100%;
                width: 100%;
                text-align: left;
                overflow: hidden;
                padding: 2px 16px 2px 8px;
                position: relative;

                .previewDate {
                    display: inline-block;
                    height: 14px;
                    font: {
                        family: "Gabarito-Bold";
                        size: 0.8rem;
                    }
                    background: $gravityGreen;
                    padding: 4px 16px;
                    border-radius: 12px;
                }
                .previewDescription {
                    font: {
                        family: "Gabarito-Bold";
                        size: 1.0rem;
                    }
                    color: $greenDark;
                    padding: 8px 0;

                    &.text {
                        padding: 12px;
                        background: $textPurple;
                        color: $lightPurple;
                        transform: translateY(8px);
                        border-radius: 12px;
                    }
                    
                    .triangle {
                        width: 0;
                        height: 0;
                        border-top: 8px solid $textPurple;
                        border-right: 8px solid transparent;
                        border-left: 8px solid transparent;
                        position: absolute;
                        right: 48px;
                        bottom: -8px;
                    }
                }
                .previewCreator {
                    display: block;
                    width: 100%;
                    font: {
                        family: "Gabarito-Medium";
                        size: 1.0rem;
                    }
                    position: absolute;
                    bottom: 2px;
                }
            }
            .previewIndicator {
                height: 12px;
                width: 12px;
                background: $gravityGreen;
                position: absolute;
                right: 0px;
                top: 8px;
                border-radius: 50%;
                transform: translateX(-50%);
            }
        }
    }
    .updateEmpty {
        text-align: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        .emptyImage {
            .emptyIcon {
                height: 60px;
                width: 60px;
            }
        }
        .emptyText {
            font: {
                family: "Gabarito-Medium";
                size: 1.0rem;
            }
            color: $purpleDark;
        }
    }
}

.mobile {
    .RecentVideos {
        .videoWrapper {
            display: block;
            height: 121px;
            width: 240px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            .videoInner {
                padding: 4px;

                &:hover {
                    animation: 500ms 0s normal forwards hopDown;
                }

                .previewTitle {
                    .previewDate {
                        height: 12px;
                        font: {
                            size: 0.7rem;
                        }
                        padding: 4px 12px;
                    }
                    .previewDescription {
                        font: {
                            size: 0.9rem;
                        }
                        padding: 6px 0;
                    }
                    .previewCreator {
                        font: {
                            size: 0.9rem;
                        }
                    }
                }
            }
        }
    }
}
