@import "../exports/variables.scss";

.Hero {
  display: block;

  .menuBar {
    height: 80px;
    width: calc(100% - 24px);
    background: $purpleDarkOpaque;
    -webkit-backdrop-filter: saturate(100%) blur(4px);
    backdrop-filter: saturate(100%) blur(4px);
    padding: 12px 16px;
    position: fixed;
    box-sizing: border-box;
    z-index: 2;
    border-radius: 40px;
    left: 50%;
    transform: translate(calc(-50% - 4px), 8px);

    .gravityLogo {
      height: 56px;
      width: 160px;
    }
    .schoolLogo {
      height: 40px;
      position: absolute;
      top: 22px;
      left: 200px;
    }
    .buttonsWrapper {
      position: absolute;
      top: 16px;
      right: 16px;

      .buttonWrapper {
        display: inline-block;
        vertical-align: top;
        padding: 0 4px;

        .headerButton {
          height: 48px;
          min-width: 140px;
          font: {
            family: "Gabarito-Bold";
            size: 1.2rem;
          }
          border: none;
          padding: 8px 16px;
          background: transparent;
          border-radius: 24px;

          &.startHere {
            color: $gravityGreen;
            border: 3px solid $gravityGreen;
          }
          &.logIn {
            padding: 8px 32px;
            background: $gravityGreen;
          }
        }
      }
    }
  }
  .wrapper {
    min-height: 660px;
    width: 100%;
    position: relative;
    background: $purpleDark;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    overflow: hidden;
    z-index: 1;

    .content {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 2;

      .headerContent {
        height: calc(100% - 80px);
        width: 100%;
        transform: translateY(80px);
        color: white;

        .headerSection {
          display: inline-block;
          vertical-align: top;
          height: 100%;
          width: 50%;
          position: relative;

          .headerTextWrapper {
            display: inline-block;
            padding: 32px 94px;
            box-sizing: border-box;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;

            .headerText {
              max-width: 600px;
              background-image: repeating-linear-gradient(45deg, violet, $gravityGreen, $orange, $red, violet);
              background-size: 800% 800%;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              animation: rainbow 12s ease infinite;
              font: {
                family: "Gabarito-Black";
                size: 4.6rem;
              }
            }
            .headerSubtext {
              max-width: 600px;
              font: {
                family: "Gabarito-Regular";
                size: 1.5rem;
              }
              padding: 48px 0;

              .bold {
                font: {
                  family: "Gabarito-Bold";
                }
              }
            }
          }
          .heroGraphic {
            height: 340px;
            width: 100%;
            position: absolute;
            top: 40px;
            right: 0;
            z-index: 0;

            .graphicWrapper {
              position: absolute;
              z-index: 0;

              &.person1 {
                height: 260px;
                width: 260px;
                top: 20px;
                right: calc(50% + 60px);
                animation: 5s 0s normal forwards infinite float;
              }
              &.person2 {
                height: 160px;
                width: 160px;
                top: 180px;
                right: calc(50% - 130px);
                animation: 6s 0.5s normal forwards infinite float;
              }
              &.person3 {
                height: 120px;
                width: 120px;
                top: 80px;
                right: calc(50% - 260px);
                animation: 4.5s 0.35s normal forwards infinite float;
              }
              .graphic {
                height: 100%;
                width: 100%;
                border-radius: 50%;
              }
            }
          }
        }
        .backgroundGif {
          height: calc(100% + 80px);
          width: 100%;
          background: url("https://d3f260fu0a2g7m.cloudfront.net/marketing/gif/digital.gif");
          background-size: cover;
          opacity: 0.05;
          position: absolute;
          top: -80px;
          clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
          overflow: hidden;
        }
        .gradientFade {
          height: calc(100% + 80px);
          width: 50%;
          background: linear-gradient(90deg, rgb(20, 5, 45, 1) 0%, rgba(30, 10, 65, 0.5) 80%, rgba(0, 0, 0, 0) 100%);
          position: absolute;
          transform: translateY(-80px);
        }
      }
    }
  }
  .logoSlider {
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    top: -80px;
    right: 4px;

    &::before,
    &::after {
      content: "";
      height: 125px;
      position: absolute;
      z-index: 2;
    }

    ::before {
      left: 0;
      top: 0;
    }
    ::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    .logoSlideTrack {
      display: flex;
      width: calc(250px * 12);
    }
    .slide {
      height: auto;
      padding: 5px;
      text-align: center;
      animation: logoScroll 180s linear infinite;

      .logo {
        height: 70px;
        width: auto;
        padding: 0 10px 0 10px;

        &:hover {
          transform: scale(1.1);
        }
      }

      &:nth-child(1) {
        animation-delay: -8s;
      }
      &:nth-child(2) {
        animation-delay: -7.25s;
      }
      &:nth-child(3) {
        animation-delay: -6.5s;
      }
      &:nth-child(4) {
        animation-delay: -5.75s;
      }
      &:nth-child(5) {
        animation-delay: -5s;
      }
      &:nth-child(6) {
        animation-delay: -4.25s;
      }
      &:nth-child(7) {
        animation-delay: -3.5s;
      }
      &:nth-child(8) {
        animation-delay: -2.75s;
      }
      &:nth-child(9) {
        animation-delay: -2s;
      }
      &:nth-child(10) {
        animation-delay: -1.25s;
      }
      &:nth-child(11) {
        animation-delay: -0.5s;
      }
      &:nth-child(12) {
        animation-delay: 0.25s;
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .Hero {
    .wrapper {
      .content {
        .headerContent {
          .headerSection {
            max-width: 100%;

            .headerTextWrapper {
              padding: 0 32px;

              .headerText {
                max-width: 590px;
                font: {
                  size: 4.5rem;
                }
              }
              .headerSubtext {
                max-width: 600px;
                font: {
                  size: 1.4rem;
                }
                padding: 48px 0;

                .bold {
                  font: {
                    family: "Gabarito-Bold";
                  }
                }
              }
            }
            .heroGraphic {
              display: inline-block;
              width: 650px;
              position: relative;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1310px) {
  .wrapper {
    .content {
      .headerContent {
        .headerSection {
          .headerTextWrapper {
            .headerText {
              font: {
                size: 4.2rem !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1260px) {
  .Hero {
    .wrapper {
      .content {
        .headerContent {
          .headerSection {
            .headerTextWrapper {
              padding: 32px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  .Hero {
    .wrapper {
      .content {
        .headerContent {
          .headerSection {
            .headerTextWrapper {
              padding: 0 32px;

              .headerText {
                max-width: 410px;
                font: {
                  size: 3.4rem !important;
                }
              }
              .headerSubtext {
                max-width: 480px;
                font: {
                  size: 1.2rem;
                }
                padding: 48px 0;

                .bold {
                  font: {
                    family: "Gabarito-Bold";
                  }
                }
              }
            }
            .graphicWrapper {
              &.person1 {
                right: calc(50% + 60px);
              }
              &.person2 {
                right: calc(50% - 110px);
              }
              &.person3 {
                right: calc(50% - 210px);
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 880px) {
  .Hero {
    .wrapper {
      min-height: 860px;

      .content {
        .headerContent {
          .headerSection {
            display: block;
            height: auto;
            width: 100%;
            transform: none;

            .headerTextWrapper {
              top: auto;
              left: auto;
              transform: none;

              .headerText {
                max-width: 100%;
                font: {
                  size: 4.4rem !important;
                }
                padding: 20px;
              }
              .headerSubtext {
                max-width: 100%;
                font: {
                  size: 1.6rem;
                }
                padding: 40px 20px;

                .bold {
                  font: {
                    family: "Gabarito-Bold";
                    size: 1.7rem;
                  }
                }
              }
            }

            .heroGraphic {
              top: 0;

              .graphicWrapper {
                &.person1 {
                  top: 0;
                  right: calc(50% + 80px);
                }
                &.person2 {
                  top: 80px;
                  right: calc(50% - 140px);
                }
                &.person3 {
                  top: 40px;
                  right: calc(50% - 300px);
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 860px) {
  .Hero {
    .wrapper {
      min-height: 960px;

      .content {
        .headerContent {
          .headerSection {
            .heroGraphic {
              .graphicWrapper {
                &.person1 {
                  top: 0;
                  right: calc(50% + 80px);
                }
                &.person2 {
                  top: 50px;
                  right: calc(50% - 140px);
                }
                &.person3 {
                  top: 4px;
                  right: calc(50% - 300px);
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 620px) {
  .Hero {
    .menuBar {
      .buttonsWrapper {
        width: 100%;
        top: 22px;
        right: 4px;
        text-align: right;

        .buttonWrapper {
          .headerButton {
            height: 36px;
            min-width: 90px;
            font: {
              size: 1.1rem;
            }
            padding: 0 16px;

            &.logIn {
              padding: 0 32px;
            }
          }
        }
      }
    }
    .wrapper {
      min-height: 700px;

      .content {
        .headerContent {
          .headerSection {
            .headerTextWrapper {
              padding: 0 12px;

              .headerText {
                font: {
                  size: 3.0rem !important;
                }
              }
              .headerSubtext {
                font: {
                  size: 1.3rem;
                }
                padding: 20px;

                .bold {
                  font: {
                    size: 1.4rem !important;
                  }
                }
              }
            }

            .heroGraphic {
              .graphicWrapper {
                &.person1 {
                  height: 160px;
                  width: 160px;
                  right: calc(50% + 70px);
                }
                &.person2 {
                  height: 120px;
                  width: 120px;
                  top: 24px;
                  right: calc(50% - 94px);
                }
                &.person3 {
                  height: 80px;
                  width: 80px;
                  top: 0px;
                  right: calc(50% - 200px);
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .Hero {
    .menuBar {
      .buttonsWrapper {
        .buttonWrapper {
          .headerButton {
            min-width: 80px;
            font: {
              size: 1.0rem;
            }
            padding: 0 12px !important;
          }
        }
      }
    }
    .wrapper {
      min-height: 800px;

      .content {
        .headerContent {
          .headerSection {
            .headerTextWrapper {
              .headerText {
                font: {
                  size: 3.0rem !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
