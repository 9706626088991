/* Source Code Pro */
@font-face {
  font-family: 'SourceCodePro-MI';
  src: url('fonts/SourceCodePro-MediumItalic.ttf');
}
@font-face {
  font-family: 'SourceCodePro-M';
  src: url('fonts/SourceCodePro-Medium.ttf');
}

/* Gabarito */
@font-face {
  font-family: 'Gabarito-Black';
  src: url('fonts/Gabarito-Black.ttf');
}
@font-face {
  font-family: 'Gabarito-Bold';
  src: url('fonts/Gabarito-Bold.ttf');
}
@font-face {
  font-family: 'Gabarito-ExtraBold';
  src: url('fonts/Gabarito-ExtraBold.ttf');
}
@font-face {
  font-family: 'Gabarito-SemiBold';
  src: url('fonts/Gabarito-SemiBold.ttf');
}
@font-face {
  font-family: 'Gabarito-Regular';
  src: url('fonts/Gabarito-Regular.ttf');
}
@font-face {
  font-family: 'Gabarito-Medium';
  src: url('fonts/Gabarito-Medium.ttf');
}

.App {
  display: block;

  button,
  select {
    cursor: pointer;
  }

  div {
    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      background: transparent;
      position: relative;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(140,140,140);
      border-radius: 2px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .App {
    div {
      &::-webkit-scrollbar {
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        display: none;
      }
    }
  }
}