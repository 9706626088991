.ComboRecorder {
	.buttonControls {
		transform: translateY(8px) !important;
	}
	.hidden {
		display: none;
	}
	.hiddenVideos {
		.preview {
			height: 450px;
			width: 800px;
			background: black;
			border-radius: 6px;
		}
	}
}
