@import "../exports/variables.scss";

@keyframes listening {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  25% {
    transform: translateY(-1px);
    opacity: 0.5;
  }
  75% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0px);
  }
}

.Recorder {
  height: 530px;
  width: 832px;
  position: relative;
  padding: 16px;
  box-sizing: border-box;

  &.mirrorMode {
    padding-top: 120px;
  }

  .Loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 2px), calc(-50% - 40px)) !important;
  }

  .titleWrapper {
    height: 70px;
    width: calc(100% - 22px);
    padding: 16px 0 0;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 10px;

    &.disabled {
      opacity: 0.4;
    }

    .titleEntry {
      .mediaTitle {
        height: 23px;
        width: calc(100% - 66px);
        background: none;
        padding: 0 8px;
        border: none;
        border-bottom: 1px solid black;
        transform: translate(6px, -5px);
      }
      .characterCount {
        width: 50px;
        text-align: right;
        position: absolute;
        top: 30px;
        right: 10px;
      }
    }
  }

  .uploaderWrapper {
    height: 450px;
    width: 800px;
    background: black;
    border-radius: 6px;
  }

  .recorderCamera {
    height: 450px;
    width: 800px;
    transform: rotateY(0deg);
    border-radius: 6px;

    &.mirrored {
      transform: rotateY(180deg);
    }

    .previewVideo {
      display: block;
      height: 100%;
      width: 800px;
      background: rgba(20, 20, 20, 1);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
      border-radius: 6px;

      &.screen {
        width: 100%;
      }
    }
  }

  .monitorWrapper {
    display: none;
    position: absolute;
    top: calc(100% - 102px);
    right: 50px;

    &.embed {
      top: calc(100% - 124px);
    }
    
    &.active {
      display: block;
    }

    .voiceMicrophone {
      height: 12px;
      width: 12px;
      padding: 4px;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      left: 4px;
      transform: rotate(180deg);
      z-index: 1;

      .circleWrapper {
        padding: 1px;

        .circle {
          border-radius: 2px;
          position: relative;

          &.small {
            height: 4px;
            width: 4px;
            background: rgba(255, 255, 255, 0.4);
            transform: translateX(6px);
          }
          &.medium {
            height: 4px;
            width: 10px;
            background: rgba(255, 255, 255, 0.5);
            transform: translateX(3px);
          }
          &.large {
            height: 4px;
            width: 16px;
            background: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }
    .volumeWrapper {
      display: inline-block;
      width: 30px;
      height: 30px;
      transform: rotate(-90deg);
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      left: -4px;

      .volumeMonitor {
        width: 100%;
        height: 100%;
        background: rgba(250, 250, 250, 0.25);
        position: absolute;
        border-radius: 50%;
      }
    }
  }
  .recorderWrapper {
    text-align: center;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 5px));
    z-index: 1;

    .recorderButtonWrapper {
      display: inline-block;
      vertical-align: top;
      padding: 8px;
      position: relative;

      .betaTag {
        height: 16px;
        font: {
          family: "Gabarito-Medium";
          size: 0.75rem;
        }
        background: $gravityGreen;
        color: white;
        position: absolute;
        right: 0;
        padding: 2px 6px 1px;
        border-radius: 9px;
      }

      .recorderButton {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background: rgba(30, 30, 30, 1);
        border: 2px solid transparent;

        .contentWrapper {
          display: inline;
          position: relative;
          padding: 5px 0 0;

          .mediaType {
            .mediaIndicator {
              height: 30px;
              width: 30px;
            }
          }
          .buttonTitle {
            font: {
              family: "Gabarito-Medium";
              size: 1.0rem;
            }
            color: white;
            padding-top: 1px;
          }
        }
      }
    }

    &.disabled {
      display: none;
    }
  }

  .voiceAvatar {
    display: none;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;

    &.visible {
      display: block;
      opacity: 0.4;
    }
    &.active {
      opacity: 1;
    }
  }
  .recordedVideoWrapper {
    display: none;
    height: 450px;
    width: 800px;
    position: absolute;
    top: 16px;
    left: 16px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    overflow: hidden;

    .recordedVideo {
      height: 450px;
      width: 100%;
      position: relative;
      border-radius: 6px;
    }
    &.showing {
      display: block;
    }
  }

  .toggleFullscreen {
    display: none;
    height: 30px;
    width: 30px;
    background: rgba(250, 250, 250, 0.25);
    padding: 1px;
    position: absolute;
    top: calc(100% - 105px);
    right: 22px;
    border-radius: 8px;
    border: none;
    transform: translateY(-100%);
    position: absolute;
    z-index: 1;

    &.isResponse {
      transform: scale(0.8);
      top: 132px;
      right: 2px;
    }

    .fullscreenWrapper {
      height: 100%;
      width: 100%;

      .lineWrapper {
        position: absolute;

        &.top {
          top: 6px;
        }
        &.bottom {
          bottom: 6px;
        }
        &.left {
          left: 6px;
        }
        &.right {
          right: 6px;
        }
        &.topLeft {
          transform: rotate(-90deg) translate(-5px, 1px);
        }
        &.topRight {
          transform: translate(-5px, 1px);
        }
        &.bottomLeft {
          transform: rotate(180deg) translate(-5px, 0);
        }
        &.bottomRight {
          transform: rotate(90deg) translate(-4px, 1px);
        }
        .line {
          height: 2px;
          width: 6px;
          background: rgba(255, 255, 255, 0.9);
          position: absolute;

          &.horizontal {
            transform: translate(-1px, -1px);
          }
          &.vertical {
            transform: rotate(90deg) translate(1px, -1px);
          }
        }
      }
      &.minimize {
        .lineWrapper {
          &.topLeft {
            transform: rotate(90deg) translate(2px, -5px);
          }
          &.topRight {
            transform: rotate(180deg) translate(1px, -6px);
          }
          &.bottomLeft {
            transform: rotate(0deg) translate(1px, -4px);
          }
          &.bottomRight {
            transform: rotate(270deg) translate(0, -5px);
          }
        }
      }
    }

    &.active {
      display: block;
    }
    &[disabled] {
      display: none;
    }
  }

  .progressBar {
    display: none;
    height: 6px;
    width: calc(100% - 32px);
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 460px;
    z-index: 1;

    &.active {
      display: block;
    }

    .progressHead {
      height: 6px;
      background: purple;
      position: absolute;
    }
  }

  .recordControls {
    position: absolute;
    transform: translate(3px, -40px);
    z-index: 1;

    .recControlWrapper {
      display: inline-block;
      padding: 0 4px;

      .recControl {
        height: 32px;
        background: rgba(255, 255, 255, 0.4);
        border: none;
        border-radius: 16px;
        padding: 0 16px 0 8px;

        &:disabled {
          opacity: 0.4;
          cursor: pointer;
        }

        .textWrapper {
          display: inline-block;
          font: {
            family: "Gabarito-Bold";
            size: 1.0rem;
          }
          color: $purpleLight;
        }

        .iconWrapper {
          display: inline-block;
          vertical-align: middle;
          padding: 0 0 0 2px;

          .pauseWrapper {
            height: 16px;
            width: 20px;

            &.paused {
              animation: 1s 0s normal forwards infinite fadeInOut;
            }

            .lineWrapper {
              display: inline-block;
              padding: 0 0 0 2px;

              .pause {
                height: 16px;
                width: 4px;
                background: $purpleLight;
                opacity: 0.4;
              }
            }
          }
        }
      }
    }
  }
  .cameraSelector {
    position: absolute;
    right: 60px;
    transform: translate(0, -40px);
    z-index: 1;

    .cameraSelect {
      height: 32px;
      width: 190px;
      background: rgba(255, 255, 255, 0.4);
      font: {
        family: "Gabarito-Bold";
        size: 1.0rem;
      }
      border: none;
      border-radius: 16px;
      padding: 0 0 0 8px;
    }
  }

  .timeWrapper {
    display: none;
    height: 34px;
    width: 94px;
    font: {
      family: "Gabarito-Bold";
      size: 1.6rem;
    }
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    text-align: center;
    padding: 0 12px;
    position: absolute;
    top: calc(100% - 104px);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 16px;
    background: rgba(250, 250, 250, 0.25);
    z-index: 1;

    &.embed {
      top: calc(100% - 126px);
    }

    &.active {
      display: block;
    }

    .recorderStatus {
      display: inline-block;
      vertical-align: middle;
      padding: 0 4px 0 0;

      .statusShape {
        height: 16px;
        width: 16px;

        &.idle {
          background: $offWhite;
          border-radius: 50%;
          box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
        }
        &.recording {
          background: rgba(220, 0, 0, 1);
          border-radius: 50%;
          box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
          animation: 2s 0s normal forwards infinite fadeInOut;
        }
      }
    }

    .recorderTime {
      display: none;
    }
    .playerTime {
      display: none;

      .playTime {
        display: inline-block;
      }
      .playDivider {
        display: inline-block;
        color: $playDivider;
        text-shadow: none;
        padding: 0 4px;
        transform: translateY(-2px);
      }
      .totalTime {
        display: inline-block;
      }
    }
    .active {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .buttonControls {
    text-align: center;
    transform: translateY(12px);

    .buttonWrapper {
      display: inline-block;
      vertical-align: top;
      padding: 0 4px;

      .playerButton {
        height: 50px;
        width: 50px;
        background: rgba(245, 245, 245, 1);
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.75);

        &.submitVideo {
          background: rgba(0, 200, 100, 1);

          .confirmWrapper {
            position: relative;
            transform: translate(19px, 12px) rotate(45deg);

            .checkmarkLong {
              height: 20px;
              width: 5px;
              background: rgba(255, 255, 255, 0.9);
            }
            .checkmarkShort {
              height: 11px;
              width: 5px;
              background: rgba(255, 255, 255, 0.9);
              position: absolute;
              transform: translate(-3px, -3px) rotate(-90deg);
            }
          }
        }

        &[disabled] {
          opacity: 0.3;
          cursor: default;
        }

        .iconWrapper {
          .icon {
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            &.record {
              height: 20px;
              width: 20px;
              background: rgba(220, 0, 0, 1);
              border-radius: 50%;
            }
            &.stop {
              height: 20px;
              width: 20px;
              background: rgba(220, 0, 0, 1);
              border-radius: 2px;
            }
            &.play {
              width: 0;
              height: 0;
              border-top: 10px solid rgba(0, 0, 0, 0);
              border-left: 16px solid rgba(0, 0, 0, 0.45);
              border-bottom: 10px solid rgba(0, 0, 0, 0);
              border-radius: 2px;
              transform: translateX(calc(-50% + 3px));
            }
            &.pauseWrapper {
              height: 20px;
              width: 18px;
              display: inline-block;
              top: 2px;
              left: 12px;

              .pause {
                height: 20px;
                width: 5px;
                background: rgba(0, 0, 0, 0.45);
                position: absolute;

                &.left {
                  left: 0;
                }
                &.right {
                  left: 10px;
                }
              }
            }
            &.redoWrapper {
              height: 22px;
              width: 22px;
              position: relative;

              .redoCircle {
                height: 16px;
                width: 16px;
                border-radius: 50%;
                border: 3px solid rgba(140, 140, 140, 1);
              }
              .redoBlock {
                height: 6px;
                width: 6px;
                background: rgba(245, 245, 245, 1);
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(calc(-50% + 1px));
              }
              .redoTriangle {
                width: 0;
                height: 0;
                border-left: 5px solid rgba(0, 0, 0, 0);
                border-right: 5px solid rgba(0, 0, 0, 0);
                border-top: 5px solid rgba(140, 140, 140, 1);
                position: absolute;
                left: -3px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            &.cameraOffWrapper {
              height: 26px;
              width: 26px;
              position: relative;

              .offDevice {
                height: 22px;
                width: 22px;
                position: absolute;
                top: 3px;
                left: 3px;

                .delete {
                  .plus {
                    height: 22px;
                    width: 3px;
                    background: rgba(140, 140, 140, 1);
                    position: absolute;
                    left: 50%;

                    &.vertical {
                      transform: translateX(-50%) rotate(45deg);
                    }
                    &.horizontal {
                      transform: translateX(-50%) rotate(-45deg);
                    }
                  }
                }
              }
            }
            &.submitWrapper {
              transform: translate(-22px, -1px);
            }
          }
        }
      }
    }
  }

  .queueingWrapper {
    height: 450px;
    width: 800px;
    position: absolute;
    top: 16px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.6);

    .countdownWrapper {
      height: 200px;
      width: 200px;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .countdownNumber {
        font: {
          family: "Gabarito-Medium";
          size: 12rem;
        }
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .originalityWrapper {
    position: absolute;
    top: 568px;
    right: 16px;

    &.transcribeWrapper {
      top: 541px;
      right: 16px;

      .translateSelect {
        height: 24px;
        width: 100px;
        font: {
          family: "Gabarito-Medium";
          size: 0.9rem;
        }
        border: none;
        border-radius: 11px;
        padding: 2px;
        outline: 2px solid #00d89f;
      }
    }

    &.questionType {
      top: 680px;

      .originalityTitle {
        font: {
          family: "Gabarito-Bold";
        }
      }
    }

    &.toneWrapper {
      top: 595px;
      right: 14px;
    }

    &.followUp {
      top: 650px;
      right: 14px;
    }

    .originalityInfo {
      display: inline-block;
      vertical-align: top;
      position: relative;

      .infoBubble {
        width: 240px;
        background: white;
        font: {
          family: "Gabarito-Regular";
          size: 0.75rem;
        }
        color: $purpleDark;
        padding: 8px;
        border-radius: 6px;
        position: absolute;
        bottom: 24px;
        right: -120px;
        z-index: 1;

        &.toneBubble {
          width: 220px;
        }

        .triangle {
          width: 0;
          height: 0;
          border-top: 8px solid transparent;
          border-left: 10px solid rgba(255, 255, 255, 0.98);
          border-bottom: 8px solid transparent;
          position: absolute;
          right: 112px;
          bottom: -8px;
        }
      }

      .info {
        .infoIcon {
          height: 12px;
          width: 12px;
        }
        transform: translate(-4px, -2px);
        cursor: pointer;
      }
    }

    .originalityTitle {
      display: inline-block;
      vertical-align: middel;
      font: {
        family: "Gabarito-Medium";
        size: 1.0rem;
      }
      color: $purpleDark;
      padding-right: 10px;

      .keywordAI {
        display: inline-block;
        height: 17px;
        width: 17px;
        font: {
          family: "Gabarito-Black";
          size: 0.7rem;
        }
        color: #fff;
        background: $gravityGreen;
        padding: 2px 3px;
        box-sizing: border-box;
        border-radius: 2px;
      }
      .keywordWord {
        display: inline-block;
        font: {
          family: "Gabarito-Bold";
        }
        color: $purpleLight;
        padding: 0 2px 0 4px;
        transform: translateY(2px);
      }
    }
    .originalityScore {
      display: inline-block;
      vertical-align: middle;
      height: 24px;
      width: 100px;
      background: rgba(0, 0, 0, 0);
      opacity: 0;
      box-shadow:
        inset 4px 4px 4px rgba(100, 100, 100, 0.3),
        inset -4px -4px 5px rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.2);
      animation: 0.5s 0.2s normal forwards growFromFlat;
      border-radius: 15px;
      position: relative;

      &.toneScore {
        width: 103px;
      }

      &.followUp {
        box-shadow: none;
        border-color: transparent;

        .Switch {
          transform: scale(0.8) translate(58px, -3px);
        }
      }

      .scoreStatus {
        width: calc(100% + 2px);
        position: absolute;

        .dotContainer {
          padding: 8px 0;
          position: relative;

          .dot {
            height: 10px;
            width: 10px;
            background: rgba(240, 240, 240, 1);
            border-radius: 50%;
            position: absolute;

            &.dot1 {
              left: 30px;
              animation: 1200ms 0s normal infinite listening;
            }
            &.dot2 {
              left: 45px;
              animation: 1200ms 250ms normal infinite listening;
            }
            &.dot3 {
              left: 60px;
              animation: 1200ms 500ms normal infinite listening;
            }
          }
        }

        .scoreWrapper {
          width: 100%;

          .toneBar {
            display: inline-block;
            height: 22px;
            box-shadow:
              inset 4px 4px 4px rgba(100, 100, 100, 0.3),
              inset -4px -4px 5px rgba(255, 255, 255, 0.5);
            border: 1px solid rgba(255, 255, 255, 0.2);
            animation: 0.5s 0.2s normal forwards growFromFlatTone;
            border-radius: 12px;

            &.green {
              background: $gravityGreen;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
            &.red {
              background: $red;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
            &.white {
              background: white;

              &.negative {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
              &.positive {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
            }

            &.full {
              border-radius: 12px;
            }
            &.empty {
              display: none;
            }
          }

          .scoreBar {
            height: 22px;
            box-shadow:
              inset 4px 4px 4px rgba(100, 100, 100, 0.3),
              inset -4px -4px 5px rgba(255, 255, 255, 0.5);
            border: 1px solid rgba(255, 255, 255, 0.2);
            animation: 0.5s 0.2s normal forwards growFromFlat;
            border-radius: 12px;

            &.green {
              background: $gravityGreen;
            }
            &.yellow {
              background: $yellow;
            }
            &.orange {
              background: $orange;
            }
            &.red {
              background: $red;
            }
            &.clear {
              background: transparent;
            }
          }
          .scoreNumber {
            font: {
              family: "Gabarito-Black";
              size: 1.0rem;
            }
            color: $purpleLight;
            text-align: center;
            transform: translateY(-21px);

            &.scoreTone {
              transform: translateY(-26px);
            }

            &.score-0 {
              color: $red;
            }
          }
        }
      }
    }
  }

  .echoCancellation {
    text-align: center;
    opacity: 0;
    vertical-align: top;

    &.active {
      display: none;
      opacity: 1;
    }
    .echoText {
      vertical-align: top;
    }
    .echoCancel {
      height: 16px;
      width: 16px;
    }
  }
  .respondingTo {
    display: inline-block;
    padding: 12px;
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(calc(-50% + 4px));

    .respondingAvatar {
      display: inline-block;
      height: 36px;
      width: 36px;

      .PeopleAvatar {
        .imageWrapper {
          height: 36px;
          width: 36px;
          box-shadow: none;
        }
      }
    }
  }
  .status {
    display: none;

    &.active {
      display: block;
    }
  }

  &.fullscreen {
    height: 100%;
    width: 100%;
    padding: 0;
    position: fixed;
    z-index: 2;

    .recorderCamera {
      height: 100%;
      width: 100%;
    }
    .recordedVideoWrapper {
      height: 100%;
      width: 100%;
      background: black;
      top: 0;
      border-radius: 0;

      .recordedVideo {
        height: 100%;
        width: 100%;
      }
    }
    .toggleFullscreen {
      top: calc(100% - 12px);
    }
    .timeWrapper {
      top: calc(100% - 40px);
    }
    .progressBar {
      top: calc(100% - 6px);
    }
    .buttonWrapper {
      .playPauseButton {
        height: 30px;
        width: 30px;
        background: rgba(250, 250, 250, 0.25);
        box-shadow: none;
        border: none;
        position: absolute;
        bottom: 12px;
        left: 4px;
        border-radius: 8px;
        z-index: 1;

        .iconWrapper {
          transform: scale(0.7);

          .icon {
            &.play {
              border-top: 10px solid rgba(0, 0, 0, 0);
              border-left: 16px solid rgba(255, 255, 255, 0.9);
              border-bottom: 10px solid rgba(0, 0, 0, 0);
              border-radius: 2px;
              transform: translate(calc(-50% + 2px), 1px);
            }
            &.pauseWrapper {
              .pause {
                background: rgba(255, 255, 255, 0.9);
                transform: translate(calc(-50% + 1px), -1px);
              }
            }
          }
        }
      }
    }
  }
}
.successStatus {
  height: 340px;
  width: 832px;
  position: relative;
  padding: 16px;
  box-sizing: border-box;
}

.mobile {
  .Recorder {
    height: 400px;
    width: 100%;
    padding: 4px;

    .recorderCamera {
      height: 280px;
      width: 100%;

      .previewVideo {
        width: 100%;
      }
    }

    .originalityWrapper {
      top: 400px;
      right: 16px;

      &.transcribeWrapper {
        display: block;
        position: absolute;
        top: 356px;
        right: 16px;

        .originalityTitle {
          display: block;
        }
        .translateSelect {
          height: 22px;
        }
      }

      &.toneWrapper {
        left: 16px;
      }

      .originalityScore {
        display: block;
      }
    }

    .recordedVideoWrapper {
      height: 280px;
      width: calc(100% - 8px);
      top: 4px;
      left: 4px;

      .recordedVideo {
        height: 280px;
      }
    }
  }
}
