@import '../exports/variables.scss';

.Loading {
  display: none;
  padding: 8px;
  border-radius: 8px;
  z-index: 1;

  &.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.active {
    display: block;
  }
  /*&.transparent {
    background: rgba(0,0,0,0.0);
  }*/

  .loadingWrapper {
    height: 38px;
    width: 38px;
    position: relative;

    .circleWrapper {
      height: 100%;
      width: 100%;
      position: absolute;

      &.large {
        animation: 0.75s 0s normal forwards infinite spinForward;
      }
      &.small {
        animation: 0.75s 0s normal forwards infinite spinForward;
      }

      .halfCircle {
        background-color: transparent;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border: 4px solid rgba(220,130,255,1.0);
        border-bottom: 0;
        position: absolute;
        animation: 4.75s 0s normal forwards infinite colorTransition;
    
        &.large {
          width: 30px;
          height: 15px;
        }
        &.small {
          width: 16px;
          height: 8px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, calc(-50% - 5px));
        }
      }
    }
  }
}