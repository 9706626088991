@import '../exports/variables.scss';

.Upload {
  display: inline-block;
  position: relative;

  .uploading {
    position: absolute;
    top: 64px;
    left: 50%;
    transform: translateX(-50%);

    .Loading {
      transform: translateX(23px);
    }

    .loaderText {
      display: inline-block;
      font: {
        family: "Gabarito-Regular";
        size: 1.0rem;
      }
      color: black;
      padding: 0 8px;
      animation: 2s 0s normal forwards infinite fadeInOut;
    }
  }

  .selectedOptions {
    padding: 16px 8px;

    .selected {
      width: 350px;
      border: 1px dashed $gravityGreen;
      background: rgb(240,240,240);
      border-radius: 16px;

      .fileType {
        display: inline-block;
        height: 40px;
        width: 30px;
        background: white;
        border: none;
        border-radius: 2px;
        position: relative;
        text-align: center;
        left: 50%;
        transform: translate(-50%,16px);
        box-shadow: -3px 3px 3px rgba(0,0,0,0.2);

        .text {
          font: {
            family: "Gabarito-Medium";
            size: 0.6rem;
          }
          color: #888;
          text-transform: lowercase;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
        .fold {
          width: 0;
          height: 0;
          border-bottom: 10px solid $gravityGreen;
          border-right: 10px solid rgb(240,240,240);
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
  .uploadOptions {
    padding: 16px 8px;
    
    .drop {
      height: 100px;
      width: 350px;
      border: 1px dashed #a5a5a5;
      font: {
        family: "Gabarito-Medium";
        size: 1.0rem;
      }
      color: #888;
      position: relative;
      border-radius: 16px;

      &.active {
        padding: 12px;
      }

      .message {
        display: inline-block;
        text-align: center;
        position: relative;
        top: calc(50% + 8px);
        left: 50%;
        transform: translate(-50%,-50%);
      }
      .or {
        font: {
          family: "Gabarito-Bold";
          size: 0.7rem;
        }
        color: black;
        text-align: center;
        padding: 8px 0 4px;
      }
    }
  }

  .input {
    position: absolute;
    visibility: hidden;
  }

  .buttonWrapper {
    text-align: center;
    padding: 4px 16px 16px;

    .selectButton {
      display: inline-block;
      height: 30px;
      font: {
        family: "Gabarito-Medium";
        size: 1.0rem;
      }
      color: white;
      background: $purpleLight;
      border: none;
      padding: 4px 90px;
      border-radius: 15px;
      position: relative;

      .icon {
        font: {
          size: 0.8rem;
        }
      }
    }
  }

  .buttonGroup {
    display: inline-block;
    padding: 16px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    
    .buttonWrapper {
      display: inline-block;
      padding: 0 4px;

      .button {
        height: 30px;
        width: 250px;
        font: {
          family: "Gabarito-Medium";
          size: 1.0rem;
        }
        border-radius: 15px;

        &.cancel {
          color: white;
          background: $purpleLight;
          border: none;
        }
        &.upload {
          color: white;
          background: $gravityGreen;
          border: none;
        }
        &:disabled {
          opacity: 0.4;
          cursor: default;
        }
      }
    }
  }

  .data {
    padding: 32px 16px 12px;

    .metadata {
      padding: 4px 0;

      .label {
        font: {
          family: "Gabarito-Medium";
          size: 1.0rem;
        }
        color: #888;
      }
      .fileTitle {
        padding: 4px 8px;
        font: {
          family: "Gabarito-Regular";
          size: 0.9rem;
        }
        word-break: break-all;
        color: black;
      }
    }
  }

  .uploadWrapper {
    .uploadSuccess {
      font: {
        family: 'Gabarito-Medium';
        size: 1.2rem;
      }
      color: black;
    }
  }
  .uploadMore {
    color: white;
    background: #888;
    border: none;
  }
}

.mobile {
  .Upload {
    width: 100%;
    left: auto;
    transform: none;

    .uploadOptions {
      padding: 12px 0px 12px;

      .drop {
        width: 100%;
      }
    }
  }
}