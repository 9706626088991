@import '../exports/variables.scss';

@keyframes rotateGear {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(90deg);
  }
}

@keyframes unrotateGear {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(-90deg);
  }
}

.Header {
  height: 60px;
  width: calc(100% - 4px);
  background: #fcfcfc;

  .content {
    width: 100%;

    .logo {
      display: inline-block;
      width: calc(100% - 70px);
      padding: 14px 8px 8px;

      .gravityHome {
        background: none;
        border: none;
        padding: 0;
        
        .gravityLogo {
          height: 34px;
        }
      }
    }

    .options {
      display: inline-block;
      vertical-align: top;
      width: 50px;

      .logoutWrapper {
        display: inline-block;
        vertical-align: middle;
        padding: 0 4px 0 8px;

        .logout {
          font: {
            family: 'Gabarito-Regular';
            size: 1.0rem;
          }
          border: none;
          background: none;
        }
      }
      .setting {
        display: inline-block;
        vertical-align: middle;

        &.menuAvatar {
          display: inline-block;
  
          .settingsIcon {
            height: 60px;
            background: none;
            border: none;
            padding: 0;
            position: relative;

            .avatar {
              height: 50px;
              width: 50px;
              background-size: cover;
              background-position-x: 50%;
              background-position-y: 50%;
              border-radius: 50%;
              border: none;
            }
            .avatarOverlay {
              height: 50px;
              width: 50px;
              background: rgba(0,0,0,0.4);
              position: absolute;
              top: 5px;
              border-radius: 50%;
            }
            .settingsImage {
              height: 20px;
              width: 20px;
              background-size: cover;
              background-position-x: 50%;
              background-position-y: 50%;
              position: absolute;
              top: 20px;
              left: 15px;
              animation: 250ms 0s normal forwards rotateGear;
            }
            &.open {
              .settingsImage {
                animation: 250ms 0s normal forwards unrotateGear;
              }
            }
          }
        }
      }
    }
  }
}