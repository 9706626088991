@import '../exports/variables.scss';

.Reaction {
  position: relative;

  &.dark {
    .reactions {
      .wrapper {
        .emojiWrapper {
          .entityWrapper {
            color: white;
          }
        }
      }
    }
  }
  .reactions {
    display: inline-block;
    vertical-align: top;
    height: 26px;
    width: calc(100% - 46px);
    padding: 0 0 4px;

    .wrapper {
      .emojiWrapper {
        padding: 0 2px;
        background: none;
        border: none;
        
        &.disabled {
          pointer-events: none;
        }

        .entityWrapper {
          display: inline-block;
          height: 22px;
          background: #003922;
          padding: 4px 8px 0px;
          border-radius: 13px;

          &.selected {
            background: $gravityGreen;

            .countWrapper {
              .count {
                color: #003922;
              }
            }
          }

          .symbolWrapper {
            display: inline-block;

            .symbol {
              display: block;
            }
          }
          .countWrapper {
            display: inline-block;
            padding: 0 4px;

            .count {
              display: block;
              font: {
                family: 'Gabarito-Bold';
                size: 0.9rem;
              }
              color: white;
            }
          }
        }
      }
    }
    .noReactions {
      font: {
        family: 'Gabarito-Regular';
        size: 0.8rem;
      }
      color: #999;
    }
  }
  .addEmoji {
    display: inline-block;

    .openEmoji {
      height: 26px;
      width: 46px;
      background: #003922;
      text-align: center;
      font: {
        family: 'Gabarito-Medium';
        size: 1.0rem;
      }
      color: white;
      border: none;
      border-radius: 13px;

      &.open {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border: 2px solid $gravityGreen;
        background: $gravityGreen;
        color: #003922;
      }
    }
  }
  .emojis {
    max-height: 120px;
    width: 162px;
    background: rgba(255,255,255,0.9);
    padding: 4px;
    position: absolute;
    top: 26px;
    right: 0;
    box-sizing: border-box;
    z-index: 1;
    border-radius: 6px;
    border-top-right-radius: 0;
    overflow-y: scroll;

    .buttonWrapper {
      display: inline-block;
      padding: 2px;

      .emoji {
        height: 26px;
        width: 26px;
        border: none;
        background: none;
        border-radius: 6px;

        &:disabled {
          background: white;
        }
      }
    }
  }
}