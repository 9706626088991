@import "../exports/variables.scss";

.Video {
    position: relative;

    &.noTranscript {
        animation: 250ms 0s normal forwards videoTranscriptClose;
    }
    &.transcript {
        animation: 250ms 0s normal forwards videoTranscriptOpen;
    }

    .videoError {
        position: relative;

        .errorWrapper {
            display: inline-block;
            width: 100%;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .errorImageWrapper {
                .errorImage {
                    height: 60px;
                    width: 60px;
                }
            }
            .errorText {
                width: 100%;
                font: {
                    family: "Gabarito-Medium";
                    size: 1.0rem;
                }
                color: $lightErrorPurple;
                padding: 0 8px 8px;
            }
            .retryWrapper {
                padding: 8px;

                .retry {
                    height: 40px;
                    font: {
                        family: "Gabarito-Bold";
                        size: 1.0rem;
                    }
                    color: white;
                    background: $lightErrorPurple;
                    padding: 0 32px;
                    border: none;
                    border-radius: 24px;
                }
            }
        }
    }

    .videoPlayer {
        display: inline-block;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        &.main {
            min-height: 292px;
        }

        &.transcriptOpen {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
        &.transcriptClosed {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            animation: 500ms 0s normal forwards videoTranscriptClose;
        }
        &.voice {
            background: rgba(175, 215, 200, 1);
        }
    }
    .posterBackground {
        position: absolute;
        top: 0;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        opacity: 0;
        z-index: -1;
        pointer-events: none;

        &.active {
            width: 100%;
            height: 100%;
            background: #000;
            opacity: 1;
            z-index: auto;
            pointer-events: auto;
        }
        .posterImage {
            height: 100%;
            width: 100%;
            border-radius: 6px;
            background-size: cover;
            background-position: center;
            background-color: black;
            position: relative;
            opacity: 0.4;
            box-shadow: inset 0 0 100px rgba(0, 0, 0, 1);
        }
        .overlayPlayButton {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: none;
            border: none;

            .openImage {
                height: 50px;
                width: 50px;
                background: rgba(0, 0, 0, 0.4);
                padding: 12px;
                border-radius: 50%;
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .openIcon {
                    height: 50px;
                    width: 50px;
                }
            }
        }
    }
    .voiceIcon {
        height: 100px;
        width: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 8px));
    }
}

.transcriptButton {
    height: 40px;
    width: 40px;
    background: $clearGrey;
    border: none;
    position: absolute;
    top: 6px;
    left: 6px;
    z-index: 1;
    border-radius: 50%;

    &.open {
        background: $clearGreen;
    }

    .transcriptIcon {
        height: 20px;
        width: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% + 2px));
    }
}

.translateButton {
    height: 40px;
    width: 40px;
    background: $clearGrey;
    border: none;
    position: absolute;
    top: 6px;
    left: 56px;
    z-index: 1;
    border-radius: 50%;

    &.open {
        background: $clearGreen;
    }

    .Loading {
        transform: scale(0.5) translate(-50%, calc(-50% + 8px));
    }

    .translateIcon {
        height: 22px;
        width: 22px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.translateWrapper {
    height: 30px;
    position: absolute;
    top: 6px;
    left: 106px;
    z-index: 1;

    .translateOption {
        display: inline-block;
        padding: 0 2px;

        .translateSelect {
            height: 30px;
            width: 120px;
            font: {
                family: "Gabarito-Regular";
                size: 0.9rem;
            }
            border: none;
            background: $clearGrey;
            padding: 4px 8px;
            border-radius: 15px;

            &:focus {
                outline: none;
            }

            &:disabled {
                color: #000;
                opacity: 0.3;
            }
        }
    }

    .translateArrowIcon {
        display: inline-block;
        height: 12px;
        width: 12px;
        background: $clearGrey;
        opacity: 0.3;
        padding: 4px;
        border-radius: 50%;
        transform: translateY(5px);

        &.active {
            background: $clearGrey;
            opacity: 1;
        }
        &.valid {
            background: $clearGreen;
        }
    }
    .loaderWrapper {
        display: inline-block;
        height: 10px;
        width: 20px;
        transform: scale(0.4) translate(-16px, -16px);
    }
}

.transcriptWrapper {
    display: none;
    background: $purpleDark;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    transform: translateY(-4px);
    margin-top: -1px;

    &.open {
        display: block;
        height: 0;
        animation: 500ms 0s normal forwards drawerOpen;
    }

    &.closed {
        display: block;
        height: 120px;
        animation: 500ms 0s normal forwards drawerClose;
    }

    .transcript {
        height: calc(100% - 32px);
        width: calc(100% - 32px);
        background: white;
        font: {
            family: "Gabarito-Regular";
            size: 1.0rem;
        }
        transform: translate(16px, 16px);
        overflow-y: scroll;
        border-radius: 6px;

        .transcriptText {
            padding: 8px;
            box-sizing: border-box;
            text-align: left;

            .transcriptNote {
                font: {
                    size: 0.7rem;
                }
                color: $midGrey;
                padding: 8px 0 0;
            }
        }
    }
}

.mobile {
    .Video {
        .videoPlayer {
            &.main {
                min-height: 0;
                width: 100%;
            }
        }
    }
}
