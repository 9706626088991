@import "../exports/variables.scss";

.Content {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  .banner {
    height: 64px;
    width: calc(100% - 18px);
    background: rgb(230, 230, 230, 0.95);
    position: fixed;
    top: 8px;
    left: 50%;
    transform: translateX(calc(-50% - 4px));
    z-index: 4;
    border-radius: 32px;
    -webkit-backdrop-filter: saturate(100%) blur(4px);
    backdrop-filter: saturate(100%) blur(4px);

    .gravityHome {
      background: none;
      border: none;
      padding: 16px 20px;

      .gravityLogo {
        height: 34px;
      }
      /*.homeButton {
        height: 20px;
        width: 20px;
        padding: 0 0 0 20px;
        transform: translateY(-8px);
      }*/
    }

    .announcement {
      width: calc(100% - 190px);
      text-align: center;
      padding: 12px 16px 0;
      box-sizing: border-box;
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translate(calc(-50% + 28px), 0);

      .newWrapper {
        padding: 0 4px 0 0;

        .new {
          font: {
            family: "Gabarito-Bold";
            size: 1.0rem;
          }
          background: $gravityGreen;
          color: #fff;
          padding: 4px 8px;
          border-radius: 8px;
        }
      }
      .text {
        font: {
          family: "Gabarito-Regular";
          size: 1.0rem;
        }

        .bold {
          font: {
            family: "Gabarito-Bold";
            size: 1.0rem;
          }
        }
      }
    }

    .typeWrapper {
      display: inline-block;
      width: 100%;
      text-align: center;
      position: relative;
      padding: 6px;
      left: 50%;
      transform: translateX(-50%);
      box-sizing: border-box;

      .assetWrapper {
        width: 100%;

        .backWrapper {
          padding: 0 5px;
          position: absolute;
          top: -60px;
          right: 82px;

          .backButton {
            height: 40px;
            font: {
              family: "Gabarito-Bold";
              size: 1.0rem;
            }
            color: black;
            background: #00d89f;
            padding: 0 32px;
            border: none;
            border-radius: 24px;
          }
        }
        .assetTitle {
          display: inline-block;
          position: relative;
          transform: translateX(-12px);

          .type {
            display: inline-block;
            vertical-align: middle;
            padding: 2px 4px 0;
            .icon {
              height: 16px;
              width: 16px;
            }
          }
          .title {
            display: inline-block;
            vertical-align: middle;
            font: {
              family: "Gabarito-Medium";
              size: 1.0rem;
            }
            color: black;
            padding: 5px;
          }
        }
      }

      .timeLimit {
        display: inline-block;
        top: -74px;
        position: absolute;

        .sharedLimit {
          font: {
            family: "Gabarito-Medium";
            size: 0.7rem;
          }
          color: grey;
          transform: translate(16px, 16px);
        }
        .timeBar {
          display: inline-block;
          height: 30px;
          padding: 4px 6px;
          border-radius: 20px;

          .timeWrapper {
            display: inline-block;
            vertical-align: middle;
            height: 30px;
            width: 30px;
            background: #000;
            border: 2px solid $gravityGreen;
            position: relative;
            border-radius: 50%;

            .time {
              font: {
                family: "Gabarito-Bold";
                size: 1.0rem;
              }
              color: #fff;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .minutesLeft {
            display: inline-block;
            vertical-align: middle;
            font: {
              family: "Gabarito-Bold";
              size: 0.9rem;
            }
            color: #000;
            padding: 0 8px 0 8px;
            transform: translate(-1px, 6px);
          }
        }
        .or {
          display: inline-block;
          vertical-align: middle;
          font: {
            family: "Gabarito-Bold";
            size: 0.6rem;
          }
          padding: 0 16px 0 0;
        }
        .unlockPremium {
          display: inline-block;
          vertical-align: middle;
          position: relative;
          border-radius: 6px;
          overflow: hidden;

          .unlockButton {
            height: 30px;
            font: {
              family: "Gabarito-Bold";
              size: 0.8rem;
            }
            color: #fff;
            padding: 0 12px;
            background: linear-gradient(
              90deg,
              rgba(21, 11, 42, 1) 0%,
              rgba(39, 30, 58, 0.9) 54%,
              rgba(21, 11, 42, 0.6) 100%
            );
            border: none;

            .premium {
              background-image: repeating-linear-gradient(45deg, violet, $gravityGreen, $orange, $red, violet);
              background-size: 800% 800%;
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
              animation: rainbow 12s ease infinite;
            }
          }
          .background {
            height: 100%;
            width: 100%;
            background-image: url("https://d3f260fu0a2g7m.cloudfront.net/marketing/jpg/gravity-waves.jpg");
            background-size: cover;
            background-position-x: 50%;
            background-position-y: 50%;
            position: absolute;
            top: 0;
            z-index: -1;
          }
        }
      }

      .searchWrapper {
        .searchSpaces {
          height: 30px;
          width: 300px;
          font: {
            family: "Gabarito-Regular";
            size: 1.0rem;
          }
          padding: 0 15px;
          border: none;
          border-radius: 15px;
          box-sizing: border-box;
          transform: translateX(20px);

          &:focus {
            outline: none;
          }
        }
        .searchWrapper {
          display: inline-block;
          vertical-align: top;
          padding: 0 2px;
          transform: translateX(20px);

          .searchButton {
            display: inline-block;
            vertical-align: top;
            height: 30px;
            width: 30px;
            padding: 0 16px;
            background: none;
            border: none;
            text-align: center;
            position: relative;

            .searchIcon {
              height: 16px;
              width: 16px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
    .spacesMenu {
      width: 100%;
      box-sizing: border-box;

      .createSpace {
        height: 50px;
        width: 50px;
        font: {
          family: "Gabarito-Regular";
          size: 2.0rem;
        }
        color: rgba(255, 255, 255, 0.8);
        border: 2px solid rgba(255, 255, 255, 0.7);
        box-shadow:
          1px 4px 3px 1px rgb(0 0 0 / 15%),
          -3px -2px 3px 1px rgb(255 255 255 / 100%);
        border-radius: 50%;
        background: $gravityGreen;
        transform: translateY(6px);
        position: absolute;
        top: 10px;
        right: 7px;
      }
    }

    .deleteWrapper {
      position: absolute;
      right: 60px;
      top: 2px;
    }
    .code {
      display: inline-block;
      border: 2px solid #e7e7e7;
      padding: 2px;
      color: $purpleLight;
      border-radius: 6px;
      position: absolute;
      top: 8px;
      left: 4px;

      .codeText {
        display: inline-block;
        vertical-align: middle;
        font: {
          family: "Gabarito-Medium";
          size: 0.9rem;
        }
        padding: 0 6px 0 0;
        -webkit-user-select: none;
        user-select: none;
      }
      .codeCode {
        display: inline-block;
        vertical-align: middle;
        font: {
          family: "Courier New", monospace;
          weight: bolder;
          size: 1.2rem;
        }
        padding: 1px 4px 0;
        color: white;
        background: $gravityGreen;
        border-radius: 4px;
      }
      /*.copyCode {
        height: 18px;
        width: 18px;
        padding: 0px;
        background: none;
        border: none;
        transform: translate(2px,3px);
        user-select: none;

        .clipboard {
          height: 14px;
          width: 14px;
        }
      }*/
    }
    .teacherDashboard {
      position: absolute;
      top: 10px;
      right: 8px;
    }
  }
  .content {
    position: absolute;
    height: 100%;
    width: 100%;
    min-width: 1080px;
    padding: 84px 8px 10px;
    box-sizing: border-box;

    .welcomeContent {
      height: 100%;
      width: 100%;
      overflow: hidden;
      overflow-y: scroll;
      position: relative;
    }

    .welcomeGraphic {
      .graphic {
        height: 100px;
        width: 100px;
        background-size: cover;
        background-position-x: 50%;
        background-position-y: 50%;
        border-radius: 50%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
      padding: 60px 0 8px;
    }
    .welcomeMessage {
      font: {
        family: "Gabarito-Medium";
        size: 1.2rem;
      }
    }
    .welcomeStatus {
      font: {
        family: "Gabarito-Regular";
        size: 1.0rem;
      }
      color: #999;
      padding: 8px 0;
    }
  }
  .assetLoader {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 4;

    .assetBlur {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      -webkit-backdrop-filter: saturate(100%) blur(4px);
      backdrop-filter: saturate(100%) blur(4px);
    }

    .assetWrapper {
      height: 100%;
      width: 100%;

      .assetData {
        display: inline-block;
        min-height: 30px;
        min-width: 90px;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 12px;
        padding: 32px 16px 64px;
        position: relative;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);

        .Loading {
          position: absolute;
          right: 50%;
          transform: translate(50%, 4px);
        }

        .assetGif {
          height: 100px;
          width: 100px;

          .preview {
            display: inline-block;
            vertical-align: top;
            height: 100px;
            width: 100px;
            position: relative;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            .previewAuthor {
              height: 100px;
              width: 100px;
              border-radius: 50%;
              background-size: cover;
              background-position: center;
              position: absolute;
            }

            .previewVideo {
              height: 100px;
              width: 100px;
              border-radius: 6px;
              background-size: cover;
              background-position: center;
            }
          }
        }
        .assetTitle {
          width: 600px;
          text-align: center;
          font: {
            family: "Gabarito-Bold";
            size: 2.0rem;
          }
          color: white;
          padding: 8px 0;
        }
        .assetCreator {
          width: 600px;
          text-align: center;
          font: {
            family: "Gabarito-Medium";
            size: 1.0rem;
          }
          color: #9e9e9e;
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .Content {
    .banner {
      .typeWrapper {
        .timeLimit {
          min-width: 350px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .Content {
    .content {
      min-width: 100%;
      padding: 84px 8px 10px;
    }
  }
}

.mobile {
  .Content {
    .banner {
      .typeWrapper {
        .assetWrapper {
          .backWrapper {
            right: auto;
          }
        }
      }
      .announcement {
        display: none;
      }
    }
    .content {
      min-width: 300px;
    }
    .assetLoader {
      background: rgba(0, 0, 0, 0.6);
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 4;

      .assetWrapper {
        height: 100%;
        width: 100%;

        .assetData {
          width: 80%;

          .assetTitle {
            width: 100%;
          }
          .assetCreator {
            width: 100%;
          }
        }
      }
    }
  }
}
