@keyframes rainbow {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 25%; }
  100% { background-position: 0% 50%; }
}
@keyframes logoScroll {
    0% { transform: translateX(100vw); }
    100% { transform: translateX(calc(-250px * 7)); }
  }


// General
@keyframes fadeIn {
  0% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@keyframes fadeOut {
  0% { opacity: 1.0; }
  80% { opacity: 0.0; }
  100% { opacity: 0.0; }
}

@keyframes fadeInOut {
  0% { opacity: 0.25; }
  50% { opacity: 1.0; }
  100% { opacity: 0.25; }
}

// Spining Loader <Loading />
@keyframes spinForward {
  0% { transform: rotate(0deg); }
  70% { transform: rotate(300deg); }
  90% { transform: rotate(350deg); }
  100% { transform: rotate(360deg); }
}

@keyframes colorTransition {
  0% { 
    border: 4px solid rgba(220,130,255,1.0);
    border-bottom: 0;
  }
  10% {
    border: 4px solid rgba(255,130,130,1.0);
    border-bottom: 0;
  }
  30% {
    border: 4px solid rgba(255,220,130,1.0);
    border-bottom: 0;
  }
  50% {
    border: 4px solid rgba(130,255,220,1.0);
    border-bottom: 0;
  }
  70% {
    border: 4px solid rgba(130,220,255,1.0);
    border-bottom: 0;
  }
  90% { 
    border: 4px solid rgba(220,130,255,1.0);
    border-bottom: 0;
  }
}

// Slide in and out <ThreadView />
/* Menu Animation */
@keyframes menuSlideIn {
  0% { transform: translateX(100%) translateZ(0); }
  100% { transform: translateX(0%) translateZ(0); }
}

@keyframes menuSlideOut {
  0% { transform: translateX(0%) translateZ(0); }
  100% { transform: translateX(100%) translateZ(0); }
}

@keyframes menuFadeIn {
  0% { visibility: 0; }
  100% { visibility: 1; }
}

@keyframes menuFadeOut {
  0% { visibility: 1; }
  100% { visibility: 0; }
}

// Slider Switch
/* Grow from Flat */
@keyframes growFromFlat {
  0% { opacity: 0.25; transform: scale(0.85); }
  70% { opacity: 1.0; }
  100% { opacity: 1.0; transform: scale(1.0); }
}

@keyframes growFromFlatTone {
  0% { opacity: 0.25; transform: scale(0.85); }
  70% { opacity: 0.5; }
  100% { opacity: 0.5; transform: scale(1.0); }
}

/* Modal Switch */
@keyframes switchLeft {
  0% { transform: translateX(18px); }
  100% { transform: translateX(-2px); }
}

@keyframes switchRight {
  0% { transform: translateX(-2px); }
  100% { transform: translateX(18px); }
}

/* Marketing */
@keyframes float {
	0% { transform: translatey(0px); }
	50% { transform: translatey(-14px); }
	100% { transform: translatey(0px); }
}

@keyframes repeatinout {
  0% { 
    opacity: 1.0; 
    transform: rotateZ(360deg);
  }
  50% { 
    opacity: 0.0; 
    transform: rotateZ(360deg);
  }
  100% { 
    opacity: 1.0; 
    transform: rotateZ(360deg);
  }
}

@keyframes slidefadeout {
  0% { transform: translateZ(0) translate(0%) rotateZ(360deg); }
  50% { transform: translateZ(0) translate(0%, 10%) rotateZ(360deg); }
  100% { transform: translateZ(0) translate(0%) rotateZ(360deg); }
}

/* Video transcript */
@keyframes drawerOpen {
  0% {
    opacity: 0.0;
    height: 0;
  }
  100% { 
    opacity: 1.0;
    height: 120px;
  }
}

@keyframes drawerClose {
  0% { 
    opacity: 1.0;
    height: 120px;
  }
  80% {
    opacity: 1.0;
  }
  100% { 
    opacity: 0.0;
    height: 0;
  }
}

@keyframes videoTranscriptClose {
  0% {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  99% {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  100% { 
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

@keyframes videoTranscriptOpen {
  0% { transform: translate(-50%, -50%); }
  100% { transform: translate(-50%, -60%); }
}

@keyframes videoTranscriptClose {
  0% { transform: translate(-50%, -60%); }
  100% { transform: translate(-50%, -50%); }
}

/* Menu animations */
@keyframes menuExpand {
  0% { 
    width: 64px;
    left: 50%;
  }
  100% { 
    width: 286px;
  }
}

@keyframes menuCollapse {
  0% { 
    width: 286px;
    left: calc(50% + 0px);
  }
  100% { 
    width: 64px;
    transform: translateX(7px);
    left: calc(0%);
  }
}

@keyframes menuWrapperExpand {
  0% { width: 76px; }
  100% { width: 300px; }
}

@keyframes menuWrapperCollapse {
  0% { width: 300px; }
  100% { width: 76px; }
}

@keyframes contentWrapperExpand {
  0% { width: calc(100% - 76px); }
  100% { width: calc(100% - 300px); }
}

@keyframes contentWrapperCollapse {
  0% { width: calc(100% - 300px); }
  100% { width: calc(100% - 76px); }
}

@keyframes contentWrapperCollapseMobile {
  0% { width: calc(100% - 2px); }
  100% { width: calc(100% - 2px); }
}

@keyframes contentWrapperCollapseTutorials {
  0% { width: 100%; }
  100% { width: 100%; }
}

@keyframes expandDirection {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-180deg); }
}

@keyframes collapseDirection {
  0% { transform: rotate(-180deg); }
  100% { transform: rotate(0deg); }
}

@keyframes hopUp {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(4px);
  }
}
@keyframes hopDown {
  0% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0px);
  }
}