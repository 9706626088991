@import "../exports/variables.scss";

.Menu {
  height: calc(100vh - 114px);
  width: 286px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;

  &.creatorStudio {
    height: 100%;
    width: 50%;

    .hidden {
      display: none;
    }
    .invisible {
      visibility: hidden;
    }

    .menuHeader {
      .content {
        .selections {
          width: calc(100% - 4px);
        }
      }
      .editCommunity {
        display: none;
      }
      .collapseExpand {
        display: none;
      }
    }

    .menuContent {
      height: 360px;
      background: none;

      &.hidden {
        display: none;
      }
      .groupWrapper {
        .copyFolders {
          display: none;
        } 
        .editFolders {
          display: none;
        }
        .groupTitle {
          &.active {
            width: calc(100% - 30px);
          }
        }
      }
    }
  }

  &.expanded {
    animation: 500ms 0s normal forwards menuExpand;

    .menuHeader {
      .content,
      .collapseExpand {
        animation: 400ms 0s normal forwards menufadeIn;

        .button {
          cursor: pointer;
          pointer-events: all;
        }
      }
    }
    .menuContent {
      .groupWrapper {
        animation: 150ms 350s normal forwards menufadeIn;

        button {
          cursor: pointer;
          pointer-events: all;
        }
      }
      .content {
        animation: 150ms 350s normal forwards menufadeIn;

        .groupItem {
          .groupButton {
            cursor: pointer;
            pointer-events: all;
          }
        }
      }
    }
    .contextArea {
      .code {
        animation: 200ms 0s normal forwards menufadeIn;
      }
    }
  }

  &.collapsed {
    animation: 500ms 0s normal forwards menuCollapse;

    .menuHeader {
      .content,
      .editCommunity,
      .collapseExpand {
        display: none;

        .button {
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }
    .menuContent {
      .groupWrapper {
        animation: 150ms 0s normal forwards fadeOut;

        button {
          cursor: not-allowed;
          pointer-events: none;
        }
      }
      .content {
        animation: 150ms 0s normal forwards fadeOut;

        .groupItem {
          .groupButton {
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }
    }
    .contextArea {
      .code {
        animation: 200ms 0s normal forwards fadeOut;
      }
    }
  }

  .menuHeader {
    height: 70px;
    max-width: 100%;
    position: relative;
    padding: 14px;
    box-sizing: border-box;
    background: rgba(220, 220, 220, 1);

    .content {
      padding: 10px 8px;
      border-radius: 22px;
      background: rgba(244, 244, 244, 1);

      .selections {
        height: 22px;
        width: 190px;
        font: {
          family: "Gabarito-Medium";
          size: 1.0rem;
        }
        background: none;
        border: none;

        &.full {
          width: calc(100% - 8px);
        }
        &:focus {
          outline: none;
        }

        option {
          color: black;

          &:disabled {
            color: $disabledText;
          }
        }
      }
    }
    .emptyCommunities {
      color: black;
      font: {
        family: "Gabarito-Medium";
        size: 1.0rem;
      }
      padding: 0 12px;
    }
    .collapseExpand {
      position: absolute;
      top: 14px;
      right: 14px;
      padding: 5px;

      .button {
        height: 30px;
        width: 30px;
        font: {
          family: "Gabarito-Regular";
          size: 1.4rem;
        }
        color: black;
        background: none;
        border: none;
        box-sizing: border-box;

        &:disabled {
          opacity: 0.5;
        }

        &:hover {
          color: $gravityGreen;
        }
      }
    }
    .editCommunity {
      position: absolute;
      top: 14px;
      right: 38px;
      padding: 5px;

      .button {
        height: 30px;
        width: 28px;
        background: none;
        border: none;
        box-sizing: border-box;

        .editIcon {
          height: 12px;
          width: 12px;
          transform: translateY(2px);
        }
      }

      .Loading {
        transform: scale(0.5) translate(20px, -20px);
      }
    }
  }
  .menuContent {
    height: calc(100% - 130px);
    max-width: 100%;
    background: $menuGrey;
    padding: 16px;
    box-sizing: border-box;

    .groupWrapper {
      padding: 5px 0;

      .groupTitle {
        display: inline-block;
        width: calc(100% - 38px);
        font: {
          family: "Gabarito-Medium";
          size: 1.0rem;
        }

        &.active {
          width: calc(100% - 86px);
        }
        &.empty {
          width: calc(100% - 68px);
        }

        .title {
          .iconImage {
            .icon {
              display: inline-block;
              height: 16px;
              width: 16px;
              padding: 0 2px;
              transform: translate(-2px, 2px);
            }
          }
          padding: 0 4px;
        }
      }
      .editFolders,
      .copyFolders {
        display: inline-block;
        vertical-align: top;
        height: 30px;
        width: 28px;
        background: none;
        border: none;
        box-sizing: border-box;

        &.hidden {
          pointer-events: none;
          visibility: hidden;
        }

        .editIcon {
          height: 12px;
        }
      }
      .CreateFolder {
        display: inline-block;
        vertical-align: top;
        height: 30px;
        width: 30px;
        font: {
          family: "Gabarito-Regular";
          size: 1.4rem;
        }
        color: black;
        background: none;
        border: none;
        box-sizing: border-box;
        transform: translate(-3px, -2px);
        position: relative;
        z-index: 1;

        &.empty {
          transform: translate(35px, -2px);
        }

        &:hover {
          color: $gravityGreen;
        }

        &.hidden {
          pointer-events: none;
          visibility: hidden;
        }
      }
      .contextMore {
        display: inline-block;
        vertical-align: top;
        height: 30px;
        width: 30px;
        background: none;
        border: none;
        transform: translate(0, -5px);

        .contextWrapper {
          padding: 3px 0 0 0;

          .contextDot {
            height: 4px;
            width: 4px;
            border-radius: 50%;
            background: black;
            transform: translate(15px, 1px);
          }
        }

        &.hover {
          &:not(selected) .contextWrapper {
            .contextDot {
              background: $gravityGreen;
            }
          }
        }
        &.selected {
          transform: translate(-8px, -5px);

          .contextWrapper {
            .contextDot {
              background: $gravityGreen;
            }
          }
        }
        &.empty {
          transform: translate(0px, -5px);
        }
      }
    }

    .content {
      overflow-y: scroll;
      height: calc(100% - 24px);
      
      .groupItem {
        border-left: 4px solid transparent;
        padding: 4px 4px 4px 0;

        .groupButton {
          background: none;
          font: {
            family: "Gabarito-Medium";
            size: 1.0rem;
          }
          text-align: left;
          border: none;
          padding: 6px 6px 6px 20px;
          position: relative;
          cursor: pointer;

          &.disabled {
            cursor: default;
          }

          .Loading {
            transform: scale(0.4);
            position: absolute;
            top: -12px;
            left: -16px;
          }

          /*.private {
            display: inline-block;
            padding: 0 4px;
          }*/
          .selected {
            height: calc(100% - 10px);
            width: 4px;
            border-radius: 4px;
            background: $gravityGreen;
            position: absolute;
            top: 50%;
            left: 4px;
            transform: translateY(-50%);
          }

          .deleteFolder,
          .copyFolder {
            background: none;
            border: none;
            position: absolute;
            top: 50%;
            left: -3px;
            transform: translateY(calc(-50% + 1px));

            .deleteIcon,
            .copyIcon {
              height: 12px;

              &.copy {
                height: 11px;
              }
            }
            .copyIcon {
              transform: translateX(2px);
            }
          }

          .title {
            display: inline-block;

            &.hot {
              opacity: 0.25;
            }
          }

          &.selected {
            background: black;
            color: white;
            border-radius: 4px;
          }
        }
      }
      .noGroups {
        width: 160px;
        font: {
          family: "Gabarito-Regular";
          size: 1.0rem;
        }
        color: white;
        text-align: center;
        padding: 34px 0;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        .noFolders {
          height: 40px;
          width: 40px;
          position: absolute;
          top: 2px;
          right: -20px;

          &.emptyCommunity {
            transform: rotate(-38deg);
          }
        }

        .imageWrapper {
          .empty {
            height: 60px;
            width: 60px;
          }
        }
        .emptyText {
          color: black;
          padding: 8px;
        }
      }
    }
  }
  .contextArea {
    height: 50px;
    position: relative;
    background: rgba(220, 220, 220, 1);
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;

    .menuBottomButton {
      height: 34px;
        width: 34px;
        border: none;
        border-radius: 50%;
        box-sizing: border-box;
        position: absolute;
        top: 8px;
        z-index: 2;
        padding: 5px 2px;

      &.goHome {
        left: 15px;
        opacity: 0.0;

        &.expanded {
          animation: 400ms 200ms normal forwards fadeIn;
        }
        &.collapsed {
          animation: 300ms 0s normal forwards fadeOut;
        }

        .goHomeIcon {
          height: 20px;
          width: 20px;
          transform: translateY(2px);
        }
      }
      &.expandCollapse {
        right: 15px;

        .expandCollapseIcon {
          height: 24px;
          width: 24px;
  
          &.expanded {
            animation: 400ms 0s normal forwards collapseDirection;
          }
          &.collapsed {
            animation: 400ms 0s normal forwards expandDirection;
          }
        }
        .inviteMembersIcon {
          height: 18px;
          width: 18px;
          transform: translate(1px,2px);
        }
      }
    }

    .code {
      width: 154px;
      display: inline-block;
      padding: 2px;
      color: $purpleLight;
      border-radius: 6px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .codeText {
        display: inline-block;
        vertical-align: middle;
        font: {
          family: "Gabarito-Bold";
          size: 0.9rem;
        }
        padding: 0 6px 0 0;
        -webkit-user-select: none;
        user-select: none;
      }
      .codeCode {
        display: inline-block;
        vertical-align: middle;
        font: {
          family: "Courier New", monospace;
          weight: bolder;
          size: 1.2rem;
        }
        padding: 1px 4px 0;
        color: white;
        background: $gravityGreen;
        border-radius: 4px;
      }
    }
  }
}

.mobile {
  .Menu {
    &.creatorStudio {
      width: 100%;
    }
  }
}