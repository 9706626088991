@import '../exports/variables.scss';

.Login {
  .wrapper {
    height: calc(100vh - 92px);
    width: 100%;
    overflow-y: scroll;

    .loginBox {
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 6px;
      transform: translate(-50%,-50%);
      box-shadow: 5px 5px 10px 0 rgba(0,0,0,0.2), -6px -6px 9px 0 rgba(255,255,255,0.8);

      .loginBackground {
        display: inline-block;
        vertical-align: top;
        height: 460px;
        width: 700px;
        background-size: cover;
        background-position-x: 50%;
        background-position-y: 50%;
        border-radius: 6px;

        .loginSide {
          display: inline-block;
          vertical-align: top;
          height: 100%;
          box-sizing: border-box;
          position: relative;
          
          &.loginLeft {
            width: 370px;

            .loginMessage {
              font: {
                family: 'Gabarito-Bold';
                size: 1.3rem;
              }
              text-align: center;
              padding: 24px 0;
            }
            .credentials {
              display: inline-block;
              vertical-align: top;
              height: 100%;
              width: 100%;
              background: rgba(250,245,245,1.0);
              border-radius: 6px;
      
              .logo {
                text-align: center;
                padding: 24px 0 8px;
                
                .logoWrapper {
                  height: 60px;
                  width: 60px;
                  border-radius: 6px;
                  position: relative;
                  left: 50%;
                  transform: translateX(-50%);
      
                  .icon {
                    width: 44px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                  }
                }
              }
              .restoring {
                display: inline-block;
                text-align: center;
                position: relative;
                top: calc(50% - 100px);
                left: 50%;
                transform: translate(-50%,-50%);
    
                .image {
                  .restoringImage {
                    height: 54px;
                  }
                }
                .text {
                  font: {
                    family: 'Gabarito-Bold';
                    size: 0.8rem;
                  }
                  color: $purpleDark;
                  padding: 4px;
                }
              }
              .loginContainer {
                .login {
                  position: relative;
                  
                  .resetText {
                    font: {
                      family: 'Gabarito-Regular';
                      size: 1.0rem;
                    }
                    padding: 0 36px;
                    box-sizing: border-box;
                  }

                  .inputs {
                    display: inline-block;
                    position: relative;
                    left: 50%;
                    transform: translateX(calc(-50% - 8px));
                    padding: 8px 0;
    
                    &.search {
                      z-index: 2;
                    }
    
                    .input {
                      height: 40px;
                      width: 300px;
                      font: {
                        family: 'Gabarito-Regular';
                        size: 1.2rem;
                      }
                      background: white;
                      padding: 8px;
                      border: 2px solid black;
                      border-radius: 4px;
                      box-sizing: border-box;
                      transform: translateX(8px);
        
                      &:focus {
                        outline: none;
                      }
                    }
                  }
                }
              }
              .resetWrapper {
                display: inline-block;
                width: 100%;
                padding: 0 36px;
                box-sizing: border-box;

                .resetPassword {
                  font: {
                    family: 'Gabarito-Medium';
                    size: 0.9rem;
                  }
                  color: $purpleLight;
                  background: none;
                  float: right;
                  padding: 4px 0;
                  border: none;
                  
                  &.reset {
                    float: left;
                  }
                }
              }
              .submitWrapper {
                padding: 24px 0;
                position: absolute;
                bottom: 24px;
                left: 50%;
                transform: translateX(-50%);
                
                .submit {
                  height: 40px;
                  width: 200px;
                  font: {
                    family: 'Gabarito-Bold';
                    size: 1.0rem;
                  }
                  color: $purpleLight;
                  border: 2px solid $gravityGreen;
                  padding: 0 60px;
                  border-radius: 20px;
                  background: $gravityGreen;
                  position: relative;
                  left: 50%;
                  transform: translateX(-50%);
    
                  &:disabled {
                    opacity: 0.3;
                  }
                }
                .loadingIcon {
                  height: 24px;
                  width: 24px;
                  position: absolute;
                  top: 86px;
                  right: 166px;
                  animation: 0.75s 0s normal forwards infinite spinForward;
                }
              }
              .modeWrapper {
                width: 100%;
                position: absolute;
                bottom: 0;
    
                .mode {
                  display: inline-block;
                  width: 50%;
    
                  .modeButton {
                    width: 200px;
                    font: {
                      family: 'Gabarito-Bold';
                      size: 1.0rem;
                    }
                    text-align: center;
                    color: white;
                    border: 4px solid transparent;
                    background: none;
                    padding: 4px;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
    
                    &.active {
                      color: $gravityGreen;
                      border-bottom: 4px solid $gravityGreen;
                    }
                  }
                }
              }
            }
          }
          &.loginRight {
            width: 330px;
            background: #f0d6da;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;

            .loginBackground {
              width: 100%;
              position: absolute;
              top: 85px;
              left: 50%;
              transform: translateX(-50%);

              .loginImage {
                width: 100%;
              }
            }
            .registerWrapper {
              height: 140px;
              width: 100%;
              position: absolute;
              bottom: 0;

              .registerText {
                font: {
                  family: 'Gabarito-Bold';
                  size: 1.0rem;
                }
                color: white;
                text-shadow: 1px 2px 2px rgba(128,0,128,0.2);
                text-align: center;
                padding: 16px 0;
              }
              .registerButton {
                height: 40px;
                width: 200px;
                font: {
                  family: 'Gabarito-Bold';
                  size: 1.0rem;
                }
                color: $purpleLight;
                border: none;
                padding: 0 60px;
                border-radius: 20px;
                background: rgba(255,255,255,0.2);
                position: relative;
                left: 50%;
                transform: translateX(-50%);
    
              }
            }
          }
        }
      }
    }
  }
}

.mobile {
  .Login {
    .wrapper {
      .loginBox {
        width: calc(100% - 32px);
        position: relative;
        top: 24px;
        transform: translateX(-50%);
        box-shadow: -3px 5px 10px 0 rgba(0, 0, 0, 0.2), -6px 8px 9px 0 rgba(255, 255, 255, 0.8);

        .loginBackground {
          display: block;
          width: 100%;

          .loginSide {
            &.loginLeft {
              width: 100%;
            }
            &.loginRight {
              display: inline-block;
              height: 100px;
              width: 100%;
              background: none;

              .registerWrapper {
                display: inline-block;
                height: auto;

                .registerText {
                  font: {
                    size: 1.0rem;
                  }
                  color: #000;
                  text-shadow: none;
                }
                .registerButton {
                  display: inline-block;
                  height: 30px;
                  width: auto;
                  color: $gravityGreen;
                  border: none;
                  border-bottom: 2px solid $gravityGreen;
                  background: transparent;
                  border-radius: 0;
                  padding: 0 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}