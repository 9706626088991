@import "../exports/variables.scss";

.CTASection {
  background: $purpleDark;
  padding: 60px 0 30px;
  box-sizing: border-box;
  float: none;
  clip-path: polygon(0 20%, 100% 0, 100% 100%, 0 100%);
  transform: translateY(-16px);

  .ctaWrapper {
    text-align: center;

    .ctaHeader {
      padding: 100px 20px 20px;

      .ctaText {
        font: {
          family: "Gabarito-Bold";
          size: 2.2rem;
        }
        color: white;
      }
      .ctaSubtext {
        padding: 5px;

        .gravityLogo {
          height: 100px;
          padding: 30px 0;
        }
      }
    }
    .ctaEmail {
      padding: 0 0 48px;

      .headerButton {
        height: 48px;
        min-width: 140px;
        font: {
          family: "Gabarito-Bold";
          size: 1.2rem;
        }
        border: none;
        padding: 8px 16px;
        background: transparent;
        border-radius: 24px;

        &.startHere {
          color: $gravityGreen;
          border: 3px solid $gravityGreen;

          &:hover {
            color: $purpleDark;
            background: $gravityGreen;
          }
        }
      }
    }
    .emailMessage {
      .email {
        font: {
          family: "Gabarito-SemiBold";
          size: 1.0rem;
        }
        text-decoration: none;
        color: $gravityGreen;
      }
    }
    .ctaPrivacyTOS {
      font: {
        family: "Gabarito-Regular";
        size: 0.75rem;
      }
      padding: 24px 0 0;

      .privacy,
      .tos {
        color: $gravityGreen;
        text-decoration: underline;
      }
      color: $gravityGreen;
    }
  }
}

@media only screen and (max-width: 720px) {
  .CTASection {
    .ctaWrapper {
      .ctaHeader {
        .ctaText {
          font: {
            size: 1.8rem;
          }
        }
      }
    }
  }
}
