@import "../exports/variables.scss";

.VideoCarousel {
    height: 350px;
    width: 680px;

    .carouselOuter {
        height: 350px;
        width: 680px;
        background: transparent;
        padding: 8px;
        box-sizing: border-box;
        border-radius: 12px;
        position: relative;
        overflow: hidden;

        .carouselWrapper {
            height: calc(100% - 8px);
            background: linear-gradient(
                150deg,
                rgb(4, 106, 201, 0.1) 0%,
                rgba(255, 235, 202, 0.4) 35%,
                rgba(255, 175, 38, 0.08) 100%
            );
            border: 4px solid white;
            box-shadow: $morphShadow;
            position: relative;
            border-radius: 12px;
            overflow: hidden;

            .videoWrapper {
                width: 535px;
                height: 300px;
                background: black;
                overflow: hidden;
                border-radius: 8px;
                position: relative;
                top: 14px;
                left: 50%;
                transform: translateX(-50%);
            }

            .videoPlayer {
                width: 100%;
                height: 100%;
            }

            .navButton {
                background-color: $lwOrange;
                color: white;
                border: none;
                padding: 10px 15px;
                cursor: pointer;
                font-size: 20px;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                display: flex;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                position: absolute;
                top: 150px;

                &.left {
                    left: 8px;
                }
                &.right {
                    right: 8px;
                }

                .navIcon {
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    &.left {
                        transform: translate(calc(-50% - 2px), -50%);
                    }
                    &.right {
                        transform: translate(calc(-50% + 2px), -50%);
                    }
                }

                &:hover {
                    background-color: $lwBlue;
                }
            }

            .decoration {
                position: absolute;
                z-index: -1;
    
                &.fox {
                    top: 16px;
                    right: -16px;
                    opacity: 0.4;
                }
    
                .fox {
                    height: 220px;
                }
    
                &.elephant {
                    top: 150px;
                    left: -60px;
                    opacity: 0.4;
                }
    
                .elephant {
                    height: 220px;
                }
            }
        }
        .carouselCount {
            font: {
                family: "Gabarito-Bold";
                size: 1rem;
            }
            color: $lwOrange;
            position: absolute;
            bottom: 20px;
            right: 22px;

            .of {
                font: {
                    family: "Gabarito-Bold";
                    size: 0.8rem
                }
            }
        }
    }
}
