.ComboCounter {
    display: none;

    .timeWrapper {
        display: inline-block !important;
    }
    .recorderTime {
        display: inline-block !important;
        vertical-align: middle !important;
    }

    &.visible {
		display: block;
	}
}
