@import '../exports/variables.scss';

@keyframes hopUp {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(4px);
    }
}
@keyframes hopDown {
    0% {
        transform: translateY(4px);
    }
    100% {
        transform: translateY(0px);
    }
}

.Register {
    .wrapper {
        height: calc(100vh - 92px);
        width: 100%;
        overflow-y: scroll;
        position: relative;

        .buttonWrapper {
            padding: 24px;
            position: absolute;
            
            .navigationButton {
                height: 30px;
                font: {
                    family: "Gabarito-Medium";
                    size: 1.0rem;
                }
                color: $purpleLight;
                background: $gravityGreen;
                border: none;
                padding: 4px 32px;
                border-radius: 15px;
            }
        }

        .Loading {
            position: absolute;
            top: 120px;
            left: 50%;
            transform: translateX(-50%);
        }

        .typeChoices {
            position: relative;
            top: 50%;
            transform: translateY(calc(-50% - 32px));

            .register {
                text-align: center;

                .socialImage {
                    height: 80px;
                    width: 80px;
                    border-radius: 50%;
                }

                .main {
                    font: {
                        family: "Gabarito-Bold";
                        size: 2.3rem;
                    }
                }
                .sub {
                    font: {
                        family: "Gabarito-Medium";
                        size: 1.8rem;
                    }
                }
            }
            .stepRender {
                .typeWrapper {
                    display: inline-block;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);

                    .type {
                        display: inline-block;
                        padding: 60px 16px;

                        .choiceWrapper {
                            text-align: center;
                            background: rgba(250, 248, 250, 1);
                            box-shadow: 5px 5px 12px 0 rgba(0, 0, 0, 0.2), -6px -6px 9px 0 rgba(255, 255, 255, 0.6);
                            border: 1px solid whitefff33;
                            border-radius: 8px;
                            position: relative;
                            animation: 250ms 0s normal forwards hopUp;

                            &:hover {
                                animation: 250ms 0s normal forwards hopDown;
                            }

                            .choice {
                                height: 200px;
                                width: 300px;
                                border: none;
                                background: none;

                                .choiceImage {
                                    height: 120px;
                                    width: 120px;
                                    position: absolute;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    top: 16px;
                                }
                                .choiceText {
                                    width: 100%;
                                    font: {
                                        family: "Gabarito-Medium";
                                        size: 1.2rem;
                                    }
                                    position: absolute;
                                    bottom: 32px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
                .codeWrapper {
                    display: inline-block;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);

                    .characterWrapper {
                        display: inline-block;
                        padding: 40px 8px;

                        .joinCharacter {
                            height: 60px;
                            width: 60px;
                            background: white;
                            font: {
                                family: "Gabarito-Medium";
                                size: 3.0rem;
                            }
                            text: {
                                align: center;
                                transform: uppercase;
                            }
                            border: 2px solid $purpleLight;
                            border-radius: 4px;
                        }
                    }
                }
                .newWrapper {
                    display: inline-block;
                    padding: 34px 0;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);

                    .community {
                        width: 360px;
                        font: {
                            family: "Gabarito-Regular";
                            size: 1.2rem;
                        }
                        background: white;
                        padding: 8px;
                        border: 2px solid $purpleDark;
                        border-radius: 4px;
                    }
                    .checkAvailability {
                        display: block;
                        height: 48px;
                        width: 300px;
                        font: {
                            family: "Gabarito-Bold";
                            size: 1.4rem;
                        }
                        color: $purpleLight;
                        border: none;
                        padding: 0 40px;
                        border-radius: 24px;
                        background: $gravityGreen;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, 24px);

                        &:disabled {
                            cursor: default;
                            opacity: 0.3;
                        }
                    }
                }
                .submitCode {
                    height: 48px;
                    font: {
                        family: "Gabarito-Bold";
                        size: 1.4rem;
                    }
                    color: $purpleLight;
                    border: none;
                    padding: 0 40px;
                    border-radius: 24px;
                    background: $gravityGreen;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 130px);

                    &:disabled {
                        cursor: default;
                        opacity: 0.3;
                    }
                }
                .communityError {
                    width: 300px;
                    font: {
                        family: "Gabarito-Regular";
                        size: 1.1rem;
                    }
                    color: $red;
                    text-align: center;
                    position: absolute;
                    bottom: -90px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .socialRegister {
                    text-align: center;
                    padding: 16px 0 0;

                    .Google {
                        display: inline-block;
                    }
                }
                .or {
                    font: {
                        family: "Gabarito-Medium";
                        size: 0.9rem;
                    }
                    text-align: center;
                }
                .emailRegister {
                    text-align: center;
                    padding: 10px 0 0;

                    .useEmail {
                        height: 40px;
                        width: 200px;
                        font: {
                            family: "Gabarito-Medium";
                            size: 0.8rem;
                        }
                        color: #3c4043;
                        background: #fff;
                        border: 1px solid #dadce0;
                        border-radius: 20px;

                        &:hover {
                            background: rgb(255, 245, 255);
                        }
                    }
                }

                .registerForm {
                    display: inline-block;
                    padding: 0 32px 32px;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);

                    .inputs {
                        padding: 8px 0;

                        .title {
                            font: {
                                family: "Gabarito-Medium";
                                size: 1.2rem;
                            }
                            color: $purpleLight;
                            padding: 8px 0;
                        }
                        .input {
                            width: 280px;
                            font: {
                                family: "Gabarito-Regular";
                                size: 1.2rem;
                            }
                            background: white;
                            padding: 8px;
                            border: 2px solid $purpleDark;
                            border-radius: 4px;
                        }
                        .roles {
                            .roleWrapper {
                                display: inline-block;
                                padding: 0 12px 0 0;

                                .roleType {
                                    height: 144px;
                                    width: 144px;
                                    background: rgba(250, 248, 250, 1);
                                    box-shadow: 5px 5px 12px 0 rgba(0, 0, 0, 0.2), -6px -6px 9px 0 rgba(255, 255, 255, 0.6);
                                    border: 1px solid whitefff33;
                                    border-radius: 8px;
                                    position: relative;
                                    opacity: 0.3;
                                    animation: 250ms 0s normal forwards hopUp;

                                    &:hover {
                                        animation: 250ms 0s normal forwards hopDown;
                                    }

                                    &.selected {
                                        border: 2px solid $gravityGreen;
                                        opacity: 1.0;
                                    }
                                    .roleImage {
                                        height: 100px;
                                        width: 100px;
                                    }
                                    .roleText {
                                        font: {
                                            family: "Gabarito-Bold";
                                            size: 1.2rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .submitRegister {
                        height: 48px;
                        font: {
                            family: "Gabarito-Bold";
                            size: 1.4rem;
                        }
                        color: $purpleLight;
                        border: none;
                        padding: 0 40px;
                        border-radius: 24px;
                        background: $gravityGreen;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, 24px);

                        &:disabled {
                            cursor: default;
                            opacity: 0.3;
                        }
                    }
                    .passwordHint {
                        display: inline-block;
                        padding: 8px 8px 4px;
                        background: rgba(255, 255, 255, 0.98);
                        position: absolute;
                        left: -260px;
                        top: 194px;
                        z-index: 1;
                        border-radius: 6px;
                        box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1), -6px -6px 9px 0 rgba(255, 255, 255, 0.1);

                        .triangle {
                            width: 0;
                            height: 0;
                            border-top: 8px solid transparent;
                            border-left: 10px solid rgba(255, 255, 255, 0.98);
                            border-bottom: 8px solid transparent;
                            position: absolute;
                            right: -10px;
                            bottom: 14px;
                        }

                        .validation {
                            .status {
                                display: inline-block;
                                vertical-align: middle;
                                padding: 2px;

                                .validateImage {
                                    height: 18px;
                                    width: 18px;
                                    opacity: 0.2;
                                }
                            }
                            .requirement {
                                display: inline-block;
                                font: {
                                    family: "Gabarito-Regular";
                                    size: 1.0rem;
                                }
                                opacity: 0.4;
                                padding: 0 2px;
                                transform: translateY(-2px);
                            }
                            &.validated {
                                .status {
                                    .validateImage {
                                        opacity: 1;
                                    }
                                }
                                .requirement {
                                    font: {
                                        family: "Gabarito-Regular";
                                    }
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile {
    .Register {
        .wrapper {
            .typeChoices {
                .register  {
                    padding-top: 0;
                }
                .stepRender {
                    .typeWrapper {
                        .type {
                            display: block;
                            padding: 24px 16px 0;
                        }
                    }
                    .codeWrapper {
                        .characterWrapper {
                            padding: 40px 2px;

                            .joinCharacter {
                                height: 50px;
                                width: 50px;
                            }
                        }
                    }
                }
            }
        }
    }
}