@import '../exports/variables.scss';

.ActionBar {
  &.fullscreen {
    z-index: 0;
  }

  .buttonTab {
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%);
    
    .buttonWrapper {
      display: inline-block;
      transform: rotate(0deg);

      &.active {
        transform: rotate(45deg);
      }

      .button {
        height: 32px;
        width: 200px;
        vertical-align: middle;
        background: purple;
        padding: 0 6px 0 12px;
        border: 1px solid white;
        border-radius: 4px;

        .addWrapper {
          display: inline-block;
          vertical-align: middle;
          height: 22px;
          width: 22px;
          position: relative;
          border-radius: 4px;

          .plus {
            height: 22px;
            width: 2px;
            background: white;
            position: absolute;
            left: 50%;
            
            &.vertical {
              transform: translateX(-50%) rotate(0deg);
            }
            &.horizontal {
              transform: translateX(-50%) rotate(90deg);
            }
            &.active {
              &.vertical {
                animation: 0.5s 0s normal forwards spinFortyFive;
              }
              &.horizontal {
                animation: 0.5s 0s normal forwards spinOneThirtyFive;
              }
            }
            &.inactive {
              &.vertical {
                animation: 0.5s 0s normal forwards spinFortyFiveBack;
              }
              &.horizontal {
                animation: 0.5s 0s normal forwards spinOneThirtyFiveBack;
              }
            }
          }
        }
        .actionText {
          display: inline-block;
          vertical-align: middle;
          color: white;
          padding: 0 8px;
        }
        &.active {
          background: rgba(0,0,0,0.0);
          border: 1px solid rgba(0,0,0,0.0);

          .addWrapper {
            .plus {
              background: purple;
            }
          }
          .actionText {
            color: purple;
          }
        }
      }
    }
  }
}