@import '../exports/variables.scss';

.Footer {
  height: 32px;
  width: calc(100% - 12px);
  background: #0a0416;
  border-radius: 16px;
  transform: translate(6px, -8px);

  &.viewer {
    position: absolute;
    bottom: 0;
  }

  .content {
    position: relative;

    .copyright {
      display: inline-block;
      font: {
        family: 'Gabarito-Regular';
        size: 0.8rem;
      }
      color: white;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      padding: 8px 0;
    }
    .version {
      font: {
        family: 'Gabarito-Regular';
        size: 0.8rem;
      }
      color: $offWhite;
      position: absolute;
      top: 8px;
      right: 16px;
    }
  }
}