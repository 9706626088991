@import '../exports/variables.scss';

.Switch {
  display: block;
  position: absolute;

  &.centeredSwitch {
    padding: 6px 0;
    position: unset;
  }

  .switchButton {
    display: inline-block;
    height: 30px;
    width: 60px;
    background: rgba(0,0,0,0.0);
    opacity: 0.0;
    box-shadow: inset 4px 4px 4px rgba(100,100,100,0.3), inset -4px -4px 5px rgba(255,255,255,0.5);
    border: 1px solid rgba(255,255,255,0.2);
    animation: 0.5s 0.2s normal forwards growFromFlat;
    border-radius: 15px;

    &.active {
      background: $gravityGreen;
    }
    
    &:disabled {
      background: rgba(0,215,160,0.4);

      .switchWrapper {
        opacity: 0.6;
      }
    }

    .switchWrapper {
      height: 20px;
      width: 28px;
      background: rgba(250,250,250,0.5);
      border-radius: 15px;
      box-shadow: 4px 4px 6px rgba(0,0,0,0.15), -1px -1px 6px rgba(255,255,255,0.25);
      border: 1px solid rgba(220,220,220,0.4);

      &.disabled {
        transform: translateX(18px);
        animation: 400ms 0s normal forwards switchLeft;
      }
      &.enabled {
        background: rgba(250,250,250,1.0);
        transform: translateX(2px);
        animation: 400ms 0s normal forwards switchRight;
      } 
    }

    &[disabled] {
      cursor: default;
    }
  }
}