@import "../exports/variables.scss";

.Modal {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  overflow: scroll;

  .modalWrapper {
    width: 600px;
    background: rgba(220, 220, 220, 1);
    //padding: 16px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 22px;

    .generateLoading {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: scale(2) translate(calc(-50% + 20px), calc(-50% + 20px));
    }

    &.actionSuccess {
      min-height: 300px;
    }

    .successBackground {
      height: 100%;
      width: 100%;
      background: url("https://d3f260fu0a2g7m.cloudfront.net/marketing/gif/confetti.gif");
      opacity: 0.4;
      position: absolute;
      z-index: -1;

      &.actionSuccess {
        height: 100% !important;
        top: 0;
      }
    }

    &.youtube {
      width: auto;
    }

    .modalTitle {
      height: 60px;
      width: 100%;
      padding: 14px;
      position: relative;
      box-sizing: border-box;

      .closeModal {
        height: 30px;
        font: {
          family: "Gabarito-Medium";
          size: 0.9rem;
        }
        color: #fff;
        padding: 0 24px;
        border: none;
        background: $purpleDark;
        border-radius: 15px;
        float: right;
      }
      .keywordAI {
        &.display {
          display: inline-block;
          vertical-align: middle;
          height: 28px;
          width: 28px;
          font: {
            family: "Gabarito-Black";
            size: 1.4rem;
          }
          color: #fff;
          background: $gravityGreen;
          padding: 2px 3px;
          box-sizing: border-box;
          border-radius: 2px;
        }
      }
      .magicAIOrb {
        display: inline-block;
        vertical-align: middle;
        height: 24px;
        width: 24px;
        background-image: repeating-linear-gradient(45deg, violet, $gravityGreen, $orange, $red, violet);
        background-size: 800% 800%;
        animation: rainbow 12s ease infinite;
        border-radius: 50%;
      }
      .title {
        display: inline-block;
        vertical-align: middle;
        font: {
          family: "Gabarito-Bold";
          size: 1.6rem;
        }
        color: $purpleLight;
        padding: 0 8px;

        &.magic {
          display: inline-block;
          vertical-align: middle;
          font: {
            family: "Gabarito-Bold";
          }
          padding-left: 4px;
        }
      }
    }

    .required {
      font: {
        family: "Gabarito-Medium";
        size: 0.9rem;
      }
      color: red;
      position: absolute;
      transform: translate(0, -4px);
    }

    .avatarResponse {
      display: inline-block;
      vertical-align: middle;
      padding-right: 16px;

      .avatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-size: cover;
        background-position-x: 50%;
        background-position-y: 50%;
        border-radius: 50%;
      }
    }

    .successWrapper {
      height: calc(100% - 60px);

      .successBackground {
        height: calc(100% - 60px);
        background: url("https://d3f260fu0a2g7m.cloudfront.net/marketing/gif/confetti.gif");
        opacity: 0.2;
      }

      .successContent {
        display: inline-block;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .confetti {
          padding: 14px;

          .confettiIcon {
            height: 60px;
            width: 60px;
          }
        }

        .headline {
          font: {
            family: "Gabarito-Bold";
            size: 1.5rem;
          }
        }
        .text {
          font: {
            family: "Gabarito-Medium";
            size: 1rem;
          }
          padding: 8px 0;
        }

        .notification {
          padding: 24px 0 0px;
          font: {
            family: "Gabarito-Regular";
            size: 1rem;
          }

          .notificationCheckbox {
            display: inline-block;
            vertical-align: middle;
            height: 20px;
            width: 20px;
          }
          .notificationQuestion {
            display: inline-block;
            vertical-align: middle;
            padding-left: 4px;
          }
        }

        .buttonWrapper {
          padding: 32px 0;

          .successClose {
            height: 30px;
            font: {
              family: "Gabarito-Bold";
              size: 1rem;
            }
            color: $purpleLight;
            border: 2px solid $gravityGreen;
            padding: 0 40px;
            border-radius: 15px;
            background: $gravityGreen;

            &:disabled {
              opacity: 0.4;
              cursor: default;
            }
          }
          .Loading {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    .creatorSelection {
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      .selectionFlow {
        padding: 0 24px;
        box-sizing: border-box;

        .flowTitle {
          font: {
            family: "Gabarito-Medium";
            size: 1.3rem;
          }
          color: $purpleLight;
          text-align: center;
          padding: 20px 0 16px;

          &.bottom {
            padding: 80px 0 0;
          }

          .colorFlow {
            font: {
              family: "Gabarito-Bold";
              size: 1.4rem;
            }
            color: $gravityGreen;
          }
          .selectionOk {
            display: inline-block;
            vertical-align: middle;
            height: 24px;
            width: 24px;
            padding: 0 8px;
            transform: translateY(-2px);
            opacity: 0.2;

            &.valid {
              opacity: 1;
            }
          }
        }
      }
    }

    .modalContent {
      padding: 10px;

      .questionFollow {
        .followUpGraphic {
          text-align: center;
          padding: 24px 0;

          .followUpIcon {
            .followUpAvatar {
              height: 120px;
              width: 120px;
            }
            .followUpText {
              .keywordAI {
                display: inline-block;
                height: 25px;
                width: 25px;
                font: {
                  family: "Gabarito-Black";
                  size: 1.2rem;
                }
                color: #fff;
                background: $gravityGreen;
                padding: 2px 3px;
                box-sizing: border-box;
                border-radius: 2px;
              }
              .keywordWord {
                display: inline-block;
                font: {
                  family: "Gabarito-Bold";
                  size: 1.3rem;
                }
                color: $purpleLight;
                padding: 0 2px 0 6px;
                transform: translateY(2px);
              }
            }
          }
        }
        .followUpMessage {
          width: 100%;
          font: {
            family: "Gabarito-Medium";
            size: 1rem;
          }
          text-align: center;
          padding: 24px 0;
        }
        .followUpSection {
          width: 100%;

          .triangleUp {
            width: 0;
            height: 0;
            border-left: 16px solid transparent;
            border-right: 16px solid transparent;
            border-bottom: 24px solid $lightPurple;
            transform: translateX(-50%);
            position: relative;
            left: 50%;
            opacity: 0;
            animation: 500ms 500ms normal forwards fadeIn;
          }

          .followUpBubble {
            width: calc(100% - 120px);
            font: {
              family: "Gabarito-Medium";
              size: 1.4rem;
            }
            background: $lightPurple;
            color: white;
            padding: 16px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 16px;
            opacity: 0;
            animation: 500ms 500ms normal forwards fadeIn;
          }
        }
      }

      .subscriptionButtonsWrapper {
        margin-top: -12px;
        padding: 0 0 8px;

        .subscriptionEduCheck {
          padding: 0 0 16px;

          .subscriptionLabel {
            font: {
              family: "Gabarito-Bold";
              size: 1rem;
            }
            color: $purpleDark;
          }
          .subscriptionCheckbox {
            display: inline-block;
            vertical-align: middle;
            height: 14px;
            width: 14px;
          }
        }
      }

      .promptGenerate {
        background: linear-gradient(
          90deg,
          rgba(21, 11, 42, 1) 0%,
          rgba(39, 30, 58, 0.9) 54%,
          rgba(21, 11, 42, 0.8) 100%
        );
        border: none;
        padding: 6px 12px 6px 16px;
        position: absolute;
        top: 14px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 8px;

        &:hover {
          .generateWrapper {
            .generateDot {
              background: rgba(0, 216, 159, 0.4);
              box-shadow: 0 0 8px 2px rgba(0, 216, 159, 0.3);
            }
          }
        }

        .generateTitle {
          display: inline-block;
          vertical-align: middle;
          font: {
            family: "Gabarito-Bold";
            size: 0.9rem;
          }
          color: $gravityGreen;
        }
        .generateWrapper {
          display: inline-block;
          vertical-align: middle;
          padding: 0 0 0 8px;

          .generateDot {
            height: 16px;
            width: 16px;
            background: rgba(0, 216, 159, 0.2);
            border-radius: 50%;

            &.generated {
              background: $gravityGreen;
            }

            &.active {
              background-image: repeating-linear-gradient(45deg, violet, $gravityGreen, $orange, $red, violet);
              background-size: 800% 800%;
              animation: rainbow 4s ease infinite;
            }
          }
          .Loading {
            opacity: 0.7;
            position: absolute;
            top: -13px;
            right: -7px;
            transform: scale(0.5);
          }
        }
      }
      .generator {
        height: 60px;
        width: calc(100% - 32px);
        background: linear-gradient(
          90deg,
          rgba(21, 11, 42, 1) 0%,
          rgba(39, 30, 58, 0.9) 54%,
          rgba(21, 11, 42, 0.8) 100%
        );
        border-radius: 30px;
        position: relative;
        left: 50%;
        transform: translate(calc(-50% + 2px), -16px);

        .inputWrapper {
          display: inline-block;
          vertical-align: middle;
          padding: 0 4px;
          position: relative;
          top: 50%;
          transform: translate(6px, -50%);

          .aiField {
            height: 36px;
            width: 250px;
            font: {
              family: "SourceCodePro-M";
              size: 0.8rem;
            }
            border: 2px solid transparent;
            padding: 0 8px;
            border-radius: 18px;

            &:focus {
              outline: none;
              border-color: $gravityGreen;
            }

            &.aspects {
              width: 350px;
            }
          }
        }
        .buttonWrapper {
          display: inline-block;
          vertical-align: middle;
          position: relative;
          top: 50%;
          transform: translate(10px, -50%);

          .generateButton {
            height: 40px;
            width: 120px;
            font: {
              family: "Gabarito-Bold";
              size: 1rem;
            }
            color: #fff;
            border: none;
            background-image: repeating-linear-gradient(45deg, violet, $gravityGreen, $orange, $red, violet);
            background-size: 800% 800%;
            animation: rainbow 12s ease infinite;
            border-radius: 20px;
            opacity: 1;

            &:disabled {
              opacity: 0.2;
              cursor: default;
            }
          }
        }
      }
      .aiScript {
        height: 130px;
        width: calc(100% - 64px);
        padding: 6px 8px 8px;
        position: absolute;
        background: rgba(60, 60, 60, 0.5);
        z-index: 2;
        border-radius: 6px;
        box-sizing: border-box;
        transform: translate(26px, 76px);
        overflow: hidden;

        &.hidden {
          opacity: 0.4;
          height: 32px;
        }

        .toggleScript {
          height: 20px;
          width: 90px;
          font: {
            family: "Gabarito-Medium";
            size: 0.9rem;
          }
          padding: 0 24px;
          border: none;
          position: absolute;
          border-radius: 10px;
          right: 4px;
        }
        .script {
          font: {
            family: "Gabarito-Medium";
            size: 0.9rem;
          }
          color: #fff;
          text-align: center;
          padding: 0 0 8px;
        }
        .aiText {
          height: 90px;
          width: 100%;
          font: {
            family: "SourceCodePro-M";
            size: 0.9rem;
          }
          color: #fff;
          background: rgba(20, 20, 20, 0.1);
          text-align: left;
          box-sizing: border-box;
          border: none;
          resize: none;
          padding: 4px;
          box-sizing: border-box;

          &::placeholder {
            font: {
              family: "SourceCodePro-MI";
            }
            color: rgb(240, 240, 240);
          }
          &:focus {
            outline-color: rgba(0, 0, 0, 0);
          }
        }
      }

      .keywordTitle {
        height: 20px;
        padding: 8px 2px;

        .keywordAI {
          display: inline-block;
          height: 17px;
          width: 17px;
          font: {
            family: "Gabarito-Black";
            size: 0.7rem;
          }
          color: #fff;
          background: $gravityGreen;
          padding: 2px 3px;
          box-sizing: border-box;
          border-radius: 2px;
        }
        .keywordWord {
          display: inline-block;
          font: {
            family: "Gabarito-Bold";
            size: 0.8rem;
          }
          color: $purpleLight;
          padding: 0 4px;
          transform: translateY(1px);

          .beta {
            font: {
              family: "Gabarito-Medium";
              size: 0.6rem;
            }
            color: $purpleLight;
            padding: 0 2px;
          }
        }
      }

      .description {
        font: {
          family: "Gabarito-Regular";
          size: 1rem;
        }
        color: $purpleDark;
        padding: 0 12px 16px;

        &.keyword {
          font: {
            family: "SourceCodePro-MI";
            size: 0.9rem;
          }
          padding: 4px 24px 32px;
        }

        .bolded {
          font: {
            family: "Gabarito-Bold";
            size: 1rem;
          }
        }
      }

      .question {
        .questionIcon {
          padding: 0 18px;
          box-sizing: border-box;

          .questionImage {
            display: inline-block;
            vertical-align: middle;
            height: 50px;
            width: 50px;
          }
          .questionWrapper {
            display: inline-block;
            vertical-align: middle;
            width: calc(100% - 50px);

            .questionText {
              font: {
                family: "Gabarito-Bold";
                size: 1.1rem;
              }
              color: $purpleDark;
              padding: 0 12px;
              box-sizing: border-box;
            }
          }
        }
      }

      .entryWrapper {
        padding: 0 12px 14px;

        .narchivesVideo {
          background: #000;
        }

        .addTypeToggle {
          text-align: center;

          .addToggle {
            display: inline-block;
            padding: 0 4px;

            .toggleType {
              height: 40px;
              font: {
                family: "Gabarito-Bold";
                size: 1rem;
              }
              color: $purpleDark;
              background: rgba(0, 0, 0, 0.1);
              padding: 2px 24px;
              border: 2px solid transparent;
              border-radius: 20px;

              &.selected {
                color: $purpleDark;
                background: $gravityGreen;
              }

              &.premium {
                background: #fff;
                color: $purpleLight;
              }

              .premium {
                background-image: repeating-linear-gradient(45deg, violet, $gravityGreen, $orange, $red, violet);
                background-size: 800% 800%;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                animation: rainbow 12s ease infinite;
              }
            }
          }
          .orToggle {
            display: inline-block;
            font: {
              family: "Gabarito-Bold";
              size: 0.8rem;
            }
            color: $purpleDark;
            padding: 0 8px;
          }
        }

        .entryTitle {
          font: {
            family: "Gabarito-Medium";
            size: 1rem;
          }
          color: $purpleDark;
          padding: 8px 0;
        }
        .inputWrapper {
          .keywordMap {
            padding-bottom: 8px;

            &:last-child {
              padding-bottom: 0;
            }
          }

          .input {
            height: 50px;
            width: 375px;
            font: {
              family: "Gabarito-Regular";
              size: 1rem;
            }
            padding: 0 15px;
            border: none;
            border-radius: 25px;
            box-sizing: border-box;
            transform: translateX(10px);

            &.centered {
              transform: translate(-50%, 12px);
              position: relative;
              left: 50%;
            }

            &.joinCode {
              text-transform: uppercase;
              height: 50px;
              border: 2px solid black;
              font-size: 2.5rem;
              font-weight: bold;
              letter-spacing: 1.7rem;

              &::placeholder {
                font-size: 1rem;
                letter-spacing: 0rem;
                transform: translateY(-8px);
              }
            }

            &:focus {
              outline: none;
            }

            &.description {
              width: 390px;
              border-radius: 25px;
            }
            &.recorder {
              height: 50px;
              border-radius: 25px;
            }
            &.response {
              width: 790px;
            }
          }
        }
      }

      .communityWrapper {
        max-height: 400px;
        padding: 12px;
        overflow-y: scroll;
        box-sizing: border-box;

        .eligibleEdit {
          box-sizing: border-box;
        }
      }

      .typeSelection {
        padding: 24px 16px 0;
        box-sizing: border-box;

        .instructions {
          font: {
            family: "Gabarito-Medium";
            size: 1rem;
          }
          color: $purpleDark;
          padding: 0 0 12px;
        }
        .buttonWrapper {
          display: inline-block;
          vertical-align: top;
          width: 50%;
          text-align: center;

          .typeSelect {
            height: 30px;
            width: 280px;
            font: {
              family: "Gabarito-Bold";
              size: 1rem;
            }
            color: $purpleLight;
            border: 2px solid $purpleLight;
            padding: 0 40px;
            border-radius: 15px;
            background: transparent;

            &.selected {
              background: $purpleLight;
              color: $gravityGreen;
            }
          }
          .typeDescription {
            width: 300px;
            font: {
              family: "Gabarito-Regular";
              size: 1rem;
            }
            padding: 12px 0;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      .uploadSelectionWrapper {
        display: inline-block;
        width: 50%;
        padding: 6px 0 0;

        .uploadSelection {
          padding: 8px 16px 0;
          box-sizing: border-box;

          .instructions {
            font: {
              family: "Gabarito-Medium";
              size: 1rem;
            }
            color: $purpleDark;
            padding-left: 10px;

            .attachmentText {
              display: inline-block;
              min-width: 200px;

              .document {
                display: inline-block;
                vertical-align: middle;
                height: 12px;
                width: 12px;
                padding-right: 4px;
                transform: translateY(-2px);
              }

              .youtube {
                display: inline-block;
                vertical-align: top;
                height: 14px;
                width: 14px;
                padding-right: 2px;
                transform: translateY(3px);
              }
              .narchives,
              .figma,
              .gdrive,
              .canva {
                display: inline-block;
                vertical-align: top;
                height: 16px;
                width: 16px;
                transform: translateY(3px);
                border-radius: 4px;
              }
            }
            .Switch {
              display: inline-block;
              transform: scale(0.8) translateY(-6px);
            }
            .inputWrapper {
              display: block;
              padding: 16px 0 0;

              .input {
                height: 50px;
                width: 350px;
                font: {
                  family: "Gabarito-Regular";
                  size: 0.9rem;
                }
                padding: 0 15px;
                border: none;
                border-radius: 25px;
                box-sizing: border-box;

                &::placeholder {
                  opacity: 0.5;
                }

                &:focus {
                  outline: none;
                }

                &.description {
                  width: 380px;
                }
              }

              iframe {
                height: 200px;
                width: 345px;
                border: 2px solid #000;
                border-radius: 12px;
              }
            }
            .youtubeOK {
              display: inline-block;
              vertical-align: middle;
              height: 20px;
              width: 20px;
              transform: translateX(8px);
              opacity: 0.2;

              &.valid {
                opacity: 1;
              }
            }
            .previewWrapper {
              padding: 16px;
              text-align: center;
              transform: translateX(-8px);

              .previewYoutube {
                display: inline-block;
                height: 30px;
                width: 260px;
                font: {
                  family: "Gabarito-Medium";
                  size: 1rem;
                }
                color: white;
                background: #271e3a;
                border: none;
                padding: 4px 0;
                border-radius: 15px;
                position: relative;
                opacity: 1;

                &:disabled {
                  opacity: 0.3;
                  cursor: default;
                }
              }
            }
            .narchivesPlayer {
              height: 200px;
              width: 345px;
              border: 2px solid #000;
              border-radius: 12px;
              background: #000;
            }
          }
        }
      }

      .datePickerTitle {
        display: inline-block;
        vertical-align: top;
        font: {
          family: "Gabarito-Medium";
          size: 1rem;
        }
        color: $purpleDark;
        padding: 0 10px 0 0;
      }
      .datePicker {
        &.react-datepicker__day--disabled {
          color: #eee;
        }
        &.react-datepicker__day--selected {
          font: {
            family: "Gabarito-Medium";
            size: 0.9rem;
          }
          background: $gravityGreen;
          color: white;
        }
      }
      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          input {
            height: 20px;
            width: 98px;
            font: {
              family: "Gabarito-Medium";
              size: 0.9rem;
            }
            text-align: center;
            border: none;
            border-radius: 11px;
            outline: 2px solid $gravityGreen;

            &:focus {
              outline: 2px solid $gravityGreen;
            }
          }
        }

        .datePickerTitle {
          display: inline-block;
          vertical-align: top;
          font: {
            family: "Gabarito-Medium";
            size: 1rem;
          }
          color: $purpleDark;
          padding: 0 12px 0 0;
        }

        .react-datepicker-popper {
          left: 1px !important;

          .react-datepicker__navigation-icon {
            &::before {
              border-color: white;
            }
          }
          .react-datepicker__triangle {
            stroke: transparent;
          }
        }

        .react-datepicker__month-container {
          .react-datepicker__header {
            background: $gravityGreen;
            border-bottom: none;

            .react-datepicker__current-month {
              font: {
                family: "Gabarito-Bold";
                size: 1rem;
              }
              color: white;
              padding-bottom: 8px;
            }
            .react-datepicker__day-names {
              font: {
                family: "Gabarito-Medium";
                size: 0.8rem;
              }
              background: #f5f5f5;
            }
          }
        }
      }
      .react-datepicker-popper {
        left: 1px !important;

        .react-datepicker__navigation-icon {
          &::before {
            border-color: white;
          }
        }
        .react-datepicker__triangle {
          stroke: transparent;
        }
      }
      .react-datepicker__month-container {
        .react-datepicker__header {
          background: $gravityGreen;
          border-bottom: none;

          .react-datepicker__current-month {
            font: {
              family: "Gabarito-Bold";
              size: 1rem;
            }
            color: white;
            padding-bottom: 8px;
          }
          .react-datepicker__day-names {
            font: {
              family: "Gabarito-Medium";
              size: 0.8rem;
            }
            background: #f5f5f5;
          }
        }
      }

      .datePickerWrapper {
        position: absolute;
        top: 745px;
        right: 20px;

        &.generationOpen {
          top: 805px;
        }
      }

      .settingsWrapper {
        padding: 0 0 16px;

        .settingsSwitch {
          padding: 12px 16px 0;
          box-sizing: border-box;

          .settingType {
            display: inline-block;
            vertical-align: middle;
            width: calc(100% - 200px);
            font: {
              family: "Gabarito-Medium";
              size: 1.1rem;
            }

            .settingsHeader {
              font: {
                family: "Gabarito-Medium";
                size: 0.9rem;
              }
              color: $lightPurple;
              padding: 4px 0 8px;
            }
          }
          .settingSwitch {
            display: inline-block;
            vertical-align: middle;
            width: 170px;
            position: relative;

            .Loading {
              position: absolute;
              right: 0;
              transform: scale(0.6) translate(80px, -44px);
            }

            .Switch {
              position: absolute;
              top: -14px;
              right: 0;
            }

            .react-datepicker-wrapper {
              position: absolute;
              top: -12px;
              right: 0;

              .react-datepicker__input-container {
                input {
                  height: 20px;
                  width: 98px;
                  font: {
                    family: "Gabarito-Medium";
                    size: 0.9rem;
                  }
                  text-align: center;
                  border: none;
                  border-radius: 11px;
                  outline: 2px solid $gravityGreen;

                  &:focus {
                    outline: 2px solid $gravityGreen;
                  }
                }
              }

              .datePickerTitle {
                display: inline-block;
                vertical-align: top;
                font: {
                  family: "Gabarito-Medium";
                  size: 1rem;
                }
                color: $purpleDark;
                padding: 0 12px 0 0;
              }

              .datePicker {
                display: inline-block;
                font: {
                  family: "Gabarito-Regular";
                  size: 0.9rem;
                }
                color: $gravityGreen;

                &.react-datepicker__day--disabled {
                  color: #eee;
                }
                &.react-datepicker__day--selected {
                  font: {
                    family: "Gabarito-Medium";
                    size: 0.9rem;
                  }
                  background: $gravityGreen;
                  color: white;
                }
              }
              .react-datepicker-popper {
                left: 1px !important;

                .react-datepicker__navigation-icon {
                  &::before {
                    border-color: white;
                  }
                }
                .react-datepicker__triangle {
                  stroke: transparent;
                }
              }

              .react-datepicker__month-container {
                .react-datepicker__header {
                  background: $gravityGreen;
                  border-bottom: none;

                  .react-datepicker__current-month {
                    font: {
                      family: "Gabarito-Bold";
                      size: 1rem;
                    }
                    color: white;
                    padding-bottom: 8px;
                  }
                  .react-datepicker__day-names {
                    font: {
                      family: "Gabarito-Medium";
                      size: 0.8rem;
                    }
                    background: #f5f5f5;
                  }
                }
              }
            }
          }
        }
        .settingsModule {
          box-sizing: border-box;
          padding: 0 32px 0 4px;

          .settingsWrapper {
            display: inline-block;
            vertical-align: top;
            //width: 50%;
            width: 100%;
            box-sizing: border-box;
            padding: 12px;
            border-radius: 8px;

            .settingsSplit {
              padding: 8px 8px 5px;
              box-sizing: border-box;
              border-radius: 8px;
              background: #271e3a;
              position: relative;

              .title {
                font: {
                  family: "Gabarito-Medium";
                  size: 0.8rem;
                }
                color: #fff;
                padding: 0 0 8px;
              }
              .copyClipboardWrapper {
                position: absolute;
                top: 0;
                right: 0;
                padding: 6px 8px;
                
                .copyClipboard {
                  height: 20px;
                  font: {
                    family: "Gabarito-Bold";
                    size: 0.8rem;
                  }
                  background: $gravityGreen;
                  color: $purpleDark;
                  border: none;
                  border-radius: 4px;
                }
              }
              .codeWrapper {
                width: 100%;
                position: relative;

                .code {
                  height: 70px;
                  width: calc(100% - 16px);
                  font: {
                    family: Monospace;
                    size: 0.85rem;
                  }
                  padding: 8px;
                  resize: none;
                  border: none;
                  outline: 0;
                  border-radius: 8px;
                }
              }
            }

            /*&.left {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              padding-right: 6px;
            }
            &.right {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              padding-left: 6px;
            }*/
          }
        }
      }

      .privateToggle {
        position: absolute;
        transform: translate(16px, -40px);

        .toggleText {
          font: {
            family: "Gabarito-Medium";
            size: 1rem;
          }
          color: $purpleDark;
          transform: translate(64px, 6px);
          opacity: 0.5;

          &.private {
            opacity: 1;
          }

          .privateIcon {
            height: 12px;
            width: 12px;
            padding: 0 4px 0 0;
          }
        }
      }

      .videoSelection {
        padding: 12px 16px 0 4px;
        box-sizing: border-box;

        .instructions {
          font: {
            family: "Gabarito-Medium";
            size: 1rem;
          }
          color: $purpleDark;
          padding-left: 12px;
        }
      }

      .privateWrapper {
        padding: 16px 0 0;

        .makePrivate {
          font: {
            family: "Gabarito-Medium";
            size: 1rem;
          }
        }

        .buttonWrapper {
          padding: 8px 0;

          .private {
            height: 30px;
            font: {
              family: "Gabarito-Bold";
              size: 1rem;
            }
            background: $purpleLight;
            color: $gravityGreen;
            border: none;
            padding: 0 24px;
            border-radius: 15px;
          }
        }
      }

      .profileEdit {
        padding: 8px 12px;

        .entryTitle {
          font: {
            family: "Gabarito-Medium";
            size: 1.2rem;
          }
          padding: 12px 0;
        }
        .staticEntry {
          font: {
            family: "Gabarito-Regular";
            size: 1rem;
          }
          color: #555;
          padding: 0 0 0 12px;
        }
        .input {
          height: 40px;
          width: 380px;
          font: {
            family: "Gabarito-Regular";
            size: 1.2rem;
          }
          padding: 0 15px;
          border-radius: 20px;
          border: none;
          box-sizing: border-box;

          &:focus {
            outline: none;
          }

          &.description {
            width: 380px;
          }
        }
        .profilePreview {
          display: inline-block;
          vertical-align: middle;
          padding: 0 0 0 12px;

          .avatar {
            height: 100px;
            width: 100px;
            background-size: cover;
            background-position-x: 50%;
            background-position-y: 50%;
            border-radius: 50%;
            border: 2px solid $gravityGreen;
          }
        }
        .selectImage {
          display: block;
          height: 30px;
          width: 100px;
          font: {
            family: "Gabarito-Bold";
            size: 1rem;
          }
          color: $purpleLight;
          border: none;
          padding: 0 24px;
          border-radius: 15px;
          background: $gravityGreen;
          transform: translate(12px, 12px);
        }
        .inputImage {
          display: inline-block;
          vertical-align: middle;
          padding: 0 8px;
          visibility: hidden;
        }
      }

      // Youtube
      .context {
        padding-bottom: 34px;

        .contextHeader {
          padding: 0 0 12px 4px;

          .contextIcon {
            display: inline-block;
            vertical-align: middle;
            height: 14px;
          }
          .contextName {
            display: inline-block;
            vertical-align: middle;
            font: {
              family: "Gabarito-Bold";
              size: 0.85rem;
            }
            color: $purpleLight;
            padding: 0 4px;
          }
        }
        .contextWrapper {
          display: block;
          width: 100%;
          text-align: left;
          background: none;
          padding: 4px 0 0 22px;
          border: none;
          cursor: pointer;
          position: relative;

          .noResults {
            font: {
              family: "Gabarito-Regular";
              size: 0.75rem;
            }
            color: rgba(0, 0, 0, 0.7);
          }

          .contextThumbnail {
            display: inline-block;
            vertical-align: middle;

            .thumbnail {
              height: 36px;
              width: 48px;
              border-radius: 4px;
            }
          }
          .youtubeClose {
            height: 17px;
            width: 20px;
            font: {
              family: "Gabarito-Medium";
              size: 0.8rem;
            }
            text-align: center;
            color: #fff;
            background: red;
            padding: 3px 0 0;
            border-radius: 4px;
            position: absolute;
            top: 6px;
            right: 16px;
          }
          .contextTitle {
            display: inline-block;
            vertical-align: middle;
            width: calc(100% - 64px);
            padding: 0 4px;

            .name {
              font: {
                family: "Gabarito-Medium";
                size: 0.75rem;
              }
            }
            .description {
              font: {
                family: "Gabarito-Regular";
                size: 0.65rem;
              }
              padding: 4px 0;
              color: rgba(0, 0, 0, 0.7);
            }
          }
          .youtubeVideo {
            width: 100%;
          }
        }
      }

      .premiumWrapper {
        background: linear-gradient(90deg, $purpleDark 0%, $purpleLight 54%, $purpleDark 100%);
        padding: 12px 24px;
        box-sizing: border-box;
        border-radius: 24px;

        .premium {
          width: 250px;
          display: inline-block;
          text-align: center;
          padding: 6px;

          .premiumTitle {
            font: {
              family: "Gabarito-Bold";
              size: 1.2rem;
            }
            color: $gravityGreen;
            padding: 6px;
          }
          .premiumDescription {
            font: {
              family: "Gabarito-Regular";
              size: 1rem;
            }
            color: #fff;
            padding: 8px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      .buttonAccept {
        width: 100%;
        text-align: center;
        padding: 16px 0 2px;

        &.assetThread {
          padding-top: 100px;
        }
        &.premiumPrompt {
          padding: 28px 0 8px;
        }
        &.quick {
          padding-top: 28px;
        }
        .aiFollowUp {
          width: 100%;
          text-align: center;
          position: absolute;
          padding-top: 8px;

          .next {
            display: inline-block;
            vertical-align: middle;
            font: {
              family: "Gabarito-Black";
              size: 1rem;
            }
            color: $purpleDark;
            padding: 0 8px 0 0;
          }

          .keywordAI {
            display: inline-block;
            height: 17px;
            width: 17px;
            font: {
              family: "Gabarito-Black";
              size: 0.7rem;
            }
            color: #fff;
            background: $gravityGreen;
            padding: 2px 3px;
            box-sizing: border-box;
            border-radius: 2px;
          }
          .keywordWord {
            display: inline-block;
            font: {
              family: "Gabarito-Bold";
              size: 0.9rem;
            }
            color: $gravityGreen;
            padding: 0 0 0 4px;
            transform: translateY(1px);
          }
        }

        .sideBySide {
          display: inline-block;
          padding: 0 4px;
        }
        .contactUs {
          font: {
            family: "Gabarito-Bold";
            size: 1rem;
          }
          color: $purpleLight;
          border: none;
          padding: 0 40px;
          border-radius: 15px;
          background: transparent;

          &:hover {
            color: purple;
          }
        }
        .subscriptionButtons {
          display: inline-block;
          vertical-align: middle;
          width: calc(50% - 20px);
          padding: 0 4px;
          position: relative;

          .submitRequest {
            width: 100%;
            font: {
              family: "Gabarito-Bold";
              size: 1rem;
            }
            padding: 0;
            border: none;
            box-shadow: 0 0 20px 4px rgb(72, 171, 224, 0.8);

            &.yearly {
              background-image: repeating-linear-gradient(45deg, violet, $gravityGreen, $orange, $red, violet);
              background-size: 800% 800%;
              animation: rainbow 12s ease infinite;
              color: white;
            }
          }
          .magicCount {
            display: block;
            height: 16px;
            width: 140px;
            background: $gravityGreen;
            font: {
              family: "Gabarito-Bold";
              size: 0.8rem;
            }
            color: white;
            border-radius: 12px;
            padding-top: 2px;
            position: absolute;
            top: -14px;
            right: 8px;
            box-shadow: -2px 4px 3px rgba(10, 10, 10, 0.4);
          }
        }
        .submitRequest {
          height: 50px;
          font: {
            family: "Gabarito-Bold";
            size: 1.2rem;
          }
          color: $purpleLight;
          border: 2px solid $gravityGreen;
          padding: 0 180px;
          border-radius: 25px;
          background: $gravityGreen;
          position: relative;

          &.cancel {
            padding: 0 60px;
            background: $grey;
            border-color: $grey;
          }
          &.delete {
            padding: 0 60px;
            background: $red;
            border-color: $red;
          }
          &.uploading {
            padding: 0 60px;
            opacity: 0.4;
            pointer-events: none;
          }

          .Loading {
            position: absolute;
            right: 0;
            bottom: 0;
            transform: scale(0.5) translate(16px, 16px);
          }

          &:disabled {
            opacity: 0.4;
            background: transparent;
            color: $gravityGreen;
            cursor: default;

            &.success {
              background: $gravityGreen;
              border: 2px solid $gravityGreen;
            }
          }
        }
      }
    }
  }

  &.recorderModal {
    .modalWrapper {
      height: 870px;
      width: 850px;
      overflow-y: scroll;
      border-radius: 22px;

      &.quick {
        height: 750px;
      }

      .entryWrapper {
        &.recorderEntry {
          display: inline-block;
          padding: 0 12px;
        }
      }
    }
  }
}

@media screen and (max-height: 820px) {
  .Modal {
    .modalWrapper {
      top: 8px;
      transform: translateX(-50%);
    }
  }
}

.mobile {
  .Modal {
    .modalWrapper {
      width: calc(100% - 16px);
      top: auto;
      left: auto;
      transform: translate(8px, 8px);

      .modalTitle {
        height: auto;
      }
      .modalContent {
        .subscriptionButtonsWrapper {
          padding: 8px 0;

          .subscriptionButtons {
            display: inline-block;
            vertical-align: middle;
            width: calc(50% - 10px);
            padding: 0 4px;
            position: relative;

            .submitRequest {
              height: 60px;
              width: 100%;
              font: {
                family: "Gabarito-Bold";
                size: 1rem;
              }
              padding: 0;
              border: none;
              border-radius: 12px;
              box-shadow: 0 0 20px 4px rgb(72, 171, 224, 0.8);

              &.yearly {
                background-image: repeating-linear-gradient(45deg, violet, $gravityGreen, $orange, $red, violet);
                background-size: 800% 800%;
                animation: rainbow 12s ease infinite;
                color: white;
              }
            }
            .magicCount {
              display: block;
              height: 16px;
              width: 140px;
              background: $gravityGreen;
              font: {
                family: "Gabarito-Bold";
                size: 0.8rem;
              }
              text-align: center;
              color: white;
              border-radius: 12px;
              padding-top: 2px;
              position: absolute;
              top: -14px;
              right: 8px;
              box-shadow: -2px 4px 3px rgba(10, 10, 10, 0.4);
            }
          }
        }
        .datePickerWrapper {
          display: block;
          height: 30px;
          width: 50%;
          position: absolute;
          top: 550px;
          left: 70px;
          transform: translateX(calc(-50% + 8px));
          z-index: 2;

          .datePickerContainer {
            text-align: center;
            top: 0;
          }
        }
        .submitRequest {
          height: 50px;
          font: {
            family: "Gabarito-Bold";
            size: 1.2rem;
          }
          color: $purpleLight;
          border: 2px solid $gravityGreen;
          padding: 0 180px;
          border-radius: 25px;
          background: $gravityGreen;
          position: relative;

          &.cancel {
            padding: 0 60px;
            background: $grey;
            border-color: $grey;
          }
          &.delete {
            padding: 0 60px;
            background: $red;
            border-color: $red;
          }
          &.uploading {
            padding: 0 60px;
            opacity: 0.4;
            pointer-events: none;
          }

          .Loading {
            position: absolute;
            right: 0;
            bottom: 0;
            transform: scale(0.5) translate(16px, 16px);
          }

          &:disabled {
            opacity: 0.4;
            background: transparent;
            color: $gravityGreen;
            cursor: default;

            &.success {
              background: $gravityGreen;
              border: 2px solid $gravityGreen;
            }
          }
        }
        .buttonAccept {
          .sideBySide {
            min-width: 140px;
          }
        }
        .premiumWrapper {
          .premium {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .promptGenerate {
          position: relative;
          top: -18px;
        }
        .generator {
          height: 130px;
          padding: 16px;

          .inputWrapper {
            width: 100%;
            vertical-align: top;
            top: 0;
            transform: none;
            box-sizing: border-box;
            padding-bottom: 8px;

            .aiField {
              width: 100%;
              box-sizing: border-box;
            }
          }
          .buttonWrapper {
            display: block;
            top: 0;
            transform: none;

            .generateButton {
              float: right;
            }
          }
        }
        .entryWrapper {
          .inputWrapper {
            padding-top: 8px;
            transform: translateY(-8px);

            .input {
              &.recorder {
                height: 40px;
                border-radius: 20px;
                width: 100%;
              }
            }
          }
        }
        .aiScript {
          transform: translate(26px, 120px);
        }
        .uploadSelectionWrapper {
          width: 100%;
          padding-top: 40px;

          .uploadSelection {
            .instructions {
              .inputWrapper {
                .input {
                  width: calc(100% - 40px);
                }
              }
            }
          }
        }
        .buttonAccept {
          .submitRequest {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
    &.recorderModal {
      .modalWrapper {
        height: calc(100% - 32px);
        width: calc(100% - 32px);

        .entryWrapper {
          &.recorderEntry {
            width: calc(100% - 40px);

            .inputWrapper {
              .input {
                &.response {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
