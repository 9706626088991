@import "../exports/variables.scss";

.Tutorials {
    .tutorialsWrapper {
        .tutorialInner {
            display: inline-block;
            vertical-align: middle;
            padding: 24px;

            .tutorial {
                min-height: 380px;
                width: 355px;
                margin: 0;
                padding: 16px;
                border: none;
                border-radius: 8px;
                background: linear-gradient(
                    150deg,
                    rgb(4, 106, 201, 0.05) 0%,
                    rgb(255, 237, 252, 0.7) 35%,
                    rgb(159, 255, 247, 0.2) 100%
                );
                border: 2px solid rgba(255, 255, 255, 0.4);
                box-shadow: $morphShadow;
                animation: 250ms 0s normal forwards hopUp;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &:hover {
                    animation: 500ms 0s normal forwards hopDown;
                }

                .tutorialImage {
                    height: 240px;
                    width: 320px;
                    background: black;
                    border-radius: 8px;
                    position: absolute;
                    top: 24px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                .tutorialTag {
                    height: 16px;
                    font: {
                        family: "Gabarito-Medium";
                        size: 0.9rem;
                    }
                    text-transform: uppercase;
                    background-image: repeating-linear-gradient(45deg, violet, $gravityGreen, $orange, $red, violet);
                    background-size: 800% 800%;
                    animation: rainbow 12s ease infinite;
                    color: white;
                    position: absolute;
                    top: -18px;
                    right: -18px;
                    padding: 6px 12px;
                    border-radius: 14px;
                }
                .tutorialsData {
                    min-height: 100px;
                    padding-top: 260px;

                    .tutorialTitle {
                        min-height: 54px;
                        width: 320px;
                        font: {
                            family: "Gabarito-Bold";
                            size: 1.4rem;
                        }
                        color: $purpleLight;
                        padding: 0 0 16px 0;
                    }
                    .tutorialDesc {
                        width: 320px;
                        font: {
                            family: "Gabarito-Regular";
                            size: 1rem;
                        }
                        color: $purpleLight;
                    }
                }
            }
        }
    }
    .backgroundWrapper {
        padding: 48px 0 24px;

        .background {
            height: 240px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .backgroundImage {
                background-image: url("https://d3f260fu0a2g7m.cloudfront.net/marketing/jpg/gravity-waves.jpg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                min-width: 100%;
                min-height: 100%;
                border-radius: 16px;
            }
            .backgroundOverlay {
                height: 100%;
                width: 100%;
                background: rgba(10, 2, 30, 0.8);
                position: absolute;
                border-radius: 16px;
            }
            .backgroundTag {
                display: inline-block;
                font: {
                    family: "Gabarito-Bold";
                    size: 1.8rem;
                }
                color: white;
                background: $gravityGreen;
                position: absolute;
                top: -24px;
                padding: 8px 24px;
                border-radius: 12px;
            }
            .backgroundText {
                font: {
                    family: "Gabarito-Bold";
                    size: 3.2rem;
                }
                text-align: center;
                line-height: 3.6rem;
                color: white;
                position: absolute;
                padding: 24px;
                box-sizing: border-box;
            }
        }
    }
    .tutorialDisplay {
        .tutorialWrapper {
            max-width: 1080px;
            padding: 36px 0;
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            .tutorialData {
                padding: 32px 0 64px;

                .tutorialLongDesc {
                    font: {
                        family: "Gabarito-Bold";
                        size: 1.8rem;
                    }
                    color: #1c0c3f;
                    padding: 0 120px 0;
                    box-sizing: border-box;
                }
            }

            .stepWrapper {
                padding: 48px 110px 0;

                .stepImage {
                    display: inline-block;
                    vertical-align: top;
                    height: 240px;
                    width: 320px;
                    background: black;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    border-radius: 8px;
                    box-shadow: $morphShadow;
                }
                .stepData {
                    display: inline-block;
                    vertical-align: top;
                    width: calc(100% - 320px);
                    padding: 0 0 0 28px;
                    box-sizing: border-box;

                    .stepNumber {
                        .stepText {
                            display: inline-block;
                            font: {
                                family: "Gabarito-Bold";
                                size: 1.6rem;
                            }
                            .stepStep {
                                position: relative;
                                z-index: 1;
                            }
                            .stepDecoration {
                                height: 16px;
                                width: calc(100% + 16px);
                                background: $gravityGreen;
                                transform: skew(-36deg, -2deg) rotate(-2deg) translate(-16px, -20px);
                                z-index: -1;
                                border-radius: 5px;
                                opacity: 0.5;
                            }
                        }
                    }
                    .stepTitle {
                        font: {
                            family: "Gabarito-Bold";
                            size: 1.3rem;
                        }
                        padding: 2px 0;
                    }
                    .stepDesc {
                        font: {
                            family: "Gabarito-Regular";
                            size: 1.2rem;
                        }
                        padding: 8px 0;
                    }
                }
            }
        }
    }
}
