@import "../exports/variables.scss";

.InputEdit {
    box-sizing: border-box;

    .ineligibleEdit {
        display: inline-block;
        width: calc(100% - 166px);
        font: {
            family: "Gabarito-Regular";
            size: 1.0rem;
        }
        color: rgba(0, 0, 0, 0.8);
        padding: 16px 16px;

        &.folder {
            color: rgba(0, 0, 0, 0.5);
        }
    }
    .leaveWrapper {
        display: inline-block;
        width: 120px;
        padding: 8px 0 0 0;

        .leaveButton {
            display: inline-block;
            height: 30px;
            transform: translateY(8px);

            .loadingWrapper {
                display: inline-block;
                vertical-align: middle;
                position: relative;
                transform: translate(-28px,-24px);

                .Loading {
                    position: absolute;
                    transform: scale(0.6);
                }
            }

            .editButton {
                display: inline-block;
                vertical-align: middle;
                min-width: 120px;
                background: rgba(255, 255, 255, 0.5);
                font: {
                    family: "Gabarito-Medium";
                    size: 1.0rem;
                }
                color: $purpleLight;
                padding: 6px 16px;
                border: none;
                border-radius: 8px;
                transform: translateY(-12px);
            }
        }
    }

    .editInput {
        display: inline-block;
        height: 40px;
        width: calc(100% - 154px);
        font: {
            family: "Gabarito-Regular";
            size: 1.0rem;
        }
        padding: 8px 16px;
        border: none;
        border-radius: 20px;
        box-sizing: border-box;
    }
    .editButtons {
        display: inline-block;
        vertical-align: middle;
        width: 132px;
        padding: 10px;

        .buttonWrapper {
            display: inline-block;
            vertical-align: middle;
            padding: 0 2px;

            .editButton {
                height: 40px;
                width: 40px;
                border: none;
                border-radius: 20px;
                position: relative;

                &:disabled {
                    opacity: 0.2;

                    &.modifying {
                        opacity: 1;
                    }
                }

                &.undo {
                    background: $purpleLight;
                }
                &.save {
                    background: $gravityGreen;
                }
                &.delete {
                    background: $red;
                }

                .icon {
                    height: 16px;
                    width: 16px;
                    transform: translateY(2px);
                    opacity: 1;

                    &.disabled {
                        opacity: 0;
                    }
                }
                .Loading {
                    height: 36px;
                    width: 36px;
                    transform: scale(0.5) translate(-12px, -12px);
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}
