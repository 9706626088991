.Privacy {
    height: calc(100vh - 30px);
    overflow-y: scroll;
    position: relative;
    padding-bottom: 16px;
    box-sizing: border-box;

    .header {
        font: {
            family: 'Gabarito-Bold';
            size: 1.2rem;
        }
        padding: 8px 0 8px 12px;
        box-sizing: border-box;
    }
    .content {
        font: {
            family: 'Gabarito-Regular';
            size: 0.9rem;
        }
        padding: 12px 0 24px 12px;
        box-sizing: border-box;

        .title {
            font: {
                family: 'Gabarito-Bold';
                size: 0.9rem;
            }
        }

        li {
            padding: 0 0 0 24px;
            margin-bottom: -16px;
        }
    }
}