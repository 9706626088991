@import "../exports/variables.scss";

.CreatorStudio {
  &.mini {
    height: 90px;

    .welcomeColor {
      padding: 2px 0;
      border-radius: 30px;

      .welcomeTextWrapper {
        text-align: left;
        padding: 0px 16px;

        .welcomeWrapper {
          display: inline-block;
          position: relative;
          left: 50%;
          transform: translate(-50%, 0);

          .welcomeText {
            vertical-align: middle;
            font: {
              size: 1.7rem;
            }
            padding: 16px 0;
          }
          .studioType {
            vertical-align: middle;
            font: {
              size: 1.3rem;
            }
            padding: 3px 16px 0 4px;
          }
          .welcomeFunctions {
            display: inline-block;
            padding: 0;

            .welcomeButtonWrapper {
              display: inline-block;
              vertical-align: middle;
              padding: 4px 8px;
              position: relative;

              .welcomeButton {
                height: 28px;
                min-width: 232px;
                font: {
                  size: 1.1rem;
                }
                &.magicAIButton {
                  box-shadow: 0 0 12px 2px rgb(72, 171, 224, 0.8);
                }
              }
              .magicCount {
                height: 16px;
                font: {
                  size: 0.8rem;
                }
              }
            }
          }
        }
        .functionDescription {
          font: {
            size: 0.6rem;
          }
          transform: translate(120px, -16px);

          .descriptionWrapper {
            .featureHover {
              display: inline-block;
              padding: 0 20px;
  
              .unlimited {
                color: $gravityGreen;
              }
              .ai {
                color: $gravityGreen;
              }
            }
          }
        }
      }
    }
  }
  .welcomeColor {
    background: linear-gradient(90deg, $purpleDark 0%, $purpleLight 54%, $purpleDark 100%);
    width: 100%;
    position: relative;
    border-radius: 24px;
    overflow: hidden;

    .creatorBackground {
      height: 100%;
      width: 100%;
      background-image: url("https://d3f260fu0a2g7m.cloudfront.net/marketing/jpg/gravity-waves.jpg");
      background-size: cover;
      background-position-x: 50%;
      background-position-y: 50%;
      position: absolute;
      opacity: 0.06;
    }

    .welcomeTextWrapper {
      text-align: center;
      padding: 16px 26px;

      .welcomeWrapper {
        .welcomeText {
          display: inline-block;
          vertical-align: bottom;
          font: {
            family: "Gabarito-Black";
            size: 2.8rem;
          }
          color: white;
          padding: 36px 0 16px;
          color: $gravityGreen;
        }
        .studioType {
          display: inline-block;
          vertical-align: bottom;
          font: {
            family: "Gabarito-Medium";
            size: 1.8rem;
          }
          color: white;
          padding: 36px 0 21px 8px;
          color: white;
        }
        .welcomeFunctions {
          padding: 12px 0 42px;
          box-sizing: border-box;
          position: relative;

          .welcomeButtonWrapper {
            display: inline-block;
            vertical-align: middle;
            padding: 4px 8px;
            position: relative;

            .welcomeButton {
              height: 40px;
              min-width: 240px;
              font: {
                family: "Gabarito-Bold";
                size: 1.2rem;
              }
              background: white;
              border: none;
              border-radius: 6px;

              &:hover {
                box-shadow: 0 0 20px 4px rgb(72, 171, 224, 0.3);
              }

              &.magicAIButton {
                background-image: repeating-linear-gradient(45deg, violet, $gravityGreen, $orange, $red, violet);
                background-size: 800% 800%;
                animation: rainbow 12s ease infinite;
                color: white;
                box-shadow: 0 0 20px 4px rgb(72, 171, 224, 0.8);

                &:hover {
                  box-shadow: 0 0 20px 4px rgb(72, 171, 224, 1);
                }
              }

              .premium {
                background-image: repeating-linear-gradient(45deg, violet, $gravityGreen, $orange, $red, violet);
                background-size: 800% 800%;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                animation: rainbow 12s ease infinite;
              }
            }
            .magicCount {
              display: block;
              height: 20px;
              width: 92px;
              background: $gravityGreen;
              font: {
                family: "Gabarito-Bold";
                size: 0.9rem;
              }
              color: white;
              border-radius: 12px;
              position: absolute;
              top: -10px;
              right: 0;
              box-shadow: -2px 4px 3px rgba(10, 10, 10, 0.4);

              .magicNumber {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
      .functionDescription {
        height: 0px;
        width: 100%;
        font: {
          family: "Gabarito-Medium";
          size: 0.9rem;
        }
        text-align: center;
        color: white;
        transform: translateY(-20px);
        animation: 250ms 0s normal forwards fadeOut;

        &.hovering {
          animation: 250ms 0s normal forwards fadeIn;
        }

        .descriptionWrapper {
          .featureHover {
            display: inline-block;
            padding: 0 20px;

            .unlimited {
              display: inline-block;
              vertical-align: middle;
              font: {
                weight: bold;
                size: 1.0rem;
              }
              color: purple;
              padding: 0 4px 0 0;
              border-radius: 4px;
            }
            .ai {
              display: inline-block;
              vertical-align: middle;
              font: {
                family: "Gabarito-Black";
                size: 0.9rem;
              }
              padding: 0 6px 0 0;
              color: purple;
            }
            .feature {
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

.mobile {
  .CreatorStudio {
    .welcomeColor {
      .welcomeTextWrapper {
        padding: 0;

        .welcomeWrapper {
          .welcomeText {
            font: {
              size: 1.2rem;
            }
            padding: 18px 0 2px;
          }
          .studioType {
            font: {
              size: 1.0rem;
            }
            padding: 0 0 0 4px;
            transform: translateY(-3px);
          }
          .welcomeFunctions {
            padding: 12px 0 18px;
  
            .welcomeButtonWrapper {
              padding: 2px 8px;
  
              .welcomeButton {
                height: 34px;
                width: 100%;
                font: {
                  size: 1.1rem;
                }
              }
              .magicCount {
                height: 16px;
                width: 80px;
                font: {
                  size: 0.8rem;
                }
                border-radius: 12px;
                box-shadow: -2px 4px 3px rgba(10, 10, 10, 0.4);
              }
            }
          }
        }
      }
    }
  }
}