@import "../exports/variables.scss";

.ContentSection {
  width: 100%;

  .contentWrapper {
    .content {
      height: 300px;
      max-width: 1080px;
      padding: 48px 0;
      position: relative;
      left: 50%;
      transform: translateX(-50%);

      .contentBar {
        height: 300px;
        width: 50%;
        position: relative;

        &.left {
          float: left;
        }
        &.right {
          float: right;
        }

        .textWrapper {
          display: inline-block;
          height: 200px;
          width: 420px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .textHeadline {
            font: {
              family: "Gabarito-Bold";
              size: 2.2rem;
            }
            color: $purpleLight;
          }
          .textSubtext {
            font: {
              family: "Gabarito-Regular";
              size: 1.2rem;
            }
            padding: 16px 0;
            box-sizing: border-box;
          }
          .contentLink {
            .link {
              font: {
                family: "Gabarito-Bold";
                size: 0.9rem;
              }
              color: purple;
              text-decoration: none;
            }
          }
        }
        .contentImageWrapper {
          height: 300px;
          width: 420px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .recordIndicator {
            height: 30px;
            width: 30px;
            background: #ff4500;
            border-radius: 50%;
            position: absolute;
            top: 44px;
            left: 44px;
            animation: repeatinout 2s infinite;

            .innerRecord {
              height: 20px;
              width: 20px;
              background: #ff4500;
              border: 3px solid white;
              border-radius: 50%;
              position: absolute;
              top: 2px;
              left: 2px;
            }
          }
          .aiTools {
            border-radius: 50%;
            position: absolute;
            top: -36px;
            left: 120px;

            .gif {
              height: 30px;
              border-radius: 15px;
            }
          }
          .contentImage {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 860px) {
  .ContentSection {
    height: 1900px;
    width: 100%;

    .contentWrapper {
      .content {
        .contentBar {
          width: 100%;

          .textWrapper {
            transform: translate(-50%, 210px);
          }
          .contentImageWrapper {
            transform: translate(-50%, -400px);

            &.transfer {
              transform: translate(-50%, -450px);

              .contentImage {
                transform: scale(0.9);
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .ContentSection {
    height: 1820px;
    width: 100%;

    .contentWrapper {
      .content {
        .contentBar {
          width: 100%;

          .textWrapper {
            width: 300px;
          }
          .contentImageWrapper {
            height: 215px;
            width: 300px;

            .aiTools {
              border-radius: 50%;
              position: absolute;
              top: -44px;
              left: 30px;
            }
          }

          &.left {
            .textWrapper {
              transform: translate(-50%, 140px);
            }
          }
        }
      }
    }
  }
}
