@import '../exports/variables.scss';

.Dashboard {
  height: calc(100vh - 28px);
  width: 100%;
  overflow-y: scroll;

  .dashboardContent {
    .dashboardHeader {
      height: 40px;
      width: 100%;
      background: $purpleLight;
      color: white;
      font: {
        family: 'Gabarito-Regular';
        size: 0.9rem;
      }
      text-align: center;
      padding: 8px 12px;
      box-sizing: border-box;

      .calendly {
        text-decoration: none;
        color: white;

        .underlined {
          text-decoration: underline;
        }
      }
    }
    .menuWrapper {
      display: inline-block;
      vertical-align: top;
      width: 300px;
      padding-top: 82px;
      overflow: hidden;

      

      &.expanded {
        animation: 500ms 0s normal forwards menuWrapperExpand;
      }
    
      &.collapsed {
        animation: 500ms 0s normal forwards menuWrapperCollapse;
      }

    }
    .contentWrapper {
      height: calc(100vh - 32px);
      width: calc(100% - 300px);
      display: inline-block;
      overflow-y: scroll;

      &.expanded {
        animation: 500ms 0s normal forwards contentWrapperExpand;
      }
    
      &.collapsed {
        animation: 500ms 0s normal forwards contentWrapperCollapse;
      }

      &.tutorials {
        animation: 500ms 0s normal forwards contentWrapperCollapseTutorials;
      }
    }

  }
}

.mobile {
  .Dashboard {
    .dashboardContent {
      .menuWrapper {
        width: 76px;

        &.mobileSpace {
          display: none;
        }
      }
      .contentWrapper {  
        width: calc(100% - 76px);
        
        &.expanded {
          animation: 500ms 0s normal forwards contentWrapperExpand;

          .content {
            opacity: 0.2;
            -webkit-filter: blur(3px);
            -moz-filter: blur(3px);
            -o-filter: blur(3px);
            -ms-filter: blur(3px);
            filter: blur(3px);
          }
        }
      
        &.collapsed {
          animation: 500ms 0s normal forwards contentWrapperCollapse;

          .content {
            opacity: 1.0;
            -webkit-filter: blur(0) !important;
            -moz-filter: blur(0) !important;
            -o-filter: blur(0) !important;
            -ms-filter: blur(0) !important;
            filter: blur(0) !important;
          }
        }

        &.mobileSpace {
          animation: 500ms 0s normal forwards contentWrapperCollapseMobile;
        }
      }
  
    }
  }
}