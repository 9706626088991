@import '../exports/variables.scss';

.Message {
    width: 100%;
    padding: 16px 8px;
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    top: 42px;
    opacity: 0.0;

    &.active {
        animation: 5.0s 0s normal forwards linear fadeOut;
    }

    .messageWrapper {
        padding: 16px;
        background: rgba(245,255,253,0.8);
        border-radius: 4px;
        box-shadow: 5px 5px 10px 0 rgba(0,0,0,0.1), -6px -6px 9px 0 rgba(255,255,255,0.1);

        .messageImage {
            display: inline-block;
            vertical-align: top;
            height: 20px;
            width: 30px;

            .messageIcon {
                height: 20px;
                width: 20px;
            }
        }
        .messageText {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 30px);
            font: {
                family: 'Gabarito-Regular';
                size: 1.0rem;
            }
        }
    }
}