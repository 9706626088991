@import "../exports/variables.scss";

.SpacesView {
  .content {
    padding: 8px;

    .background {
      height: 300px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 16px;
      position: relative;

      .backgroundImage {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        min-width: 100%;
        min-height: 100%;
      }
      .backgroundOverlay {
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
      }
      .backgroundText {
        font: {
          family: "Gabarito-Bold";
          size: 4.0rem;
        }
        text-align: center;
        line-height: 3.6rem;
        color: white;
        position: absolute;
        padding: 24px;
        box-sizing: border-box;
      }
      .backgroundLoading {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .backgroundManager {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 2;

        .backgroundEdit {
          background: rgba(255, 255, 255, 0.7);
          border-radius: 8px;

          .backgroundButton {
            height: 39px;
            width: 39px;
            background: transparent;
            padding: 0;
            border: none;

            .backgroundIcon {
              height: 29px;
              width: 29px;
              transform: translate(1px, 2px);
            }
          }
        }
      }
      .actionButtonsWrapper {
        position: absolute;
        top: 12px;
        left: 46px;

        .actionButtons {
          display: inline-block;
          padding: 0 2px;

          .actionButton {
            height: 30px;
            min-width: 100px;
            font: {
              family: "Gabarito-Bold";
              size: 1.0rem;
            }
            color: $purpleLight;
            border: none;
            border-radius: 8px;

            &.saveButton {
              background: $gravityGreen;
            }
            &.cancelButton {
              background: $red;
            }
            &.removeButton {
              min-width: 0px;
              background: none;
              color: #fff;
            }
            &:disabled {
              opacity: 0.5;
            }
          }
        }
      }
      .input {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        z-index: -1;
      }
    }

    .membersButtonWrapper {
      position: absolute;
      top: 182px;
      right: 16px;
      z-index: 1;

      .membersButton {
        width: 100%;
        text-align: right;

        .fetchMembers {
          display: inline-block;
          vertical-align: middle;
          min-width: 160px;
          font: {
            family: "Gabarito-Bold";
            size: 1.0rem;
          }
          color: #003922;
          text-align: center;
          background: rgba(255, 255, 255, 0.7);
          text-align: right;
          padding: 4px 16px;
          border: none;
          border-radius: 6px;

          .Loading {
            display: inline-block;
            vertical-align: middle;
            height: 0px;
            width: 16px;
            transform: scale(0.5) translateY(-22px);
          }
          .membersIcon {
            display: inline-block;
            vertical-align: middle;
            height: 16px;
            width: 16px;
            transform: translateY(-1px);

            &.close {
              transform: scale(0.8) translateX(2px);
            }
          }
          .buttonText {
            display: inline-block;
            vertical-align: middle;
            padding: 0 0 0 6px;
          }
        }
      }
      .membersView {
        max-height: 362px;
        width: 215px;
        overflow-y: scroll;
        background: #fff;
        padding: 12px;
        border-radius: 8px;
        box-shadow: $morphShadow;
        transform: translateY(8px);

        .membersCountWrapper {
          padding: 0 0 12px;

          .membersCount {
            width: 100%;
            position: relative;
            text-align: center;
            background: rgba(0, 57, 34, 0.1);
            border-radius: 12px;

            .countShape {
              height: 80px;
              width: 80px;
              opacity: 0.8;
            }
            .count {
              font: {
                family: "Gabarito-Bold";
                size: 1.2rem;
              }
              color: #fff;
              padding: 0 0 12px;
              position: absolute;
              top: 32px;
              left: 50%;
              transform: translateX(-50%);
            }
            .membersCommunity {
              font: {
                family: "Gabarito-Bold";
                size: 0.9rem;
              }
              text-align: center;
              color: $greenDark;
              padding: 0 0 8px;
            }
          }
        }
      }
    }

    .data {
      text-align: center;

      &.membersActive {
        padding: 0 250px 0 0;
        box-sizing: border-box;
      }

      .spaceWrapper {
        display: inline-block;
        padding: 12px;
        animation: 250ms 0s normal forwards hopUp;

        &:hover {
          animation: 500ms 0s normal forwards hopDown;
        }

        .space {
          min-height: 180px;
          width: 380px;
          background: linear-gradient(
            150deg,
            rgb(4, 106, 201, 0.05) 0%,
            rgb(255, 237, 252, 0.4) 35%,
            rgb(159, 255, 247, 0.05) 100%
          );
          border: 2px solid rgba(255, 255, 255, 0.7);
          box-shadow: $morphShadow;
          position: relative;
          padding: 4px 8px;
          box-sizing: border-box;
          border-radius: 6px;

          &.scheduled {
            border: 2px solid rgba(210, 210, 210, 0.3);
          }

          .spaceData {
            width: 100%;
            background: none;
            border: none;
            padding: 4px 0;

            .scheduled {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background: rgba(255, 255, 255, 0.8);
              z-index: 1;
              border-radius: 6px;

              .scheduledText {
                display: inline-block;
                height: 24px;
                background: rgba(0, 216, 158, 0.8);
                padding: 8px 16px;
                border-radius: 8px;
                transform: translateY(68px);

                .calendarIcon {
                  display: inline-block;
                  vertical-align: middle;
                  height: 18px;
                  width: 18px;
                  transform: translateY(-1px);
                }

                .scheduledTextNode {
                  display: inline-block;
                  vertical-align: middle;
                  font: {
                    family: "Gabarito-Bold";
                    size: 1.2rem;
                  }
                  color: $greenDark;
                  padding-left: 8px;
                }
              }
            }

            .preview {
              display: inline-block;
              vertical-align: top;
              height: 100px;
              width: 100px;
              position: relative;

              .previewAuthor {
                height: 100px;
                width: 100px;
                border-radius: 50%;
                background-size: cover;
                background-position: center;
                position: absolute;

                &.voice {
                  height: 70px;
                  width: 70px;
                  background: rgb(175, 215, 200);
                  border-radius: 6px;
                  padding: 15px;
                  position: initial;
                }
              }

              .previewVideo {
                height: 100px;
                width: 100px;
                border-radius: 6px;
                background: #000;
                background-size: cover;
                background-position: center;
              }
            }
            .spaceTitle {
              display: inline-block;
              vertical-align: top;
              width: calc(100% - 100px);
              text-align: left;
              padding: 0 12px;
              box-sizing: border-box;

              .creation {
                padding: 0 0 4px;

                .dateWrapper {
                  display: inline-block;
                  vertical-align: top;
                  padding: 0 2px 0 0;

                  .date {
                    width: 70px;
                    text-align: center;
                    background: #003922;
                    padding: 2px 2px 0px;
                    border-radius: 35px;
                    font: {
                      family: "Gabarito-Medium";
                      size: 0.7rem;
                    }
                    color: white;
                    padding: 2px 2px 2px;
                  }
                }
                .magicAI {
                  display: inline-block;
                  vertical-align: top;
                  font: {
                    family: "Gabarito-Bold";
                    size: 0.7rem;
                  }
                  color: #fff;
                  background-image: repeating-linear-gradient(45deg, violet, $gravityGreen, $orange, $red, violet);
                  background-size: 800% 800%;
                  animation: rainbow 12s ease infinite;
                  padding: 2px 16px;
                  border-radius: 35px;
                }
                .document {
                  display: inline-block;
                  vertical-align: middle;
                  padding: 4px 0 0 2px;

                  &.andYoutube {
                    padding: 1px 0 0 2px;
                  }

                  .icon {
                    height: 12px;
                    width: 12px;
                  }
                }
                .youtube {
                  display: inline-block;
                  vertical-align: middle;
                  transform: translateY(1px);

                  .icon {
                    height: 16px;
                    width: 22px;
                  }
                }
                .narchives {
                  display: inline-block;
                  vertical-align: middle;
                  padding: 1px 0 0 0;

                  .icon {
                    height: 12px;
                    width: 12px;
                    border-radius: 4px;
                  }
                }
                .figjam {
                  display: inline-block;
                  vertical-align: middle;
                  padding: 2px 0 0;

                  .icon {
                    height: 12px;
                    width: 14px;
                  }
                }
                .gdrive {
                  display: inline-block;
                  vertical-align: middle;
                  padding: 2px 2px 0 0;

                  .icon {
                    height: 12px;
                    width: 12px;
                  }
                }
                .canva {
                  display: inline-block;
                  vertical-align: middle;
                  padding: 2px 2px 0 1px;

                  .icon {
                    height: 12px;
                    width: 12px;
                  }
                }
              }
              .check {
                position: absolute;
                top: 8px;
                right: 8px;

                .checkImage {
                  height: 16px;
                  width: 16px;
                  opacity: 0.2;

                  &.complete {
                    opacity: 1;
                  }
                }
              }
              .title {
                font: {
                  family: "Gabarito-Bold";
                  size: 1.1rem;
                }
                color: $greenDark;
              }
            }
            .participants {
              width: 100%;
              padding: 8px;
              position: absolute;
              left: 0;
              bottom: 8px;
              box-sizing: border-box;

              .mainAvatarWrapper {
                padding: 0 2px;
                position: relative;
                text-align: left;

                .author {
                  height: 14px;
                  width: 14px;
                  position: absolute;
                  top: -10px;
                  left: 10px;
                }
                .mainAvatar {
                  display: inline-block;
                  vertical-align: middle;
                  height: 30px;
                  width: 30px;
                  background-size: cover;
                  background-position-x: 50%;
                  background-position-y: 50%;
                  border-radius: 50%;
                }
                .mainName {
                  display: inline-block;
                  vertical-align: middle;
                  padding: 0 4px;

                  .createdName {
                    font: {
                      family: "Gabarito-Bold";
                      size: 0.9rem;
                    }
                  }
                  .createdText {
                    font: {
                      family: "Gabarito-Medium";
                      size: 0.6rem;
                    }
                    display: none;
                  }
                }
              }
              .viewResponses {
                border: none;
                position: absolute;
                bottom: 6px;
                right: 8px;

                .responsesText {
                  display: inline-block;
                  font: {
                    family: "Gabarito-Bold";
                    size: 1.0rem;
                  }
                  color: black;
                  padding: 0 4px;
                }
                .responsesIcon {
                  display: inline-block;
                  height: 16px;
                  width: 16px;
                  transform: translateY(1px);
                }
              }
              .secondaryAvatarWrapper {
                display: inline-block;
                vertical-align: middle;
                padding: 0 2px;

                .avatar {
                  display: inline-block;
                  vertical-align: middle;
                  height: 30px;
                  width: 30px;
                  background-size: cover;
                  background-position-x: 50%;
                  background-position-y: 50%;
                  border-radius: 50%;
                }
                .secondaryCounter {
                  display: inline-block;
                  vertical-align: middle;
                  height: 30px;
                  width: 30px;
                  background: #ffd54f;
                  color: $purpleLight;
                  font: {
                    family: "Gabarito-Bold";
                    size: 0.7rem;
                  }
                  position: relative;
                  border-radius: 50%;

                  .counter {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }
            }
          }
          .removeSpace {
            position: absolute;
            top: 0;
            right: 0;
            padding: 5px;

            .button {
              height: 26px;
              width: 26px;
              border: none;
              background: none;
            }
          }
        }
      }
    }
    .emptySpace {
      width: 100%;
      font: {
        family: "Gabarito-Medium";
        size: 1.0rem;
      }
      color: $midGrey;
      text-align: center;
      padding: 24px 0;

      .noPrompts {
        .noPromptsIcon {
          height: 60px;
          width: 60px;
        }
      }
    }
  }
}

.mobile {
  .SpacesView {
    .background {
      height: 160px;

      .backgroundText {
        font: {
          size: 1.6rem;
        }
        line-height: 1.6rem;
      }
    }
    .content {
      .layoutBuffer {
        height: 40px;
      }
      .membersButtonWrapper {
        top: 277px;
        left: 50%;
        transform: translateX(-50%);

        .membersView {
          transform: translateX(-45px);
        }
      }
      .data {
        position: relative;

        &.membersActive {
          opacity: 0.25;
          padding: 0;
        }

        .spaceWrapper {
          display: block;
          padding: 8px 0;
          position: relative;

          .space {
            width: 100%;
          }
        }
      }
    }
  }
}
