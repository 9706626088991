@import "../exports/variables.scss";

.Widget {
    height: 500px;
    width: 500px;

    .widgetOuter {
        height: 500px;
        width: 500px;
        background: transparent;
        padding: 8px;
        box-sizing: border-box;
        border-radius: 12px;

        .widgetWrapper {
            height: calc(100% - 8px);
            background: white;
            border: 4px solid white;
            box-shadow: $morphShadow;
            position: relative;
            border-radius: 12px;
            overflow: hidden;

            .widgetBackground {
                background: linear-gradient(
                    150deg,
                    rgb(4, 106, 201, 0.1) 0%,
                    rgb(255, 237, 252, 0.4) 35%,
                    rgb(159, 255, 247, 0.3) 100%
                );
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 0;
            }

            .widgetHeader {
                height: 50px;
                width: 100%;
                padding: 6px;
                box-sizing: border-box;
                position: relative;
                z-index: 1;

                .widgetHeaderInner {
                    background: rgba(100, 200, 180, 0.25);
                    border-radius: 8px;

                    .gravityHome {
                        background: none;
                        border: none;
                        padding: 4px 6px;

                        .gravityLogo {
                            height: 32px;
                            transform: translate(2px, 2px);
                        }
                    }
                    .gravityPower {
                        float: right;
                        padding: 13px 8px;
                        font: {
                            family: "Gabarito-Bold";
                            size: 0.9rem;
                        }

                        .visitAnchor {
                            padding: 3px 8px 4px;
                            border-radius: 6px;
                            background: $gravityGreen;
                            color: white;
                            text-decoration: none;
                        }
                    }
                }
            }
            .widgetVideo {
                position: relative;
                z-index: 1;
                
                .Video {
                    &.embed {
                        transform: scale(0.89) translate(-24px, -12px);
                    }
                }
            }
            .widgetData {
                position: relative;
                top: -22px;
                z-index: 1;

                .responderWrapper {
                    padding: 0 8px;
                    box-sizing: border-box;

                    .responder {
                        display: inline-block;
                        vertical-align: middle;

                        .responderAvatar {
                            height: 50px;
                            width: 50px;
                            background-size: cover;
                            background-position-x: 50%;
                            background-position-y: 50%;
                            border-radius: 50%;
                            border: 4px solid $gravityGreen;
                        }
                    }
                    .metadata {
                        display: inline-block;
                        vertical-align: middle;
                        padding: 0 8px;

                        .name {
                            font: {
                                family: "Gabarito-Bold";
                                size: 1.4rem;
                            }
                            color: #2c5041;
                        }
                        .creation {
                            font: {
                                family: "Gabarito-Medium";
                                size: 0.7rem;
                            }
                            color: #2c5041;
                            padding: 0 2px;
                        }
                    }
                }
                .descriptionWrapper {
                    padding: 6px 8px 8px;

                    .triangleUp {
                        width: 0;
                        height: 0;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-bottom: 12px solid #271e3a;
                        transform: translateX(20px);
                    }

                    .description {
                        max-height: 58px;
                        padding: 6px 16px;
                        background: #271e3a;
                        border-radius: 6px;
                        overflow-y: scroll;
                        position: relative;

                        .descriptionText {
                            text-align: left;
                            font: {
                                family: "Gabarito-Bold";
                                size: 1rem;
                            }
                            color: white;
                        }
                    }
                }
                .visit {
                    display: inline-block;
                    font: {
                        family: "Gabarito-Bold";
                        size: 0.9rem;
                    }
                    padding: 2px 0;
                    position: absolute;
                    right: 8px;
                    top: 10px;

                    &.empty {
                        text-align: center;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    .gravityJoinWrapper {
                        .gravityJoin {
                            font: {
                                family: "Gabarito-Bold";
                                size: 1rem;
                            }
                            background-image: repeating-linear-gradient(
                                45deg,
                                violet,
                                $gravityGreen,
                                $orange,
                                $red,
                                violet
                            );
                            text-decoration: none;
                            background-size: 800% 800%;
                            animation: rainbow 12s ease infinite;
                            color: white;
                            box-shadow: 0 0 20px 2px rgb(72, 171, 224, 0.5);
                            border: none;
                            padding: 4px 12px;
                            border-radius: 6px;
                        }
                    }
                }
            }
        }
    }
}
