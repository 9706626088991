@import "../exports/variables.scss";

.Member {
  .member {
    padding: 4px 0;
    position: relative;

    .avatarWrapper {
      display: inline-block;
      vertical-align: middle;
      height: 40px;
      width: 40px;
      padding: 0 10px 0 0;
      position: relative;

      .creatorCrown {
        height: 14px;
        width: 14px;
        position: absolute;
        top: -10px;
        left: 14px;
      }

      .avatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-size: cover;
        background-position-x: 50%;
        background-position-y: 50%;
        border-radius: 50%;
      }
    }
    .memberData {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 50px);

      .name {
        font: {
          family: "Gabarito-Medium";
          size: 0.9rem;
        }
      }
      .joined {
        font: {
          family: "Gabarito-Regular";
          size: 0.7rem;
        }
        color: $midGrey;
      }
    }
    .deleteWrapper {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;

      .memberDelete {
        height: 41px;
        width: 41px;
        font: {
          family: "Gabarito-Bold";
          size: 2.0rem;
        }
        background: rgba(220, 220, 220, 0.9);
        color: $red;
        border: none;
        border-radius: 50%;
        transform: translate(1px, 5px);

        opacity: 0;

        &.visible {
          opacity: 1;
        }
      }
    }
  }
}
