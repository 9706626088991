@import "../exports/variables.scss";

.Testimonials {
  text-align: center;
  padding: 32px 32px 90px;

  .moduleWrapper {
    .credentials {
      font: {
        family: "Gabarito-Bold";
        size: 1.2rem;
      }
      color: $purpleLight;
      padding: 8px 0;
    }
    .video {
      display: inline-block;
      padding: 24px 16px;

      .school {
        .schoolLogo {
          height: 60px;
        }
      }
      iframe {
        border-radius: 12px;
      }
    }
  }
}

.mobile {
  .Testimonials {
    .moduleWrapper {
      .credentials {
        font: {
          size: 1.0rem;
        }
      }
      .video {
        .school {
          .schoolLogo {
            height: 60px;
          }
        }
        iframe {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}