@import '../exports/variables.scss';

.Vote {
  .voteWrapper {
    padding: 4px 0;
    text-align: center  ;

    .voteButton {
      height: 22px;
      width: 100%;
      font: {
        family: 'Gabarito-Bold';
        size: 0.9rem;
      }
      padding: 0 16px;
      border: 2px solid $purpleDark;
      color: $purpleDark;
      border-radius: 6px;
      box-sizing: border-box;

      &.selected {
        border: 2px solid $purpleLight;
        background: $purpleLight;
        color: white;
      }
    }
  }
}