@import "../exports/variables.scss";

.Participant {
  display: inline-block;
  vertical-align: middle;

  .secondaryAvatar {
    position: relative;

    .plusMore {
      height: 44px;
      width: 44px;

      .plusNumber {
        height: 32px;
        width: 32px;
        font-family: "Gabarito-Bold";
        font-size: 0.8rem;
        background-color: white;
        border: 2px solid white;
        border-radius: 50%;
        transform: translate(6px, 5px);
        position: relative;

        .numberText {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .avatar {
      height: 38px;
      width: 38px;
      border: 2px solid white;
      border-radius: 50%;

      &.text {
        background: #002516;
        text-align: center;

        .avatarText {
          font-family: "Gabarito-Medium";
          font-size: 1.0rem;
          color: white;
          position: relative;
          transform: translateY(9px);
          user-select: none;
          cursor: default;
        }
      }
      &.display {
        height: 50px;
        width: 50px;

        .avatarText {
          font-size: 1.4rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &.asset {
        height: 26px;
        width: 26px;

        .avatarText {
          font-size: 0.7rem;
          transform: translateY(7px);
        }
      }
    }
    .participantName {
      width: 100px;
      font-family: "Gabarito-Bold";
      font-size: 0.8rem;
      text-align: center;
      background: white;
      position: absolute;
      left: calc(-50% - 10px);
      border-radius: 4px;
    }
    .seeAll {
      min-width: 100px;
      font-family: "Gabarito-Bold";
      font-size: 0.8rem;
      text-align: right;
      border: none;
      position: absolute;
      top: 50px;
      right: 0;
    }
    .participantNames {
      width: 180px;
      background: rgba(255, 255, 255, 0.9);
      position: absolute;
      top: 72px;
      right: 0;
      padding: 10px 12px;
      box-sizing: border-box;
      border-radius: 12px;
      display: none;

      &.active {
        display: block;
      }

      .participant {
        font-family: "Gabarito-Regular";
        font-size: 0.8rem;
        padding: 2px 0;
      }
    }
  }
}
