$gravityGreen: #00d89f;
$greenDark: #003922;
$purpleLight: #271e3a;
$purpleDark: #150b2a;
$purpleDarkOpaque: rgba(30,10,65,0.9);

$lightPurple: #51436d;
$lightErrorPurple: #805fd5;
$textPurple: #e5dbf9;

$grey: #b4b4b4;
$dashGrey: #f2f2f2;
$midGrey: #646464;
$yellow: #ffec6e;
$orange: #ffaa6e;
$red: #ff6e6e;
$offWhite: #eeeeee;

/* LitWorld */
$lwOrange: #f8a720;
$lwGrey: rgba(60,60,60,1.0);
$lwBlue: #44b2a1;

/* App States */
$disabledText: #cccccc;
$morphShadow: 3px 3px 5px 2px rgb(0 0 0 / 12%), -6px -4px 4px 1px rgb(255 255 255 / 40%);

/* Menu */
$menuGrey: rgb(230,230,230,0.95);

/* Recorder Module */
$playDivider: rgba(120,120,120,0.8);

/* Player */
$clearGrey: rgba(240,240,240,0.7);
$clearGreen: rgba(0,216,159,0.6);