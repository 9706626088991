@import "../exports/variables.scss";

.PublicRecorder {
    height: 460px;
    width: 680px;

    .publicOuter {
        height: 460px;
        width: 680px;
        background: transparent;
        padding: 8px;
        box-sizing: border-box;
        border-radius: 12px;

        .publicWrapper {
            height: calc(100% - 8px);
            background: white;
            border: 4px solid white;
            box-shadow: $morphShadow;
            position: relative;
            border-radius: 12px;
            overflow: hidden;

            .recorderBackground {
                background: linear-gradient(
                    150deg,
                    rgb(4, 106, 201, 0.1) 0%,
                    rgb(255, 237, 252, 0.4) 35%,
                    rgb(159, 255, 247, 0.3) 100%
                );
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 0;

                &.lw {
                    background: linear-gradient(
                        150deg,
                        rgb(4, 106, 201, 0.1) 0%,
                        rgba(255, 235, 202, 0.4) 35%,
                        rgba(255, 175, 38, 0.08) 100%
                    );
                }
            }
            .Recorder {
                height: calc(100% + 115px);
                transform: scale(0.8) translate(-110px, -70px);

                .recorderCamera {
                    .recorderWrapper {
                        width: 640px;
                        transform: translate(calc(-50% + 15px), calc(-50% - 5px)) scale(1.2);

                        .recorderScreenStart {
                            display: none;
                        }
                    }
                }
                .buttonControls {
                    transform: translateY(16px) scale(1.4);
                }
                .echoCancellation {
                    display: none;
                }
            }

            .notEmbeddable {
                height: 18px;
                font: {
                    family: "Gabarito-Bold";
                    size: 1rem;
                }
                color: $gravityGreen;
                background: transparent;
                padding: 8px 24px;
                position: absolute;
                top: calc(50% - 24px);
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 6px;
            }

            .recorderLogin {
                height: 200px;
                width: 400px;
                background: rgba(20, 20, 20, 1);
                position: absolute;
                top: 70px;
                left: 126px;

                .recorderType {
                    text-align: center;

                    &.gravityLogin {
                        text-align: left;
                        padding: 0 24px;
                    }

                    .text {
                        display: inline-block;
                        vertical-align: middle;
                        font: {
                            family: "Gabarito-Bold";
                            size: 1rem;
                        }
                        color: $gravityGreen;
                        padding: 0 6px;

                        &.lw {
                            color: $lwOrange;
                        }
                    }

                    .guestLoginButton {
                        display: inline-block;
                        vertical-align: middle;
                        font: {
                            family: "Gabarito-Bold";
                            size: 1rem;
                        }
                        color: white;
                        padding: 4px 8px 4px 6px;
                        border: 1px solid $purpleLight;
                        background: $purpleDark;
                        border-radius: 6px;

                        .green {
                            display: inline-block;
                            vertical-align: middle;
                            font: {
                                family: "Gabarito-Bold";
                                size: 0.8rem;
                            }
                            color: $gravityGreen;
                        }
                        .regular {
                            display: inline-block;
                            vertical-align: middle;
                            padding-left: 4px;
                        }
                    }

                    .type {
                        display: inline-block;
                        vertical-align: middle;
                        padding: 2px 8px 2px 6px;
                        border: 1px solid $purpleLight;
                        background: $purpleDark;
                        border-radius: 6px;

                        .gravityLogo {
                            display: inline-block;
                            vertical-align: middle;
                            height: 24px;
                        }
                        .gravityText {
                            display: inline-block;
                            vertical-align: middle;
                            font: {
                                family: "Gabarito-Bold";
                                size: 0.8rem;
                            }
                            color: $gravityGreen;
                        }
                    }
                }
                .loginLoader {
                    display: inline-block;
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    .Loading {
                        display: inline-block;
                        position: relative;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    .loginText {
                        font: {
                            family: "Gabarito-Bold";
                            size: 0.8rem;
                        }
                        color: $gravityGreen;
                        padding: 4px 0;
                    }
                }
                .recorderDetails {
                    padding: 8px;

                    .entryWrapper {
                        width: 320px;
                        padding: 4px 0;
                        position: relative;
                        left: 50%;
                        transform: translateX(-50%);

                        .entryLabel {
                            display: inline-block;
                            font: {
                                family: "Gabarito-Medium";
                                size: 1rem;
                            }
                            color: white;
                            padding: 2px 0;
                        }
                        .entryInputWrapper {
                            .entryInput {
                                height: 20px;
                                width: 300px;
                                font: {
                                    family: "Gabarito-Medium";
                                    size: 1rem;
                                }
                                padding: 4px 8px;
                                border: 2px solid $gravityGreen;
                                border-radius: 6px;

                                &:focus {
                                    outline: none;
                                }
                            }
                        }
                    }

                    &.lw {
                        .entryWrapper {
                            .entryInputWrapper {
                                .entryInput {
                                    border-color: $lwOrange;
                                }
                            }
                        }
                    }
                }
                .loginSubmit {
                    text-align: center;
                    padding: 16px 0;

                    .submitButton {
                        width: 312px;
                    }

                    .Google {
                        display: inline-block;
                    }
                }
            }

            .promptViewer {
                height: 100px;
                width: calc(100% - 32px);
                background: rgba(80, 80, 80, 0.2);
                position: absolute;
                top: 20px;
                left: 16px;
                border-radius: 8px;
                padding: 8px;
                box-sizing: border-box;

                .videoWrapper {
                    display: inline-block;
                    vertical-align: top;
                    height: 100%;
                    width: 120px;
                    border-radius: 6px;
                    background: #000;
                    background-size: cover;
                    background-position: center;
                    position: relative;

                    .overlayPlayButton {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background: none;
                        border: none;

                        .openImage {
                            height: 30px;
                            width: 30px;
                            background: rgba(0, 0, 0, 0.4);
                            padding: 6px;
                            border-radius: 50%;
                            position: absolute;
                            z-index: 1;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);

                            .openIcon {
                                height: 30px;
                                width: 30px;
                            }
                        }
                    }
                }
                .videoData {
                    display: inline-block;
                    vertical-align: top;
                    height: 100%;
                    width: calc(100% - 120px);
                    padding: 0 0 0 8px;
                    box-sizing: border-box;

                    .videoTitle {
                        font: {
                            family: "Gabarito-Bold";
                            size: 1.1rem;
                        }
                        color: $gravityGreen;
                        padding: 0 0 4px;
                    }
                    .videoDescription {
                        height: 60px;
                        background: rgba(140, 140, 140, 0.1);
                        border-radius: 8px;
                        overflow-y: scroll;

                        .description {
                            font: {
                                family: "Gabarito-Regular";
                                size: 0.9rem;
                            }
                            color: white;
                            padding: 8px 10px;
                        }
                    }
                }

                &.hidden {
                    height: 34px;

                    .videoWrapper {
                        display: none;
                    }
                    .videoData {
                        .videoTitle {
                            opacity: 0.4;
                            padding-left: 120px;
                        }
                        .videoDescription {
                            display: none;
                        }
                    }
                }

                .hide {
                    height: 18px;
                    font: {
                        family: "Gabarito-Bold";
                        size: 0.8rem;
                    }
                    background: rgba(240, 240, 240, 0.4);
                    padding: 0 8px;
                    border: none;
                    border-radius: 9px;
                    position: absolute;
                    top: 8px;
                    right: 8px;
                }
            }

            .logoWrapper {
                position: absolute;
                left: 8px;
                bottom: 12px;

                .gravityLogo {
                    display: inline-block;
                    vertical-align: text-bottom;
                    height: 36px;
                }

                &.lw {
                    bottom: 8px;

                    .litworldLogo {
                        display: block;
                        height: 28px;
                        transform: translateY(4px);
                    }
                    .poweredBy {
                        display: inline-block;
                        vertical-align: text-bottom;
                        font: {
                            family: "Gabarito-Medium";
                            size: 0.6rem;
                        }
                        color: $lwGrey;
                        padding: 0 2px 0 4px;
                        transform: translateY(-2px);
                    }
                    .gravityLink {
                        text-decoration: none;
                        outline: none;

                        .gravityLogo {
                            display: inline-block;
                            vertical-align: text-bottom;
                            height: 16px;
                        }
                    }
                }
            }
            .videoWrapperFull {
                height: calc(100% - 76px);
                width: calc(100% - 16px);
                position: absolute;
                top: 12px;
                left: 8px;
                border-radius: 4px;
                overflow: hidden;

                .videoVideo {
                    height: 100%;
                    width: 100%;
                }
                .closeVideo {
                    height: 18px;
                    font: {
                        family: "Gabarito-Bold";
                        size: 0.8rem;
                    }
                    background: rgba(0,216,159,0.8);
                    padding: 0 8px;
                    border: none;
                    border-radius: 9px;
                    position: absolute;
                    top: 16px;
                    right: 16px;
                }
            }
        }
        .submitButton {
            height: 48px;
            min-width: 140px;
            font: {
                family: "Gabarito-Bold";
                size: 1.2rem;
            }
            color: black;
            border: none;
            padding: 8px 32px;
            background: $gravityGreen;
            border-radius: 24px;

            &.recorder {
                position: absolute;
                bottom: 8px;
                right: 8px;
            }

            &.lw {
                color: $lwGrey;
                background: $lwOrange;
            }

            &:disabled {
                opacity: 0.4;
                cursor: default;
            }
        }
        .submitLoader {
            position: absolute;
            right: 0;
            bottom: 4px;

            .Loading {
                transform: scale(0.5);
            }
        }
        .successWrapper {
            height: 364px;
            width: 640px;
            background: rgb(230, 255, 249);
            position: absolute;
            top: 8px;
            left: 8px;
            border-radius: 4px;
            overflow: hidden;

            &.lw {
                background: rgb(230, 250, 255);
            }

            .confettiBack {
                height: 100%;
                width: 100%;
                background: url("https://d3f260fu0a2g7m.cloudfront.net/marketing/gif/confetti.gif");
                opacity: 0.2;
                z-index: 0;
                position: absolute;
            }
            .logoShow {
                position: absolute;
                opacity: 0.9;

                &.g {
                    height: 300px;
                    top: 100px;
                    right: -60px;
                }
                &.lw {
                    height: 500px;
                    top: 100px;
                    right: -80px;
                }
            }
            .successText {
                text-align: center;
                position: absolute;
                top: 40px;
                left: 52px;
                z-index: 1;

                .top {
                    font: {
                        family: "Gabarito-Bold";
                        size: 5.2rem;
                    }
                    color: $gravityGreen;

                    &.lw {
                        color: $lwBlue;
                    }
                }
                .text {
                    font: {
                        family: "Gabarito-Bold";
                        size: 1.2rem;
                    }
                    color: $lightPurple;

                    &.lw {
                        color: $lwOrange;
                    }
                }
            }
        }
    }
}
