@import "../exports/variables.scss";

.CallOut {
    width: 100%;
    padding: 36px;
    box-sizing: border-box;

    .callOutWrapper {
        width: 100%;
        padding: 140px 32px;
        background: radial-gradient(circle, rgba(179, 174, 238, 1) 0%, rgba(149, 226, 197, 1) 100%);
        border-radius: 22px;
        box-sizing: border-box;

        .callOut {
            display: inline-block;
            max-width: 900px;
            font: {
                family: "Gabarito-Bold";
                size: 3.2rem;
            }
            color: white;
            text-align: center;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

@media only screen and (max-width: 860px) {
    .CallOut {
        .callOutWrapper {   
            .callOut {
                font: {
                    size: 2.8rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .CallOut {
        .callOutWrapper {   
            .callOut {
                font: {
                    size: 2.2rem;
                }
            }
        }
    }
}