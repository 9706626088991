@import "../exports/variables.scss";

.ThreadView {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -4px;
  left: 0;
  z-index: 5;

  .threadFade {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    animation: 500ms 0s normal forwards fadeIn;

    &.closing {
      animation: 500ms 0s normal forwards fadeOut;
    }
  }

  .closeThreadWrapper {
    position: absolute;
    top: 16px;
    left: 16px;
    padding: 5px;
    z-index: 2;
    animation: 500ms 0s normal forwards fadeIn;

    &.closing {
      animation: 500ms 0s normal forwards fadeOut;
    }

    .closeThread {
      height: 34px;
      font: {
        family: "Gabarito-Bold";
        size: 1.0rem;
      }
      color: black;
      background: $gravityGreen;
      padding: 2px 16px;
      border: none;
      border-radius: 17px;
    }
  }

  .threadModal {
    height: calc(100% - 66px);
    width: 60%;
    max-width: 500px;
    min-width: 240px;
    background: $purpleDark;
    position: absolute;
    padding-bottom: 40px;
    top: 12px;
    right: 8px;
    z-index: 1;
    box-sizing: border-box;
    overflow-y: scroll;
    transform: translateX(100%);
    animation: 600ms 0s normal forwards menuSlideIn;
    border-radius: 22px;

    @media (max-width: 840px) {
      padding-bottom: 80px !important;
    }

    &.closing {
      animation: 600ms 0s normal forwards menuSlideOut;
    }

    .response {
      position: relative;
    }

    .media {
      text-align: center;

      .videoPlayer {
        max-height: 500px;
        width: 100%;
      }
    }

    .dataWrapper {
      padding: 0 14px 14px;
    }

    .emojiWrapper {
      text-align: left;

      .emojis {
        display: inline-block;
      }
      .emojiSelections {
        display: inline-block;
      }
    }
    .deleteResponse {
      background: none;
      border: none;
      position: absolute;
      top: 6px;
      right: 6px;
      background: rgba(240, 240, 240, 0.7);
      border: none;
      border-radius: 50%;
      height: 40px;
      width: 40px;

      .deleteIcon {
        height: 24px;
        width: 20px;
      }
    }
    .responderWrapper {
      display: inline-block;
      width: 100%;
      padding: 8px 0;
      position: relative;

      .responder {
        display: inline-block;
        vertical-align: middle;

        .responderAvatar {
          height: 50px;
          width: 50px;
          background-size: cover;
          background-position-x: 50%;
          background-position-y: 50%;
          border-radius: 50%;
        }
      }
      .metadata {
        display: inline-block;
        vertical-align: middle;
        padding: 0 8px;

        .name {
          font: {
            family: "Gabarito-Medium";
            size: 1.2rem;
          }
          color: white;
        }
        .handle {
          font: {
            family: "Gabarito-Medium";
            size: 0.7rem;
          }
          color: $offWhite;
          display: none;
        }
        .creation {
          font: {
            family: "Gabarito-Medium";
            size: 0.8rem;
          }
          color: $midGrey;
        }
      }

      &.isAI {
        .responder {
          float: right;
        }
        .metadata {
          float: right;
          transform: translateY(8px);

          .name {
            text-align: right;
            color: $gravityGreen;
          }
        }
      }
    }
    .descriptionWrapper {
      .triangleUp {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 12px solid $lightPurple;
        transform: translateX(16px);

        &.triangleUpRight {
          display: none;
        }

        &.text {
          border-bottom: 12px solid $textPurple;
        }
      }

      .description {
        padding: 10px 16px;
        text-align: left;
        font: {
          family: "Gabarito-Regular";
          size: 1.0rem;
        }
        background: $lightPurple;
        color: white;
        border-radius: 6px;

        &.text {
          font: {
            family: "Gabarito-Medium";
            size: 1.2rem;
          }
          background: $textPurple;
          color: $lightPurple;
        }
      }

      &.isAI {
        .triangleUp {
          &.triangleStandard {
            opacity: 0.0;
          }
          &.triangleUpRight {
            display: block;
            border-bottom: 12px solid $gravityGreen;
            position: absolute;
            right: 18px;
            transform: translateY(-12px);
          }
        }

        .description {
          background: $gravityGreen;

          .aiGenerated {
            font: {
              family: "Gabarito-Bold";
              size: 0.7rem;
            }
            color: $lightPurple;
            padding-top: 8px;
          }
        }
      }
    }

    .responses {
      background: rgb(11, 2, 31);
      padding: 14px;

      &.empty {
        background: transparent;
      }

      .responsesWrapper {
        .responseWrapper {
          padding: 16px 0;

          .response {
            &.text {
              z-index: 2;
              transform: translateY(36px);
            }
            .videoPlayer {
              max-height: 500px;
              width: 100%;
            }
          }
          .data {
            width: 100%;
            padding: 0 0 32px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .responseEmpty {
        font: {
          family: "Gabarito-Regular";
          size: 1.0rem;
        }
        text-align: center;
        color: $midGrey;
      }
    }
  }
  .textResponse {
    height: 60px;
    width: 60%;
    max-width: 500px;
    min-width: 240px;
    background: #002516;
    padding: 14px 12px;
    box-sizing: border-box;
    position: fixed;
    right: 8px;
    bottom: 50px;
    z-index: 2;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
    animation: 600ms 0s normal forwards menuSlideIn;

    &.closing {
      animation: 600ms 0s normal forwards menuSlideOut;
    }

    .respondWrapper {
      display: inline-block;

      .respond {
        height: 34px;
        width: 148px;
        background: $gravityGreen;
        color: $purpleLight;
        border: 2px solid white;
        font: {
          family: "Gabarito-Bold";
          size: 0.9rem;
        }
        padding: 0 28px;
        border-radius: 18px;

        &.magic {
          background-image: repeating-linear-gradient(45deg, violet, $gravityGreen, $orange, $red, violet);
          background-size: 800% 800%;
          animation: rainbow 12s ease infinite;
        }

        .responseType {
          display: inline-block;
          vertical-align: middle;
          padding-right: 6px;
          transform: translateY(2px);

          .typeIcon {
            height: 18px;
            width: 18px;
          }
        }
        .typeText {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .or {
      display: inline-block;
      width: 16px;
      font: {
        family: "Gabarito-Bold";
        size: 0.7rem;
      }
      color: white;
      transform: translateX(7px);
    }

    .textWrapper {
      float: right;

      .textEntryWrapper {
        display: inline-block;
        vertical-align: middle;

        .responseText {
          height: 36px;
          width: 150px;
          font: {
            family: "Gabarito-Regular";
            size: 0.9rem;
          }
          padding: 0 16px;
          border: none;
          border-radius: 18px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          resize: none;

          &:disabled {
            transform: translateY(-1px);

            &.submitted {
              transform: translateY(0px);
              background-color: light-dark(rgba(239, 239, 239, 1), rgba(59, 59, 59, 1));
            }
          }
        }
      }
      .textSubmitWrapper {
        display: inline-block;
        vertical-align: middle;

        .responseButton {
          height: 36px;
          width: 116px;
          background: $gravityGreen;
          color: $purpleLight;
          border: 2px solid white;
          font: {
            family: "Gabarito-Bold";
            size: 0.9rem;
          }
          padding: 0 28px;
          border-radius: 18px;

          &:disabled {
            opacity: 0.2;
            cursor: default;

            &.submitted {
              opacity: 1;
            }
          }

          &.failed {
            background: $red;
          }

          &.text {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding: 0 16px;
          }

          .Loading {
            position: absolute;
            top: 5px;
            right: 46px;
            transform: scale(0.5);

            @media (max-width: 840px) {
              top: 64px !important;
            }
          }

          .responseType {
            display: inline-block;
            vertical-align: middle;
            padding-right: 6px;
            transform: translateY(2px);

            .typeIcon {
              height: 18px;
              width: 18px;
            }
          }
          .typeText {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }

    @media (max-width: 840px) {
      height: 120px;
      bottom: 40px;

      .respondWrapper {
        width: 100%;

        .respond {
          width: 100%;
        }
      }

      .or {
        display: block;
        width: 100%;
        padding: 4px 8px 8px;
        text-align: center;
        box-sizing: border-box;
      }

      .textWrapper {
        width: 100%;

        .textEntryWrapper {
          width: calc(100% - 118px);

          .responseText {
            height: 36px;
            width: calc(100% - 30px);
          }
        }
      }
    }
  }
}

.mobile {
  .ThreadView {
    .closeThreadWrapper {
      padding: 7px 4px;
    }
    .threadModal {
      height: calc(100% - 116px);
      width: calc(100% - 16px);
      top: 72px;
      padding-bottom: 80px;

      .dataWrapper {
        padding: 14px;
      }
    }
    .textResponse {
      height: 120px;
      width: calc(100% - 16px);
      bottom: 40px;

      .respondWrapper {
        width: 100%;

        .respond {
          width: 100%;
        }
      }

      .or {
        display: block;
        width: 100%;
        padding: 4px 8px 8px;
        text-align: center;
        box-sizing: border-box;
      }

      .textWrapper {
        width: 100%;

        .textEntryWrapper {
          width: calc(100% - 118px);

          .responseText {
            height: 36px;
            width: calc(100% - 30px);
          }
        }
      }
    }
  }
}
