@import "../exports/variables.scss";

.Keywords {
    .contentKeywords {
        padding: 8px 0;
        position: relative;

        &.mainPrompt {
            width: calc(100% - 638px);
            position: absolute;
            bottom: 100px;

            &.viewer {
                bottom: 24px;
            }
        }

        .keywordTitle {
            height: 20px;
            padding: 8px 2px;

            .keywordAI {
                display: inline-block;
                height: 17px;
                width: 17px;
                font: {
                    family: "Gabarito-Black";
                    size: 0.7rem;
                }
                color: #fff;
                background: $gravityGreen;
                padding: 2px 3px;
                box-sizing: border-box;
                border-radius: 2px;
            }
            .keywordWord {
                display: inline-block;
                font: {
                    family: "Gabarito-Bold";
                    size: 0.7rem;
                }
                color: $purpleLight;
                padding: 0 2px;
                transform: translateY(1px);

                .beta {
                    font: {
                        family: "Gabarito-Regular";
                        size: 0.5rem;
                    }
                    color: $purpleLight;
                    padding: 0 2px;
                }
            }

            &.dark {
                .keywordWord {
                    color: rgba(255,255,255,0.8);

                    .beta {
                        color: rgba(255,255,255,0.8);
                    }
                }
            }

            .loadWrapper {
                display: inline-block;
                visibility: hidden;

                &.active {
                    visibility: visible;
                }

                .Loading {
                    display: inline-block;
                    height: 2px;
                    transform: scale(0.4) translateX(-40px);
                }
            }
            .editKeywords {
                position: absolute;
                top: 20px;
                right: 0;

                .editKeywordsButton {
                    height: 20px;
                    font: {
                        family: "Gabarito-Medium";
                        size: 0.6rem;
                        style: italic;
                    }
                    color: $purpleLight;
                    padding: 2px 8px;
                    background: rgba(100, 200, 180, 0.2);
                    border: none;
                    border-radius: 4px;

                    &.dark {
                        color: rgba(255,255,255,0.8);
                    }
                }
            }
        }

        .keywordsWrapper {
            min-height: 60px;

            .keywordWrapper {
                display: inline-block;
                padding: 2px;

                .keyword {
                    height: 26px;
                    font: {
                        family: "Gabarito-Medium";
                        size: 0.75rem;
                    }
                    color: #fff;
                    background: rgba(100, 200, 180, 0.8);
                    padding: 0 8px;
                    border: none;
                    border-radius: 6px;

                    &:hover {
                        background: $gravityGreen;
                    }

                    &:disabled {
                        cursor: default;
                        
                        &:hover {
                            background: rgba(100, 200, 180, 0.8);
                            cursor: default;
                        }
                    }
                }
            }
        }
    }
}

.mobile {
    .Keywords {
        .contentKeywords {
            padding: 8px 0;
            position: relative;
            box-sizing: border-box;
    
            &.mainPrompt {
                width: 100%;
                bottom: auto;
            }
        }
    }
}