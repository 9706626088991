.Viewer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .loadingViewer {
        display: inline-block;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 24px));

        .loaderWrapper {
            display: inline-block;

            .gravityLogo {
                width: 160px;
            }
            .Loading {
                height: 38px;
                width: 38px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    .banner {
        height: 64px;
        width: calc(100% - 18px);
        background: rgba(234, 234, 234, 0.8);
        position: fixed;
        top: 8px;
        left: 50%;
        transform: translateX(calc(-50% - 4px));
        z-index: 4;
        border-radius: 32px;
        -webkit-backdrop-filter: saturate(100%) blur(4px);
        backdrop-filter: saturate(100%) blur(4px);

        .gravityHome {
            background: none;
            border: none;
            padding: 16px 20px;

            .gravityLogo {
                height: 34px;
            }
        }
    }
}
