@import '../exports/variables.scss';

.Engagement {
    .moduleTitle {
        display: inline-block;
        height: 14px;
        font: {
            family: "Gabarito-Bold";
            size: 0.8rem;
        }
        background: $gravityGreen;
        padding: 4px 16px;
        border-radius: 12px;
    }
    .assetWrapper {
        display: inline-block;
        vertical-align: top;
        width: calc(33% - 16px);
        padding: 8px;

        .assetInner {
            padding: 12px;
            text-align: center;
            border-radius: 22px;

            .assetTitle {
                transform: translateX(-4px);

                .assetImage {
                    display: inline-block;
                    vertical-align: top;
                    padding: 0 6px;
                    position: relative;
                    top: 2px;

                    .assetIcon {
                        height: 16px;
                        width: 16px;
                    }
                }
                .assetText {
                    display: inline-block;
                    vertical-align: middle;
                    font: {
                        family: "Gabarito-Medium";
                        size: 0.9rem;
                    }
                    color: $purpleLight;
                }
            }
            .assetCount {
                font: {
                    family: "Gabarito-Bold";
                    size: 2.3rem;
                }
                color: $gravityGreen;
            }
            .assetType {
                font: {
                    family: "Gabarito-Bold";
                    size: 0.9rem;
                }
                color: $purpleLight;
            }
        }
    }
}

.mobile {
    .Engagement {
        .moduleTitle {
            font: {
                family: "Gabarito-Bold";
                size: 0.7rem;
            }
            transform: translate(10px, 8px);
        }
        .assetWrapper {
            width: calc(33% - 12px);
            padding: 6px;
    
            .assetInner {
                padding: 12px;
    
                .assetTitle {
                    transform: translateX(-2px);
    
                    .assetImage {
                        .assetIcon {
                            height: 20px;
                            width: 20px;
                        }
                    }
                    .assetText {
                        font: {
                            size: 0.8rem;
                        }
                    }
                }
                .assetCount {
                    font: {
                        size: 2.0rem;
                    }
                    transform: translateX(-2px);
                }
                .assetType {
                    display: none;
                }
            }
        }
    }
}